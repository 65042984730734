import { checkLoginStatus } from 'auth/actions';
import { getLoggedInStatus, getUsername } from 'auth/selectors';
import { bind } from 'bind-decorator';
import classNames from 'classnames';
import { Icon } from 'elements';
import { DiscussionsApi, UsersApi } from 'global/api';
import { RootState } from 'global/state';
import { getStore } from 'global/store';
import { ROUTES } from 'global/constants';

import { isKeyboardEvent } from 'global/utils';
import update from 'immutability-helper';
import {
    ConversationStatus,
    Profile, ProfileProfileVisibilityEnum, ProfileRoleEnum,
    UserAward, UserAwardToJSON, UserEducation, UserEducationToJSON, UserProfession, UserProfessionToJSON,
} from 'labxchange-client';
import { TagsEditor } from 'ui/components/TagsEditor';

import { MentorshipOwnProfile } from 'mentorship/components/MentorshipOwnProfile';
import { MentorshipProfile } from 'mentorship/components/MentorshipProfile';
import {
    actionCanMessage,
    MessageModalState,
    RenderModals,
} from 'messages/components';
import moment from 'moment-shortformat';
import * as React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { MessageDescriptor } from 'react-intl';
import { countries } from 'typed-countries';
import { UI_IS_SM } from 'ui/breakpoints';
import { CalendarDate, StandardPageLayout, Tag } from 'ui/components';
import { Collapsible } from '@labxchange/ui-components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showWarningMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { ImagePickerData } from 'ui/components/ImageFilePicker/ImageFilePicker';
import { ToggleSwitch } from 'ui/components/ToggleSwitch';
import * as UserActions from 'user/actions';
import { UserProfileSection } from 'user/components';
import { EMBARGOED_COUNTRY_CODES } from 'user/models';
import { WrappedMessage } from 'utils';
import { SubjectTagsSelect } from 'library/components/SubjectTagsSelect/SubjectTagsSelect';
import { UserAvatar } from '../UserAvatar';
import messages from './displayMessages';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { InvalidContent } from 'library/components/InvalidContent';
import { intl } from 'i18n';
import uiDisplayMessages from 'ui/components/displayMessages';

function visibilityMapping(x: ProfileProfileVisibilityEnum): MessageDescriptor {
    switch (x) {
        case ProfileProfileVisibilityEnum.Everyone: return messages.pvEveryoneText;
        case ProfileProfileVisibilityEnum.LabxchangeUsers: return messages.pvLabxchangeText;
        case ProfileProfileVisibilityEnum.NoOne: return messages.pvNoOneText;
    }
}

// TODO: i18n
const GenderLabels: { [id: string]: string } = {
    m: 'Male',
    f: 'Female',
    o: 'Other/Prefer not to say',
};

interface CollapsibleSubSectionProps {
    id: string;
    title: MessageDescriptor;
    className?: string;
    children?: React.ReactNode;
}

interface CollapsibleSubSectionState {
    isCollapsible: boolean;
    isOpen: boolean;
}

export class CollapsibleSubSection extends React.Component<CollapsibleSubSectionProps, CollapsibleSubSectionState> {
    public constructor(props: CollapsibleSubSectionProps) {
        super(props);
        this.state = {
            isCollapsible: this.isMobileView(),
            isOpen: !this.isMobileView(),
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    public render() {
        let indicator = null;
        if (this.state.isCollapsible) {
            indicator = (
                <Icon
                    className='user-experience-indicator'
                    name={this.state.isOpen ? 'chevron-up' : 'chevron-down'}
                    zoom='1.4em'
                />
            );
        }

        const title = (
            <div className='user-experience-header'>
                <div className='user-experience-icon'></div>
                <h3><WrappedMessage message={this.props.title} /></h3>
                <div className='user-experience-indicator'>{indicator}</div>
            </div>
        );

        return (
            <div
                id={this.props.id}
                ref={this.props.id}
                className={classNames('user-experience-container', this.props.className)}>
                <Collapsible
                    title={title}
                    isOpen={this.state.isOpen}
                    isCollapsible={this.state.isCollapsible}
                    onToggle={this.onToggle}
                >
                    {this.props.children}
                </Collapsible>
            </div>
        );
    }

    @bind private onToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    @bind private isMobileView() {
        return UI_IS_SM.matches;
    }

    @bind private onResize() {
        this.setState({
            isCollapsible: this.isMobileView(),
        });
    }
}

interface MatchProps {
    username: string;
}

interface ReduxStateProps {
    isLoggedIn: boolean;
    loggedInUsername: string;
    profileEdit: boolean;
}

interface ReduxActionProps {
    reloadAvatarImage: typeof UserActions.reloadOwnAvatarImage;
}

interface ImageLoadState {
    image: any;
    imagePreviewUrl: any; // for displaying the image preview
}

interface Props extends RouteComponentProps<MatchProps> {
}

interface State {
    loading: boolean;
    userProfile?: Profile;
    toggleUserBioEdit: boolean;
    toggleGenderEdit: boolean;
    toggleProfileVisibilityEdit: boolean;
    toggleCountryEdit: boolean;
    toggleCityEdit: boolean;
    editProfession: number;
    editEducation: number;
    editAward: number;
    userGenderValue: string | undefined;
    updatedProfession?: UserProfession;
    updatedEducation?: UserEducation;
    updatedAward?: UserAward;
    imageLoadState?: ImageLoadState | null;
    modalState: MessageModalState;
    conversationStatus?: ConversationStatus;
    showErrors: boolean;
    errorCode?: number;
}

export class UserProfilePageInternal extends React.PureComponent<Props & ReduxStateProps & ReduxActionProps, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            toggleUserBioEdit: false,
            toggleGenderEdit: false,
            toggleProfileVisibilityEdit: false,
            toggleCountryEdit: false,
            toggleCityEdit: false,
            editProfession: -1,
            editEducation: -1,
            editAward: -1,
            userGenderValue: undefined,
            modalState: MessageModalState.None,
            showErrors: false,
        };
    }

    public async componentDidMount() {
        this.fetchUserProfile();
    }

    public async componentDidUpdate(prevProps: Props & ReduxStateProps) {
        if ((this.state.userProfile && this.props.match.params.username !== this.state.userProfile.username) ||
            prevProps.isLoggedIn !== this.props.isLoggedIn) {
            this.fetchUserProfile();
        }
    }

    public render() {
        if (this.state.userProfile === undefined) {
            if (this.state.loading) {
                return (
                    <StandardPageLayout headerFeature={this.renderHeader()}>
                        <p><WrappedMessage message={uiMessages.uiLoading} /></p>
                    </StandardPageLayout>
                );
            } else if (this.state.errorCode === 401) {
                return <RegistrationGate
                    title={intl.formatMessage(this.getRegistrationGateTitle)}
                    body={intl.formatMessage(this.getRegistrationGateBody)}
                    primaryButtonText={uiMessages.uiSignUp}
                    secondaryButtonText={uiMessages.uiSignIn}
                    image='/assets/images/access.svg'
                />;
            } else {
                // We weren't able to find the user profile
                return <InvalidContent statusCode={this.state.errorCode} />;
            }
        }

        const userProfile = this.state.userProfile;

        const ownProfile = this.isOwnProfile();
        const changeImageMessage = userProfile.publicAvatarUrl ? messages.changeImageText : messages.uploadImageText;
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                pageTitleValues={{ name: userProfile.fullName || userProfile.username }}
                headerFeature={this.renderHeader()}
            >
                <div className='profile-page'>
                    <div className='profile-content'>
                        <div className='user-profile-top-section white-box'>
                            <div className='profile-info'>
                                {ownProfile ?
                                    <>
                                        <button className='unstyled profile-image own-profile-image text-header'
                                            aria-label='Set profile image'
                                            onKeyPress={() => {
                                                document.getElementById('user-profile-image-picker')!.click();
                                            }}>
                                            <div className='profile-image-avatar'>
                                                <UserAvatar
                                                    avatarStyle='large-profile'
                                                    username={this.props.match.params.username}
                                                    diameter='120px'
                                                    displayText={intl.formatMessage(changeImageMessage)}
                                                    onFileChanged={this.onImageFileChanged}
                                                />
                                            </div>
                                        </button>
                                        <div className='remove-image'>
                                            {userProfile.publicAvatarUrl &&
                                                <button type='button' onClick={this.onRemoveImage} tabIndex={0}
                                                    onKeyPress={this.onRemoveImage}>
                                                    <Icon name='x' zoom='15' />
                                                    <WrappedMessage message={messages.removeImageText} />
                                                </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className={classNames('unstyled', 'profile-image')}>
                                        <UserAvatar
                                            avatarStyle='large-profile'
                                            username={this.props.match.params.username}
                                            diameter='120px'
                                        />
                                    </div>}
                                <div className='title'>
                                    {userProfile.fullName
                                        ? <h1>{userProfile.fullName}</h1>
                                        : null}
                                </div>
                                <div className='username-handle'>
                                    @{userProfile.username}
                                </div>
                                {ownProfile ?
                                    <div className='switches'>
                                        {this.renderProfileVisibilityForm()}
                                    </div>
                                    : this.renderMessageButton()
                                }
                            </div>
                            <div className='user-bio'>
                                <h2>{this.renderUserAbout(userProfile)}</h2>
                                {this.state.toggleUserBioEdit
                                    ? this.renderUserBioForm()
                                    : <p>{userProfile.bio ? userProfile.bio : null}</p>}
                                {ownProfile ?
                                    <>
                                        {!this.state.toggleUserBioEdit ?
                                            <button
                                                className={classNames('unstyled', 'edit-icon')}
                                                aria-label={intl.formatMessage(messages.ariaEditBioButton)}
                                                onClick={this.renderBioEdit}>
                                                <Icon name='pencil' zoom='16' />
                                            </button>
                                            : null}
                                    </>
                                    : null}
                            </div>
                        </div>
                        {this.renderExperience()}
                        {this.renderInterest()}
                        {this.renderMentorship()}
                    </div>
                    {this.state.userProfile!.detailsVisible || ownProfile
                        ? <div className='sidebar'>
                            <div className='details-box white-box'>
                                <div className='details-heading'>
                                    <h2><WrappedMessage message={messages.userDetails} /></h2>
                                    {ownProfile ?
                                        <div className='details-visible'>
                                            <ToggleSwitch
                                                isChecked={Boolean(userProfile.detailsVisible)}
                                                displayText={intl.formatMessage(messages.detailsVisible)}
                                                onToggleSwitchSelect={this.setDetailsVisible} />
                                        </div>
                                        : null}
                                </div>
                                <div className='user-details'>
                                    <div>
                                        <small><WrappedMessage message={messages.gender} /></small>
                                        {this.renderGenderEditForm()}
                                        <small><WrappedMessage message={messages.country} /></small>
                                        {this.renderCountryEditForm()}
                                        <small><WrappedMessage message={messages.city} /></small>
                                        {this.renderCityEditForm()}
                                    </div>
                                </div>
                            </div>
                            {ownProfile && this.state.userProfile.role === ProfileRoleEnum.Learner &&
                                <button
                                    className='primary-button confirm-educator-btn'
                                    onClick={this.askChangeToEducatorRole}
                                >
                                    <WrappedMessage message={messages.switchToEducatorButton} />
                                </button>
                            }
                        </div>
                        : null}
                </div>
                {this.renderModals()}
            </StandardPageLayout>
        );
    }

    public get getRegistrationGateTitle() {
        if (this.props.profileEdit) {
            return messages.notLoggedProfileEditingTitle;
        }
        return messages.notLoggedLXProfileTitle;
    }

    public get getRegistrationGateBody() {
        if (this.props.profileEdit) {
            return messages.notLoggedProfileEditingBody;
        }
        return messages.notLoggedLXProfileBody;
    }

    private isOwnProfile() {
        return (this.props.loggedInUsername === this.state.userProfile!.username) && this.props.profileEdit;
    }

    @bind private onCloseModal() {
        this.setState({ modalState: MessageModalState.None });
    }

    @bind private askChangeToEducatorRole() {
        showWarningMessage(
            <WrappedMessage message={messages.confirmEducatorSwitchContent} />,
            {
                title: <WrappedMessage message={messages.confirmEducatorSwitchTitle} />,
                onConfirm: this.doChangeToEducatorRole,
                confirmText: messages.switchToEducator,
                cancelText: messages.backToProfile,
            },
        );
    }

    @bind private async doChangeToEducatorRole() {
        const updateData = { role: ProfileRoleEnum.Educator };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            return;
        }

        // if we get to here, it succeeded, so we should manually set local
        // state to match.
        this.setState({
            userProfile: update(this.state.userProfile!,
                { role: { $set: ProfileRoleEnum.Educator } },
            )
        });
        checkLoginStatus(getStore().dispatch);
    }

    private renderModals() {
        const userProfile = this.state.userProfile;
        if (userProfile === undefined) { return; }
        const onClose = this.onCloseModal;
        const history = this.props.history;
        const ownUsername = this.props.loggedInUsername;

        return RenderModals({
            userProfile,
            onClose,
            history,
            ownUsername,
            modalType: this.state.modalState,
        });
    }

    @bind private async onClickMessage() {
        const userProfile = this.state.userProfile;
        if (userProfile === undefined) { return; }

        const modalState = await actionCanMessage(userProfile.username, this.props.history);
        this.setState({ modalState });
    }

    @bind private renderBioEdit(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleUserBioEdit: !this.state.toggleUserBioEdit });
    }

    @bind private onGenderEdit(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleGenderEdit: !this.state.toggleGenderEdit });
    }

    @bind private onProfileVisibilityEdit(
        event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    ) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleProfileVisibilityEdit: !this.state.toggleProfileVisibilityEdit });
    }

    @bind private onCountryEdit(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleCountryEdit: !this.state.toggleCountryEdit });
    }

    @bind private onCityEdit(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleCityEdit: !this.state.toggleCityEdit });
    }

    @bind private onImageFileChanged(data: ImagePickerData) {
        this.setState({ imageLoadState: data });
        this.updateProfileImage(data);
    }

    @bind private onRemoveImage(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        this.setState({ imageLoadState: null });
        this.removeProfileImage();
    }

    @bind private async saveAreaOfStudy(interests: string[]) {
        const { userProfile } = this.state;
        const previousState = [...(userProfile?.areaOfStudy || [])];
        this.setState({
            userProfile: update(
                this.state.userProfile!,
                { areaOfStudy: { $set: interests } },
            )
        });
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: { area_of_interests: interests },
            });
        } catch (err) {
            this.setState({
                showErrors: true,
                userProfile: update(this.state.userProfile!,
                    { areaOfStudy: { $set: previousState } },
                ),
            });
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    // Render list of tags
    private renderTags(tags: string[]) {
        return (
            <div className='existing-tags-block'>
                {tags && tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
            </div>
        );
    }

    private renderInterest() {
        if (this.state.userProfile === undefined) {
            return null;
        }
        // Learner profile
        const { userProfile } = this.state;
        if (!userProfile.subjects || !userProfile.areaOfStudy) {
            return null;
        }
        const ownProfile = this.isOwnProfile();
        const extractedSubjects = userProfile.subjects.map((tag: string) => tag.split(':').pop() || '');
        const areaOfStudy = userProfile.areaOfStudy!;
        if (!ownProfile) {
            // If interests are not publicly visible
            if (!userProfile.interestsVisible) {
                return null;
            }
            // If interests are publicly visible, but both sections are empty
            if (extractedSubjects.length === 0 && areaOfStudy.length === 0) {
                return null;
            }
        }
        return (
            <UserProfileSection
                title={messages.userInterests}
                toggleSwitch={ownProfile ? this.renderInterestsToggleSwitch() : undefined}
                sectionName='user-interests'>
                {ownProfile && (
                    <div className='user-interests'>
                        <div className='unit-subject'>
                            <span className='section-title'><WrappedMessage message={messages.userSubjectsAdd} /></span>
                            <SubjectTagsSelect
                                onSelectedTagsChanged={this.saveSubjects}
                                selectedSubjectTags={new Set(userProfile.subjects || [])}
                                titleSubjectsAmount={3}
                            />
                        </div>
                        <hr className='divider' />
                        <div className='unit-subject'>
                            <span className='section-title'><WrappedMessage message={messages.userInterestsAdd} /></span>
                            <TagsEditor
                                placeholder={messages.userEnterKeywords}
                                required={false}
                                showErrors={this.state.showErrors}
                                onChange={this.saveAreaOfStudy}
                                helpText={messages.separateWithCommas}
                                tags={userProfile!.areaOfStudy || []}
                            />
                        </div>
                    </div>
                )}
                {!ownProfile && extractedSubjects.length > 0 && (
                    <div>
                        <span className='section-title'>
                            <WrappedMessage message={messages.userSubjects} />
                        </span>
                        {this.renderTags(extractedSubjects)}
                    </div>
                )}
                {!ownProfile && areaOfStudy.length > 0 && (
                    <div>
                        <span className='section-title'>
                            <WrappedMessage message={messages.userAreaOfStudy} />
                        </span>
                        {this.renderTags(areaOfStudy)}
                    </div>
                )}
            </UserProfileSection>
        );
    }

    private renderExperience() {
        if (this.state.userProfile === undefined) {
            return null;
        }
        const userProfile = this.state.userProfile;
        const professions = userProfile.profession || [];
        const education: UserEducation[] = userProfile.education || [];
        const awards: UserAward[] = userProfile.awards || [];
        const ownProfile = this.isOwnProfile();

        if (!ownProfile) {
            if (!userProfile.experienceVisible) {
                // another user, and experience is hidden
                return null;
            }
            if (professions.length === 0 && education.length === 0 && awards.length === 0) {
                // is visible, but no entries, so hide entire section
                return null;
            }
        }

        return (
            <UserProfileSection
                title={messages.userExperience}
                toggleSwitch={ownProfile ? this.renderExperienceToggleSwitch() : undefined}
                sectionName='user-experience'>
                <div className='user-experience'>
                    {this.renderProfessions()}
                    {this.renderEducation()}
                    {this.renderAwards()}
                </div>
            </UserProfileSection>
        );
    }

    private renderMentorship() {
        if (this.state.userProfile!.role !== ProfileRoleEnum.Educator) {
            return null;
        }

        if (this.isOwnProfile()) {
            return (
                <MentorshipOwnProfile
                    profile={this.state.userProfile!}
                    onToggleAvailability={this.toggleAvailableToMentor}
                />
            );
        } else {
            return (
                <MentorshipProfile profile={this.state.userProfile!}
                    loggedInUsername={this.props.loggedInUsername} />
            );
        }
    }

    private renderUserBioForm() {
        return (
            <div className='userbio-edit-form'>
                <textarea
                    className='form-control'
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    required={true}
                    aria-required={true}
                    value={this.state.userProfile!.bio ? this.state.userProfile!.bio : ''}
                    onChange={this.updateUserBio} aria-label={intl.formatMessage(messages.ariaTextAreaEditBio)}
                    maxLength={300 /* 300 is current edX LMS API bio length limitation */}
                />
                <button
                    className={classNames('unstyled', 'cancel-link')}
                    onClick={this.cancelBioUpdate}>
                    <WrappedMessage message={messages.cancelLink} />
                </button>

                <button
                    className='lx-btn update-button float-right mr-0'
                    onClick={this.saveUserBio}
                >
                    <WrappedMessage message={messages.updateButton} />
                </button>
            </div>
        );
    }

    private renderProfileVisibilityForm() {
        const userProfile = this.state.userProfile!;
        let age: number;
        if (userProfile.dateOfBirth !== undefined) {
            age = CalendarDate.fromJsDate(userProfile.dateOfBirth).ageInYears;
        } else {
            age = 20;
        }

        const profileVisibility = userProfile.profileVisibility ? (
            userProfile.profileVisibility
        ) : ProfileProfileVisibilityEnum.NoOne;

        return (
            <>
                <div className='user-details user-details-profile-visibility'>
                    <div><small><WrappedMessage message={messages.profileVisibilityLabel} /></small></div>
                    {
                        this.state.toggleProfileVisibilityEdit
                            ? <select autoFocus  // eslint-disable-line jsx-a11y/no-autofocus
                                value={profileVisibility}
                                onBlur={
                                    (event) =>
                                        this.updateProfileVisibility(event.target.value as ProfileProfileVisibilityEnum)
                                }
                                onChange={
                                    (event) =>
                                        this.updateProfileVisibility(event.target.value as ProfileProfileVisibilityEnum)
                                }
                                aria-label={intl.formatMessage(messages.ariaSelectEditProfileVisibility)}
                            >
                                {age >= 18 &&
                                    <option value={ProfileProfileVisibilityEnum.Everyone} >
                                        {intl.formatMessage(messages.pvEveryone)}
                                    </option>
                                }
                                <option value={ProfileProfileVisibilityEnum.LabxchangeUsers} >
                                    {intl.formatMessage(messages.pvLabxchange)}
                                </option>
                                <option value={ProfileProfileVisibilityEnum.NoOne} >
                                    {intl.formatMessage(messages.pvNoOne)}
                                </option>
                            </select>
                            : <div className='user-details-content'>
                                <span className='user-details-profile-visibility-value'>
                                    <WrappedMessage message={visibilityMapping(profileVisibility)} />
                                </span>
                                <button
                                    className='details-edit unstyled float-right'
                                    aria-label={intl.formatMessage(messages.ariaEditProfileVisibilityButton)}
                                    onClick={this.onProfileVisibilityEdit}>
                                    <Icon name='pencil' zoom='16' />
                                </button>
                            </div>
                    }
                </div>
                <ToggleSwitch
                    isChecked={Boolean(userProfile.directMessagesEnabled)}
                    displayText={intl.formatMessage(messages.messagesEnabled)}
                    disabled={userProfile.profileVisibility === ProfileProfileVisibilityEnum.NoOne || age < 18}
                    onToggleSwitchSelect={this.setMessageVisible} />
                <ToggleSwitch
                    isChecked={Boolean(userProfile.includeInSearchResults)}
                    displayText={intl.formatMessage(messages.includeInSearchResultsToggle)}
                    disabled={userProfile.profileVisibility === ProfileProfileVisibilityEnum.NoOne || age < 18}
                    onToggleSwitchSelect={this.setIncludeInSearchResults} />
                <button
                    className='btn account-settings-btn'
                    onClick={() => this.props.history.push(ROUTES.Personal.PROFILE_SETTINGS)}
                >
                    <Icon name='settings2' zoom='16' />
                    {intl.formatMessage(uiDisplayMessages.uiSettings)}
                </button>
            </>
        );
    }

    private renderMessageButton() {
        if (this.state.conversationStatus && this.state.conversationStatus.allowed) {
            const name = this.state.userProfile ? this.state.userProfile.fullName : '';
            return (
                <button
                    onClick={this.onClickMessage}
                    className='btn message-button primary-button btn-outline-primary'
                >
                    <WrappedMessage
                        message={messages.messageButton}
                        values={{ name }}
                    />
                </button>
            );
        }
        return null;
    }

    private renderGenderEditForm() {
        const ownProfile = this.isOwnProfile();

        let gender = '';
        if (this.state.userProfile && this.state.userProfile.gender) {
            gender = this.state.userProfile.gender;
        }
        return (
            this.state.toggleGenderEdit
                ? <select autoFocus  // eslint-disable-line jsx-a11y/no-autofocus
                    onBlur={this.updateGender}
                    onChange={this.updateGender}
                    value={gender}
                    aria-label={intl.formatMessage(messages.ariaSelectEditGender)}
                >
                    <option value=''></option>
                    <option value='m'>{GenderLabels.m}</option>
                    <option value='f'>{GenderLabels.f}</option>
                    <option value='o'>{GenderLabels.o}</option>
                </select>
                : <div className='user-details-content'>
                    {GenderLabels[gender]}
                    {ownProfile
                        ? <button
                            className='details-edit unstyled float-right'
                            aria-label={intl.formatMessage(messages.ariaEditGenderButton)}
                            onClick={this.onGenderEdit}>
                            <Icon name='pencil' zoom='16' />
                        </button>
                        : null}
                </div>
        );
    }

    private renderCountryEditForm() {
        const ownProfile = this.isOwnProfile();

        let countryName;

        if (this.state.userProfile!.country) {
            const countryCode = this.state.userProfile!.country;
            const countryObj = countries.find((c: any) => c.iso === countryCode);
            countryName = countryObj!.name;
        } else {
            countryName = null;
        }

        return (
            this.state.toggleCountryEdit
                ? <div>
                    <CountryDropdown
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        aria-label={intl.formatMessage(messages.ariaSelectEditCountry)}
                        value={this.state.userProfile!.country ? this.state.userProfile!.country! : ''}
                        valueType='short'
                        onChange={(val) => this.updateUserCountry(val)}
                        blacklist={EMBARGOED_COUNTRY_CODES}
                    />
                </div>
                : <div className='user-details-content'>
                    {countryName}
                    {ownProfile
                        ? <button
                            className='details-edit unstyled float-right'
                            aria-label={intl.formatMessage(messages.ariaEditCountryButton)}
                            onClick={this.onCountryEdit}>
                            <Icon name='pencil' zoom='16' />
                        </button>
                        : null}
                </div>
        );
    }

    private renderCityEditForm() {
        const ownProfile = this.isOwnProfile();

        return (
            this.state.toggleCityEdit
                ? <div className='user-details-edit-form'>
                    <input
                        className='form-control'
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        aria-label={intl.formatMessage(messages.ariaInputEditCity)}
                        value={this.state.userProfile!.city ? this.state.userProfile!.city : ''}
                        onChange={this.updateUserCity}
                        onBlur={this.saveUserCity}
                        onKeyPress={this.handleCityKeyPress}
                    />
                </div>
                : <div className='user-details-content'>
                    {this.state.userProfile!.city ? this.state.userProfile!.city : null}
                    {ownProfile
                        ? <button
                            className='details-edit unstyled float-right'
                            aria-label={intl.formatMessage(messages.ariaEditCityButton)}
                            onClick={this.onCityEdit}>
                            <Icon name='pencil' zoom='16' />
                        </button>
                        : null}
                </div>
        );
    }

    @bind private updateUserBio(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            userProfile: update(this.state.userProfile!,
                { bio: { $set: event.target.value } },
            )
        });
    }

    @bind private async toggleAvailableToMentor(available: boolean) {
        const updateData = { mentorship: { enabled: available } };
        const oldEnabledValue = this.state.userProfile!.mentorship!.enabled;
        // tentatively update state.
        this.setState({
            userProfile: {
                ...this.state.userProfile!,
                mentorship: {
                    ...this.state.userProfile!.mentorship,
                    enabled: available,
                }
            }
        });
        try {
            await UsersApi.profilesPartialUpdate({ id: this.state.userProfile!.username, data: updateData });
        } catch (err) {
            // api call failed, revert local state.
            this.setState({
                userProfile: {
                    ...this.state.userProfile!,
                    mentorship: {
                        ...this.state.userProfile!.mentorship,
                        enabled: oldEnabledValue,
                    }
                }
            });
            showErrorMessage(
                <WrappedMessage message={messages.errorSavingAvailableToMentor} />,
                { exception: err }
            );
        }
    }

    @bind private async updateUserCountry(newCountry: any) {
        this.setState({
            userProfile: update(this.state.userProfile!,
                { country: { $set: newCountry } },
            )
        });

        const updateData = { country: newCountry };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }

        this.setState({ toggleCountryEdit: false });
    }

    @bind private async updateProfileVisibility(
        profileVisibility?: ProfileProfileVisibilityEnum, force: boolean = false,
    ) {
        this.setState({ toggleProfileVisibilityEdit: false });

        if (profileVisibility === undefined || this.state.userProfile === undefined) {
            return;
        }

        // if no change; ignore
        if (this.state.userProfile.profileVisibility === profileVisibility) {
            return;
        }

        // We have this catch and recursion here so that we can show a warning
        // modal in certain cases
        if (!force) {
            let age: number;
            if (this.state.userProfile.dateOfBirth !== undefined) {
                age = CalendarDate.fromJsDate(this.state.userProfile.dateOfBirth).ageInYears;
            } else {
                age = 20; // default to old enough
            }
            if (age < 18 && profileVisibility === ProfileProfileVisibilityEnum.LabxchangeUsers) {
                showWarningMessage(<WrappedMessage message={messages.confirmPublishProfile} />, {
                    onConfirm: () => { this.updateProfileVisibility(profileVisibility, true); },
                });
                return;
            }
        }

        const updateData = { profile_visibility: profileVisibility };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }

        this.setState({
            userProfile: update(this.state.userProfile, { profileVisibility: { $set: profileVisibility } }),
        });

        // Direct messages and including profile in search results are disabled
        // at the backend when hiding profile, so must update manually from the
        // frontend here to be consistent.
        if (profileVisibility === ProfileProfileVisibilityEnum.NoOne) {
            this.setState({
                userProfile: update(this.state.userProfile, {
                    directMessagesEnabled: { $set: false },
                    includeInSearchResults: { $set: false },
                }),
            });
        }
    }

    @bind private async updateGender(event: React.ChangeEvent<HTMLSelectElement>) {
        const genderMap: any = { m: 'Male', f: 'Female', o: 'Others/Prefer not to say' };
        const gender: string = event.target.value;

        this.setState({ userGenderValue: genderMap[gender] });

        const updateData = { gender };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }

        this.setState({
            userProfile: update(this.state.userProfile!, { gender: { $set: gender } }),
            toggleGenderEdit: false,
        });
    }

    @bind private updateUserCity(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            userProfile: update(this.state.userProfile!,
                { city: { $set: event.target.value } },
            )
        });
    }

    @bind private async saveUserCity() {
        const updateData = { city: this.state.userProfile!.city };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }

        this.setState({ toggleCityEdit: false });
    }

    @bind private handleCityKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.saveUserCity();
        }
    }

    @bind private async saveUserBio() {
        const updateData = { bio: (this.state.userProfile!.bio || '').trim() };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
        this.setState({
            toggleUserBioEdit: !this.state.toggleUserBioEdit,
            userProfile: update(this.state.userProfile!,
                { bio: { $set: (this.state.userProfile!.bio || '').trim() } },
            ),
        });
    }

    @bind private cancelBioUpdate(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({ toggleUserBioEdit: !this.state.toggleUserBioEdit });
    }

    @bind private async setMessageVisible(messagesEnabled: boolean) {
        const updateData = { direct_messages_enabled: messagesEnabled };
        this.setState({
            userProfile: update(this.state.userProfile!,
                { directMessagesEnabled: { $set: messagesEnabled } },
            ),
        });
        try {
            await UsersApi.profilesPartialUpdate({ id: this.state.userProfile!.username, data: updateData });
        } catch (err) {
            // Error; revert previous eager state update.
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { directMessagesEnabled: { $set: !messagesEnabled } },
                ),
            });
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    @bind private async setIncludeInSearchResults(includeInSearchResults: boolean) {
        const updateData = { include_in_search_results: includeInSearchResults };
        this.setState({
            userProfile: update(this.state.userProfile!,
                { includeInSearchResults: { $set: includeInSearchResults } },
            ),
        });
        try {
            await UsersApi.profilesPartialUpdate({ id: this.state.userProfile!.username, data: updateData });
        } catch (err) {
            // Error; revert previous eager state update.
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { includeInSearchResults: { $set: !includeInSearchResults } },
                ),
            });
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    @bind private async setDetailsVisible(showDetails: boolean) {
        const updateData = { details_visible: showDetails };
        this.setState({
            userProfile: update(this.state.userProfile!,
                { detailsVisible: { $set: showDetails } },
            ),
        });
        try {
            await UsersApi.profilesPartialUpdate({ id: this.state.userProfile!.username, data: updateData });
        } catch (err) {
            // Error; revert previous eager state update.
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { detailsVisible: { $set: !showDetails } },
                ),
            });
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    @bind private async setExperienceVisible(showExperience: boolean) {
        const updateData = { experience_visible: showExperience };
        this.setState({
            userProfile: update(this.state.userProfile!,
                { experienceVisible: { $set: showExperience } },
            ),
        });
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            // Error; revert previous eager state update.
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { experienceVisible: { $set: !showExperience } },
                ),
            });
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    @bind private async saveSubjects(subjects: ReadonlySet<string>) {
        const updateData = { subjects: Array.from(subjects) };
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { subjects: { $set: Array.from(subjects) } },
                ),
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
        }
    }

    @bind private async setInterestsVisible(showInterests: boolean) {
        const updateData = { interests_visible: showInterests };
        this.setState({
            userProfile: update(this.state.userProfile!,
                { interestsVisible: { $set: showInterests } },
            ),
        });
        try {
            await UsersApi.profilesPartialUpdate({
                id: this.state.userProfile!.username,
                data: updateData,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            this.setState({
                userProfile: update(this.state.userProfile!,
                    { interestsVisible: { $set: !showInterests } },
                ),
            });
        }
    }

    private renderUserAbout(profileDetails: any) {
        return (
            <WrappedMessage message={messages.userBioAbout} values={{ name: profileDetails.fullName }} />
        );
    }

    private renderHeader() {
        return (
            <div className='profile-page-header-content extended container pt-md-5'></div>
        );
    }

    private async fetchUserProfile() {
        const userName = this.props.match.params.username;
        try {
            this.setState({
                loading: true,
                userProfile: undefined,
            });
            checkLoginStatus(getStore().dispatch);
            const genderMap: any = { m: 'Male', f: 'Female', o: 'Others/Prefer not to say' };
            const userProfile = await UsersApi.profilesRead({ id: userName });
            if (userProfile.profession) {
                userProfile.profession.sort(this.sortByEndYear);
            }
            if (userProfile.education) {
                userProfile.education.sort(this.sortByEndYear);
            }
            if (userProfile.awards) {
                userProfile.awards.sort(this.sortAwards);
            }

            let conversationStatus;
            if (this.props.loggedInUsername) {
                conversationStatus = await DiscussionsApi.conversationsCheckCanMessage({ id: userName });
            }
            this.setState({
                loading: false,
                userProfile,
                userGenderValue: userProfile.gender ? genderMap[userProfile.gender!] : null,
                conversationStatus,
            });
        } catch (err) {
            this.setState({
                loading: false,
                errorCode: err.status,
            });
        }
        if (this.props.profileEdit && !this.isOwnProfile()) {
            return this.props.history.push(ROUTES.Users.PROFILE_SLUG(userName));
        }
    }

    private async updateProfileImage(data: ImagePickerData) {
        // TODO: refactor this and the duplicate function in
        // UserRegistration/OnboardingDetails.tsx into a single function
        const username = this.state.userProfile!.username;
        const image = data.image;
        if (image === null) { return; }

        try {
            await UsersApi.profilesImageCreate({ id: username, image });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            return;
        }
        await this.fetchUserProfile();
        this.props.reloadAvatarImage();
    }

    private async removeProfileImage() {
        const username = this.state.userProfile!.username;
        try {
            await UsersApi.profilesImageDelete({ id: username });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            return;
        }
        this.fetchUserProfile();
        this.props.reloadAvatarImage();
    }

    private renderExperienceToggleSwitch() {
        return (
            <ToggleSwitch
                isChecked={Boolean(this.state.userProfile!.experienceVisible)}
                displayText={intl.formatMessage(messages.experienceVisible)}
                onToggleSwitchSelect={this.setExperienceVisible}
                ariaText={intl.formatMessage(messages.experienceVisibleAria)}
            />
        );
    }

    private renderInterestsToggleSwitch() {
        const { interestsVisible } = this.state.userProfile!;
        return (
            <ToggleSwitch
                isChecked={Boolean(interestsVisible)}
                displayText={intl.formatMessage(messages.interestsVisible)}
                onToggleSwitchSelect={this.setInterestsVisible}
                ariaText={intl.formatMessage(messages.interestsVisibleAria)}
            />
        );
    }

    private renderMonthSelect() {
        const months: JSX.Element[] = [];
        months.push(<option key='undefined' value=''>{intl.formatMessage(messages.monthLabel)}</option>);
        for (let i = 0; i < 12; i++) {
            const index = i.toString();
            months.push(
                <option key={index} value={index}>
                    {moment.months(i)}
                </option>,
            );
        }
        return months;
    }

    private renderYearSelect() {
        const years: JSX.Element[] = [];
        const currentYear = moment().year();
        years.push(<option key='undefined' value=''>{intl.formatMessage(messages.yearLabel)}</option>);
        for (let i = currentYear; i >= currentYear - 110; i--) {
            years.push(
                <option key={i} value={i}>
                    {i}
                </option>,
            );
        }
        return years;
    }

    private renderProfessions() {
        if (this.state.userProfile === undefined) {
            return null;
        }
        const professions = this.state.userProfile.profession || [];
        const ownProfile = this.isOwnProfile();

        if (professions.length === 0 && !ownProfile) {
            return null;
        }

        return (
            <CollapsibleSubSection
                id='profession-container'
                className='profession-container'
                title={messages.userProfession}>
                <ul className='professions'>
                    {professions.slice().map((profession, index) => {
                        let country;
                        if (profession.country) {
                            const countryObj = countries.find((c: any) => c.iso === profession.country);
                            country = countryObj && countryObj.name;
                        }

                        return (
                            <li key={index} className='profession'>
                                {!ownProfile || this.state.editProfession !== index ?
                                    <>
                                        <div className='detail-top'>
                                            <h4>{profession.jobTitle}</h4>
                                            {ownProfile &&
                                                <button
                                                    className={classNames('unstyled', 'edit-icon')}
                                                    aria-label={intl.formatMessage(messages.ariaEditProfessionButton)}
                                                    onClick={this.editProfession.bind(this, index)}>
                                                    <Icon name='pencil' zoom='16' />
                                                </button>
                                            }
                                        </div>
                                        {profession.company &&
                                            <div className='detail-middle'>
                                                {profession.company}
                                                {profession.state && `, ${profession.state}`}
                                                {country && `, ${country}`}
                                            </div>
                                        }
                                        {profession.startYear || profession.endYear ?
                                            <div className='detail-bottom'>
                                                {profession.startYear &&
                                                    <>
                                                        <span className='start'>
                                                            {profession.startMonth !== undefined ?
                                                                moment().month(profession.startMonth).format('MMM ')
                                                                : null}
                                                            {moment().year(profession.startYear).format('YYYY')}
                                                        </span>
                                                        <span className='separator'>-</span>
                                                    </>
                                                }
                                                <span className='end'>
                                                    {profession.endYear && profession.endMonth !== undefined ?
                                                        moment().month(profession.endMonth).format('MMM ')
                                                        : null}
                                                    {profession.endYear ?
                                                        moment().year(profession.endYear).format('YYYY')
                                                        :
                                                        <WrappedMessage message={messages.presentDate} />
                                                    }
                                                </span>
                                            </div>
                                            : null}
                                        {profession.description &&
                                            <p className='detail-description'>{profession.description}</p>
                                        }
                                    </>
                                    :
                                    this.renderProfessionForm()
                                }
                            </li>
                        );
                    })}
                    {ownProfile &&
                        <li>
                            {this.state.editProfession !== professions.length ?
                                <button
                                    className='unstyled add-more'
                                    onClick={this.editProfession.bind(this, professions.length)}>
                                    <Icon name='plus' zoom='12' />
                                    <span><WrappedMessage message={messages.addProfessionButton} /></span>
                                </button>
                                :
                                this.renderProfessionForm()
                            }
                        </li>
                    }
                </ul>
            </CollapsibleSubSection>
        );
    }

    private renderProfessionForm() {
        const professions = this.state.userProfile!.profession;
        const updatedProfession = this.state.updatedProfession!;

        return (
            <div className='user-experience-form'>
                <div className='profession-title'>
                    <h4><WrappedMessage message={messages.professionFormTitle}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        aria-label={intl.formatMessage(messages.ariaInputEditProfessionTitle)}
                        placeholder={intl.formatMessage(messages.ariaInputEditProfessionTitle)}
                        value={updatedProfession.jobTitle}
                        onChange={this.updateProfessionJobTitle}
                        onKeyPress={this.handleProfessionKeyPress}
                    />
                </div>
                <div className='profession-description'>
                    <h4><WrappedMessage message={messages.professionFormDescription}></WrappedMessage></h4>
                    <textarea
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaInputEditProfessionDescription)}
                        placeholder={intl.formatMessage(messages.ariaInputEditProfessionDescription)}
                        value={updatedProfession.description || ''}
                        onChange={this.updateProfessionDescription}
                    />
                </div>
                <div className='profession-company'>
                    <h4><WrappedMessage message={messages.professionFormCompany}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaInputEditProfessionCompany)}
                        placeholder={intl.formatMessage(messages.ariaInputEditProfessionCompany)}
                        value={updatedProfession.company || ''}
                        onChange={this.updateProfessionCompany}
                        onKeyPress={this.handleProfessionKeyPress}
                    />
                </div>
                <div className='input-group'>
                    <div className='profession-country'>
                        <h4><WrappedMessage message={messages.professionFormCountry}></WrappedMessage></h4>
                        <CountryDropdown
                            aria-label={intl.formatMessage(messages.ariaInputEditProfessionCountry)}
                            defaultOptionLabel={'Select country'}
                            value={updatedProfession.country || ''}
                            valueType='short'
                            onChange={this.updateProfessionCountry}
                            required
                        />
                    </div>
                    <div className='profession-state'>
                        <h4><WrappedMessage message={messages.professionFormState}></WrappedMessage></h4>
                        <input
                            className='form-control'
                            aria-label={intl.formatMessage(messages.ariaInputEditProfessionState)}
                            placeholder={intl.formatMessage(messages.ariaInputEditProfessionState)}
                            value={updatedProfession.state || ''}
                            onChange={this.updateProfessionState}
                            onKeyPress={this.handleProfessionKeyPress}
                        />
                    </div>
                </div>
                <div className='input-group'>
                    <div className='profession-start-date'>
                        <h4><WrappedMessage message={messages.professionFormStartDate}></WrappedMessage></h4>
                        <div className='date-group'>
                            <select
                                className='form-control month'
                                aria-label={intl.formatMessage(messages.monthLabel)}
                                defaultValue={updatedProfession.startMonth !== undefined ?
                                    updatedProfession.startMonth.toString() : undefined}
                                onBlur={this.updateProfessionStartMonth}
                                onChange={this.updateProfessionStartMonth}
                                required={true}>
                                {this.renderMonthSelect()}
                            </select>
                            <select
                                className='form-control year'
                                aria-label={intl.formatMessage(messages.yearLabel)}
                                defaultValue={updatedProfession.startYear !== undefined ?
                                    updatedProfession.startYear.toString() : undefined}
                                onBlur={this.updateProfessionStartYear}
                                onChange={this.updateProfessionStartYear}
                                required={true}>
                                {this.renderYearSelect()}
                            </select>
                        </div>
                    </div>
                    <div className='profession-end-date'>
                        <h4><WrappedMessage message={messages.professionFormEndDate}></WrappedMessage></h4>
                        <div className='date-group'>
                            <select
                                className='form-control month'
                                aria-label={intl.formatMessage(messages.monthLabel)}
                                defaultValue={updatedProfession.endMonth !== undefined ?
                                    updatedProfession.endMonth.toString() : undefined}
                                onBlur={this.updateProfessionEndMonth}
                                onChange={this.updateProfessionEndMonth}
                                required={true}>
                                {this.renderMonthSelect()}
                            </select>
                            <select
                                className='form-control year'
                                aria-label={intl.formatMessage(messages.yearLabel)}
                                defaultValue={updatedProfession.endYear !== undefined ?
                                    updatedProfession.endYear.toString() : undefined}
                                onBlur={this.updateProfessionEndYear}
                                onChange={this.updateProfessionEndYear}
                                required={true}>
                                {this.renderYearSelect()}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='button-group'>
                    <div>
                        {professions && this.state.editProfession < professions.length ?
                            <button
                                className='unstyled delete-button'
                                onClick={this.deleteProfession}
                            >
                                <WrappedMessage message={messages.deleteLink} />
                            </button>
                            :
                            <button
                                className='unstyled cancel-button'
                                onClick={this.cancelProfession}
                            >
                                <WrappedMessage message={messages.cancelLink} />
                            </button>
                        }
                    </div>
                    <div>
                        <button
                            className='btn btn-light save-button'
                            onClick={this.saveProfession}
                        >
                            <WrappedMessage message={messages.professionFormSaveButton} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    @bind private editProfession(
        index: number,
        event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    ) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        const professions = this.state.userProfile!.profession;
        let updatedProfession: UserProfession = {
            jobTitle: '',
            description: '',
            company: '',
            country: '',
            state: '',
            startMonth: undefined,
            startYear: undefined,
            endMonth: undefined,
            endYear: undefined,
        };
        if (professions && professions.length && index > -1 && index < professions.length) {
            updatedProfession = professions[index];
        }
        this.setState({ updatedProfession, editProfession: index });
    }

    @bind private async saveProfession() {
        const updatedProfession = this.state.updatedProfession;

        /* Do a little client-side validation. */
        if (updatedProfession && !updatedProfession.jobTitle) {
            showErrorMessage(<WrappedMessage message={messages.errorMissingProfessionTitle} />);
            return;
        }
        if (updatedProfession && updatedProfession.startYear && updatedProfession.endYear) {
            let dStart = updatedProfession.startYear;
            if (updatedProfession.startMonth) {
                dStart = dStart + updatedProfession.startMonth / 100;
            }
            let dEnd = updatedProfession.endYear;
            if (updatedProfession.endMonth) {
                dEnd = dEnd + updatedProfession.endMonth / 100;
            }

            if (dStart > dEnd) {
                showErrorMessage(<WrappedMessage message={messages.errorEndDateShouldBeLater} />);
                return;
            }
        }

        const index = this.state.editProfession;
        const userProfile = this.state.userProfile!;
        let profession: UserProfession[] = [];
        if (userProfile.profession) {
            profession = userProfile.profession.slice();
        }
        if (updatedProfession && index > -1 && index <= profession.length) {
            profession[index] = updatedProfession;
            profession.sort(this.sortByEndYear);
            const updateData = {
                profession: profession.map(UserProfessionToJSON),
            };

            let errors = false;
            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                errors = true;
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            if (!errors) {
                this.setState({
                    userProfile: update(this.state.userProfile!, { profession: { $set: profession } }),
                    editProfession: -1,
                    updatedProfession: undefined,
                });
            }
        }
    }

    @bind private handleProfessionKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.saveProfession();
        }
    }

    @bind private async cancelProfession() {
        this.setState({
            editProfession: -1,
            updatedProfession: undefined,
        });
    }

    @bind private async deleteProfession() {
        const index = this.state.editProfession;
        const userProfile = this.state.userProfile!;
        let profession: UserProfession[] = [];
        if (userProfile.profession) {
            profession = userProfile.profession.slice();
        }
        if (profession.length && index > -1 && index < profession.length) {
            profession.splice(index, 1);
            const updateData = {
                profession: profession.map(UserProfessionToJSON),
            };

            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            this.setState({
                userProfile: update(this.state.userProfile!, { profession: { $set: profession } }),
                editProfession: -1,
                updatedProfession: undefined,
            });
        } else {
            this.cancelProfession();
        }
    }

    @bind private updateProfessionJobTitle(event: React.ChangeEvent<HTMLInputElement>) {
        const jobTitle = event.target.value;
        this.setState({
            updatedProfession: update(this.state.updatedProfession, { jobTitle: { $set: jobTitle } }),
        });
    }

    @bind private updateProfessionDescription(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const description = event.target.value;
        this.setState({
            updatedProfession: update(this.state.updatedProfession, { description: { $set: description } }),
        });
    }

    @bind private updateProfessionCompany(event: React.ChangeEvent<HTMLInputElement>) {
        const company = event.target.value;
        this.setState({
            updatedProfession: update(this.state.updatedProfession, { company: { $set: company } }),
        });
    }

    @bind private updateProfessionCountry(country: any) {
        this.setState({
            updatedProfession: update(this.state.updatedProfession, { country: { $set: country } }),
        });
    }

    @bind private updateProfessionState(event: React.ChangeEvent<HTMLInputElement>) {
        const state = event.target.value;
        this.setState({
            updatedProfession: update(this.state.updatedProfession, { state: { $set: state } }),
        });
    }

    @bind private updateProfessionStartMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let month;
        if (event.target.value !== '') {
            month = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedProfession: update(this.state.updatedProfession, { startMonth: { $set: month } }),
        });
    }

    @bind private updateProfessionStartYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let year;
        if (event.target.value !== '') {
            year = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedProfession: update(this.state.updatedProfession, { startYear: { $set: year } }),
        });
    }

    @bind private updateProfessionEndMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let month;
        if (event.target.value !== '') {
            month = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedProfession: update(this.state.updatedProfession, { endMonth: { $set: month } }),
        });
    }

    @bind private updateProfessionEndYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let year;
        if (event.target.value !== '') {
            year = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedProfession: update(this.state.updatedProfession, { endYear: { $set: year } }),
        });
    }

    private renderEducation() {
        if (this.state.userProfile === undefined) {
            return null;
        }
        const education: UserEducation[] = this.state.userProfile.education || [];
        const ownProfile = this.isOwnProfile();

        if (education.length === 0 && !ownProfile) {
            return null;
        }

        return (
            <CollapsibleSubSection
                id='education-container'
                className='education-container'
                title={messages.userEducation}>
                <ul className='education'>
                    {education.slice().map((credential, index) => {
                        let country;
                        if (credential.country) {
                            const countryObj = countries.find((c: any) => c.iso === credential.country);
                            country = countryObj && countryObj!.name;
                        }

                        return (
                            <li key={index} className='credential'>
                                {!ownProfile || this.state.editEducation !== index ?
                                    <>
                                        <div className='detail-top'>
                                            <h4>{credential.qualification}</h4>
                                            {ownProfile &&
                                                <button
                                                    className={classNames('unstyled', 'edit-icon')}
                                                    aria-label={intl.formatMessage(messages.ariaEditEducationButton)}
                                                    onClick={this.editEducation.bind(this, index)}>
                                                    <Icon name='pencil' zoom='16' />
                                                </button>
                                            }
                                        </div>
                                        {credential.school &&
                                            <div className='detail-middle'>
                                                {credential.school}
                                                {credential.state && `, ${credential.state}`}
                                                {country && `, ${country}`}
                                            </div>
                                        }
                                        {credential.startYear || credential.endYear ?
                                            <div className='detail-bottom'>
                                                {credential.startYear &&
                                                    <>
                                                        <span className='start'>
                                                            {credential.startMonth !== undefined ?
                                                                moment().month(credential.startMonth).format('MMM ')
                                                                : null}
                                                            {moment().year(credential.startYear).format('YYYY')}
                                                        </span>
                                                        <span className='separator'>-</span>
                                                    </>
                                                }
                                                <span className='end'>
                                                    {credential.endYear && credential.endMonth !== undefined ?
                                                        moment().month(credential.endMonth).format('MMM ')
                                                        : null}
                                                    {credential.endYear ?
                                                        moment().year(credential.endYear).format('YYYY')
                                                        :
                                                        <WrappedMessage message={messages.presentDate} />
                                                    }
                                                </span>
                                            </div>
                                            : null}
                                        {credential.description &&
                                            <p className='detail-description'>{credential.description}</p>
                                        }
                                    </>
                                    :
                                    this.renderEducationForm()
                                }
                            </li>
                        );
                    })}
                    {ownProfile &&
                        <li>
                            {this.state.editEducation !== education.length ?
                                <button
                                    className='unstyled add-more'
                                    onClick={this.editEducation.bind(this, education.length)}>
                                    <Icon name='plus' zoom='12' />
                                    <span><WrappedMessage message={messages.addEducationButton} /></span>
                                </button>
                                :
                                this.renderEducationForm()
                            }
                        </li>
                    }
                </ul>
            </CollapsibleSubSection>
        );
    }

    private renderEducationForm() {
        const education = this.state.userProfile!.education;
        const updatedEducation = this.state.updatedEducation!;

        return (
            <div className='user-experience-form'>
                <div className='education-title'>
                    <h4><WrappedMessage message={messages.educationFormTitle}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        aria-label={intl.formatMessage(messages.ariaInputEditEducationQualification)}
                        placeholder={intl.formatMessage(messages.ariaInputEditEducationQualification)}
                        value={updatedEducation.qualification}
                        onChange={this.updateEducationQualification}
                        onKeyPress={this.handleEducationKeyPress}
                    />
                </div>
                <div className='education-description'>
                    <h4><WrappedMessage message={messages.educationFormDescription}></WrappedMessage></h4>
                    <textarea
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaInputEditEducationDescription)}
                        placeholder={intl.formatMessage(messages.ariaInputEditEducationDescription)}
                        value={updatedEducation.description || ''}
                        onChange={this.updateEducationDescription}
                    />
                </div>
                <div className='education-school'>
                    <h4><WrappedMessage message={messages.educationFormSchool}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaInputEditEducationSchool)}
                        placeholder={intl.formatMessage(messages.ariaInputEditEducationSchool)}
                        value={updatedEducation.school || ''}
                        onChange={this.updateEducationSchool}
                        onKeyPress={this.handleEducationKeyPress}
                    />
                </div>
                <div className='input-group'>
                    <div className='education-country'>
                        <h4><WrappedMessage message={messages.educationFormCountry}></WrappedMessage></h4>
                        <CountryDropdown
                            aria-label={intl.formatMessage(messages.ariaInputEditEducationCountry)}
                            value={updatedEducation.country || ''}
                            defaultOptionLabel={'Select country'}
                            valueType='short'
                            onChange={this.updateEducationCountry}
                            required
                        />
                    </div>
                    <div className='education-state'>
                        <h4><WrappedMessage message={messages.educationFormState}></WrappedMessage></h4>
                        <input
                            className='form-control'
                            aria-label={intl.formatMessage(messages.ariaInputEditEducationState)}
                            placeholder={intl.formatMessage(messages.ariaInputEditEducationState)}
                            value={updatedEducation.state || ''}
                            onChange={this.updateEducationState}
                            onKeyPress={this.handleEducationKeyPress}
                        />
                    </div>
                </div>
                <div className='input-group'>
                    <div className='education-start-date'>
                        <h4><WrappedMessage message={messages.educationFormStartDate}></WrappedMessage></h4>
                        <div className='date-group'>
                            <select
                                className='form-control month'
                                aria-label={intl.formatMessage(messages.monthLabel)}
                                defaultValue={updatedEducation.startMonth !== undefined ?
                                    updatedEducation.startMonth.toString() : undefined}
                                onBlur={this.updateEducationStartMonth}
                                onChange={this.updateEducationStartMonth}
                                required={true}>
                                {this.renderMonthSelect()}
                            </select>
                            <select
                                className='form-control year'
                                aria-label={intl.formatMessage(messages.yearLabel)}
                                defaultValue={updatedEducation.startYear !== undefined ?
                                    updatedEducation.startYear.toString() : undefined}
                                onBlur={this.updateEducationStartYear}
                                onChange={this.updateEducationStartYear}
                                required={true}>
                                {this.renderYearSelect()}
                            </select>
                        </div>
                    </div>
                    <div className='education-end-date'>
                        <h4><WrappedMessage message={messages.educationFormEndDate}></WrappedMessage></h4>
                        <div className='date-group'>
                            <select
                                className='form-control month'
                                aria-label={intl.formatMessage(messages.monthLabel)}
                                defaultValue={updatedEducation.endMonth !== undefined ?
                                    updatedEducation.endMonth.toString() : undefined}
                                onBlur={this.updateEducationEndMonth}
                                onChange={this.updateEducationEndMonth}
                                required={true}>
                                {this.renderMonthSelect()}
                            </select>
                            <select
                                className='form-control year'
                                aria-label={intl.formatMessage(messages.yearLabel)}
                                defaultValue={updatedEducation.endYear !== undefined ?
                                    updatedEducation.endYear.toString() : undefined}
                                onBlur={this.updateEducationEndYear}
                                onChange={this.updateEducationEndYear}
                                required={true}>
                                {this.renderYearSelect()}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='button-group'>
                    <div>
                        {education && this.state.editEducation < education.length ?
                            <button
                                className='unstyled delete-button'
                                onClick={this.deleteEducation}
                            >
                                <WrappedMessage message={messages.deleteLink} />
                            </button>
                            :
                            <button
                                className='unstyled cancel-button'
                                onClick={this.cancelEducation}
                            >
                                <WrappedMessage message={messages.cancelLink} />
                            </button>
                        }
                    </div>
                    <div>
                        <button
                            className='btn btn-light save-button'
                            onClick={this.saveEducation}
                        >
                            <WrappedMessage message={messages.educationFormSaveButton} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    @bind private editEducation(
        index: number,
        event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    ) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        const education = this.state.userProfile!.education;
        let updatedEducation: UserEducation = {
            qualification: '',
            description: '',
            school: '',
            country: '',
            state: '',
            startMonth: undefined,
            startYear: undefined,
            endMonth: undefined,
            endYear: undefined,
        };
        if (education && education.length && index > -1 && index < education.length) {
            updatedEducation = education[index];
        }
        this.setState({ updatedEducation, editEducation: index });
    }

    @bind private async saveEducation() {
        const updatedEducation = this.state.updatedEducation;

        /* Do a little client-side validation. */
        if (updatedEducation && !updatedEducation.qualification) {
            showErrorMessage(<WrappedMessage message={messages.errorMissingEducationTitle} />);
            return;
        }
        if (updatedEducation && updatedEducation.startYear && updatedEducation.endYear) {
            let dStart = updatedEducation.startYear;
            if (updatedEducation.startMonth) {
                dStart = dStart + updatedEducation.startMonth / 100;
            }
            let dEnd = updatedEducation.endYear;
            if (updatedEducation.endMonth) {
                dEnd = dEnd + updatedEducation.endMonth / 100;
            }

            if (dStart > dEnd) {
                showErrorMessage(<WrappedMessage message={messages.errorEndDateShouldBeLater} />);
                return;
            }
        }

        const index = this.state.editEducation;
        const userProfile = this.state.userProfile!;
        let education: UserEducation[] = [];
        if (userProfile.education) {
            education = userProfile.education.slice();
        }
        if (updatedEducation && index > -1 && index <= education.length) {
            education[index] = updatedEducation;
            education.sort(this.sortByEndYear);
            const updateData = {
                education: education.map(UserEducationToJSON),
            };

            let errors = false;
            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                errors = true;
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            if (!errors) {
                this.setState({
                    userProfile: update(this.state.userProfile!, { education: { $set: education } }),
                    editEducation: -1,
                    updatedEducation: undefined,
                });
            }
        }
    }

    @bind private handleEducationKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.saveEducation();
        }
    }

    @bind private async cancelEducation() {
        this.setState({
            editEducation: -1,
            updatedEducation: undefined,
        });
    }

    @bind private async deleteEducation() {
        const index = this.state.editEducation;
        const userProfile = this.state.userProfile!;
        let education: UserEducation[] = [];
        if (userProfile.education) {
            education = userProfile.education.slice();
        }
        if (education.length && index > -1 && index < education.length) {
            education.splice(index, 1);
            const updateData = {
                education: education.map(UserEducationToJSON),
            };

            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            this.setState({
                userProfile: update(this.state.userProfile!, { education: { $set: education } }),
                editEducation: -1,
                updatedEducation: undefined,
            });
        } else {
            this.cancelEducation();
        }
    }

    @bind private updateEducationQualification(event: React.ChangeEvent<HTMLInputElement>) {
        const qualification = event.target.value;
        this.setState({
            updatedEducation: update(this.state.updatedEducation, { qualification: { $set: qualification } }),
        });
    }

    @bind private updateEducationDescription(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const description = event.target.value;
        this.setState({
            updatedEducation: update(this.state.updatedEducation, { description: { $set: description } }),
        });
    }

    @bind private updateEducationSchool(event: React.ChangeEvent<HTMLInputElement>) {
        const school = event.target.value;
        this.setState({
            updatedEducation: update(this.state.updatedEducation, { school: { $set: school } }),
        });
    }

    @bind private updateEducationCountry(country: any) {
        this.setState({
            updatedEducation: update(this.state.updatedEducation, { country: { $set: country } }),
        });
    }

    @bind private updateEducationState(event: React.ChangeEvent<HTMLInputElement>) {
        const state = event.target.value;
        this.setState({
            updatedEducation: update(this.state.updatedEducation, { state: { $set: state } }),
        });
    }

    @bind private updateEducationStartMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let month;
        if (event.target.value !== '') {
            month = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedEducation: update(this.state.updatedEducation, { startMonth: { $set: month } }),
        });
    }

    @bind private updateEducationStartYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let year;
        if (event.target.value !== '') {
            year = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedEducation: update(this.state.updatedEducation, { startYear: { $set: year } }),
        });
    }

    @bind private updateEducationEndMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let month;
        if (event.target.value !== '') {
            month = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedEducation: update(this.state.updatedEducation, { endMonth: { $set: month } }),
        });
    }

    @bind private updateEducationEndYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let year;
        if (event.target.value !== '') {
            year = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedEducation: update(this.state.updatedEducation, { endYear: { $set: year } }),
        });
    }

    private renderAwards() {
        if (this.state.userProfile === undefined) {
            return null;
        }
        const awards: UserAward[] = this.state.userProfile.awards || [];
        const ownProfile = this.isOwnProfile();

        if (awards.length === 0 && !ownProfile) {
            return null;
        }

        return (
            <CollapsibleSubSection
                id='awards-container'
                className='awards-container'
                title={messages.userAwards}>
                <ul className='awards'>
                    {awards.slice().map((award, index) => {
                        return (
                            <li key={index} className='award'>
                                {!ownProfile || this.state.editAward !== index ?
                                    <>
                                        <div className='detail-top'>
                                            <h4>{award.awardTitle}</h4>
                                            {ownProfile &&
                                                <button
                                                    className={classNames('unstyled', 'edit-icon')}
                                                    aria-label={intl.formatMessage(messages.ariaEditAwardButton)}
                                                    onClick={this.editAward.bind(this, index)}>
                                                    <Icon name='pencil' zoom='16' />
                                                </button>
                                            }
                                        </div>
                                        {award.awardedBy &&
                                            <div className='detail-middle'>{award.awardedBy}</div>
                                        }
                                        {award.awardYear &&
                                            <div className='detail-bottom'>{moment().year(award.awardYear).format('YYYY')}</div>
                                        }
                                    </>
                                    :
                                    this.renderAwardForm()
                                }
                            </li>
                        );
                    })}
                    {ownProfile &&
                        <li>
                            {this.state.editAward !== awards.length ?
                                <button
                                    className='unstyled add-more'
                                    onClick={this.editAward.bind(this, awards.length)}>
                                    <Icon name='plus' zoom='12' />
                                    <span><WrappedMessage message={messages.addAwardButton} /></span>
                                </button>
                                :
                                this.renderAwardForm()
                            }
                        </li>
                    }
                </ul>
            </CollapsibleSubSection>
        );
    }

    private renderAwardForm() {
        const awards = this.state.userProfile!.awards;
        const updatedAward = this.state.updatedAward!;

        return (
            <div className='user-experience-form'>
                <div className='award-title'>
                    <h4><WrappedMessage message={messages.awardFormTitle}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        aria-label={intl.formatMessage(messages.ariaInputEditAwardTitle)}
                        placeholder={intl.formatMessage(messages.ariaInputEditAwardTitle)}
                        value={updatedAward.awardTitle || ''}
                        onChange={this.updateAwardTitle}
                        onKeyPress={this.handleAwardKeyPress}
                    />
                </div>
                <div className='award-by'>
                    <h4><WrappedMessage message={messages.awardFormBy}></WrappedMessage></h4>
                    <input
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaInputEditAwardBy)}
                        placeholder={intl.formatMessage(messages.ariaInputEditAwardBy)}
                        value={updatedAward.awardedBy || ''}
                        onChange={this.updateAwardBy}
                        onKeyPress={this.handleAwardKeyPress}
                    />
                </div>
                <div className='award-year'>
                    <h4><WrappedMessage message={messages.awardFormYear}></WrappedMessage></h4>
                    <select
                        className='form-control year'
                        aria-label={intl.formatMessage(messages.yearLabel)}
                        defaultValue={updatedAward.awardYear !== undefined ?
                            updatedAward.awardYear.toString() : undefined}
                        onBlur={this.updateAwardYear}
                        onChange={this.updateAwardYear}
                        required={true}>
                        {this.renderYearSelect()}
                    </select>
                </div>
                <div className='button-group'>
                    <div>
                        {awards && this.state.editAward < awards.length ?
                            <button
                                className='unstyled delete-button'
                                onClick={this.deleteAward}
                            >
                                <WrappedMessage message={messages.deleteLink} />
                            </button>
                            :
                            <button
                                className='unstyled cancel-button'
                                onClick={this.cancelAward}
                            >
                                <WrappedMessage message={messages.cancelLink} />
                            </button>
                        }
                    </div>
                    <div>
                        <button
                            className='btn btn-light save-button'
                            onClick={this.saveAward}
                        >
                            <WrappedMessage message={messages.awardFormSaveButton} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    @bind private editAward(
        index: number,
        event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    ) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        const awards = this.state.userProfile!.awards;
        let updatedAward: UserAward = {
            awardTitle: '',
            awardedBy: '',
            awardYear: undefined,
        };
        if (awards && awards.length && index > -1 && index < awards.length) {
            updatedAward = awards[index];
        }
        this.setState({ updatedAward, editAward: index });
    }

    @bind private async saveAward() {
        const updatedAward = this.state.updatedAward;

        /* Do a little client-side validation. */
        if (updatedAward && !updatedAward.awardTitle) {
            showErrorMessage(<WrappedMessage message={messages.errorMissingAwardTitle} />);
            return;
        }

        const index = this.state.editAward;
        const userProfile = this.state.userProfile!;
        let awards: UserAward[] = [];
        if (userProfile.awards) {
            awards = userProfile.awards.slice();
        }
        if (updatedAward && index > -1 && index <= awards.length) {
            awards[index] = updatedAward;
            awards.sort(this.sortAwards);
            const updateData = {
                awards: awards.map(UserAwardToJSON),
            };

            let errors = false;
            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                errors = true;
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            if (!errors) {
                this.setState({
                    userProfile: update(this.state.userProfile!, { awards: { $set: awards } }),
                    editAward: -1,
                    updatedAward: undefined,
                });
            }
        }
    }

    @bind private handleAwardKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.saveAward();
        }
    }

    @bind private async cancelAward() {
        this.setState({
            editAward: -1,
            updatedAward: undefined,
        });
    }

    @bind private async deleteAward() {
        const index = this.state.editAward;
        const userProfile = this.state.userProfile!;
        let awards: UserAward[] = [];
        if (userProfile.awards) {
            awards = userProfile.awards.slice();
        }
        if (awards.length && index > -1 && index < awards.length) {
            awards.splice(index, 1);
            const updateData = {
                awards: awards.map(UserAwardToJSON),
            };

            try {
                await UsersApi.profilesPartialUpdate({
                    id: this.state.userProfile!.username,
                    data: updateData,
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorSavingProfileChanges} />, { exception: err });
            }

            this.setState({
                userProfile: update(this.state.userProfile!, { awards: { $set: awards } }),
                editAward: -1,
                updatedAward: undefined,
            });
        } else {
            this.cancelAward();
        }
    }

    @bind private updateAwardTitle(event: React.ChangeEvent<HTMLInputElement>) {
        const awardTitle = event.target.value;
        this.setState({
            updatedAward: update(this.state.updatedAward, { awardTitle: { $set: awardTitle } }),
        });
    }

    @bind private updateAwardBy(event: React.ChangeEvent<HTMLInputElement>) {
        const awardedBy = event.target.value;
        this.setState({
            updatedAward: update(this.state.updatedAward, { awardedBy: { $set: awardedBy } }),
        });
    }

    @bind private updateAwardYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let year;
        if (event.target.value !== '') {
            year = parseInt(event.target.value, 10);
        }

        this.setState({
            updatedAward: update(this.state.updatedAward, { awardYear: { $set: year } }),
        });
    }

    private sortByEndYear(a: UserProfession | UserEducation, b: UserProfession | UserEducation) {
        /* Sort by date, descending, taking into account that an undefined `endDate` represents `now()`.
         * This routine assumes that `endYear` is always later than `startYear`. */
        let d1;
        if (a.endYear) {
            d1 = a.endYear + (a.endMonth !== undefined ? a.endMonth / 100 : 0);
        }

        let d2;
        if (b.endYear) {
            d2 = b.endYear + (b.endMonth !== undefined ? b.endMonth / 100 : 0);
        }

        let retval;
        if (d1 === undefined && d2 === undefined) {
            retval = 0;
        } else if (d1 === undefined) {
            retval = -1;
        } else if (d2 === undefined) {
            retval = 1;
        } else if (d1 > d2) {
            retval = -1;
        } else if (d1 === d2) {
            retval = 0;
        } else {
            retval = 1;
        }

        return retval;
    }

    private sortAwards(a: UserAward, b: UserAward) {
        /* Sort by year, descending. In this case, an undefined year comes later. */
        let retval;
        if (a.awardYear === undefined && a.awardYear === undefined) {
            retval = 0;
        } else if (a.awardYear === undefined) {
            retval = 1;
        } else if (b.awardYear === undefined) {
            retval = -1;
        } else if (a.awardYear > b.awardYear) {
            retval = -1;
        } else if (a.awardYear === b.awardYear) {
            retval = 0;
        } else {
            retval = 1;
        }

        return retval;
    }
}

export const UserProfilePage = connect<ReduxStateProps, ReduxActionProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        profileEdit: false,
    }), {
    reloadAvatarImage: UserActions.reloadOwnAvatarImage,
},
)(UserProfilePageInternal);

// Separate component for profile edit UI
export const UserProfileEditPage = connect<ReduxStateProps, ReduxActionProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        profileEdit: true,
    }), {
    reloadAvatarImage: UserActions.reloadOwnAvatarImage,
},
)(UserProfilePageInternal);
