// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ModuleSibling,
    ModuleSiblingFromJSON,
    ModuleSiblingToJSON,
    ModuleSlab,
    ModuleSlabFromJSON,
    ModuleSlabToJSON,
} from './';

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    learningObjectivesHeading: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Module
     */
    breadcrumbs: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Module
     */
    learningObjectives: Array<string>;
    /**
     * 
     * @type {Array<ModuleSlab>}
     * @memberof Module
     */
    moduleSlabs: Array<ModuleSlab>;
    /**
     * 
     * @type {Array<ModuleSibling>}
     * @memberof Module
     */
    siblings: Array<ModuleSibling>;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    activeVersion: string;
}

export function ModuleFromJSON(json: any): Module {
    return {
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        'learningObjectivesHeading': json['learning_objectives_heading'],
        'breadcrumbs': json['breadcrumbs'],
        'learningObjectives': json['learning_objectives'],
        'moduleSlabs': (json['module_slabs'] as Array<any>).map(ModuleSlabFromJSON),
        'siblings': (json['siblings'] as Array<any>).map(ModuleSiblingFromJSON),
        'activeVersion': json['active_version'],
    };
}

export function ModuleToJSON(value?: Module): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'learning_objectives_heading': value.learningObjectivesHeading,
        'breadcrumbs': value.breadcrumbs,
        'learning_objectives': value.learningObjectives,
        'module_slabs': (value.moduleSlabs as Array<any>).map(ModuleSlabToJSON),
        'siblings': (value.siblings as Array<any>).map(ModuleSiblingToJSON),
        'active_version': value.activeVersion,
    };
}


