import * as React from 'react';
import { Link } from 'react-router-dom';

import {
    ItemResponse,
    ItemMetadataTypeEnum,
    PathwayDetail,
    ChildItem,
    OrganizationSummaryOrganizationTypeEnum,
    ItemMetadata,
} from 'labxchange-client';
import { getItemTypeMeta } from 'items/models';
import { ItemIcon } from 'library/components';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import { Button } from 'ui/components';
import { ROUTES } from 'global/constants';

import messages from './displayMessages';
import classNames from 'classnames';

export interface PathwayCardProps {
    pathway: ItemResponse;
    pathwayDetails?: PathwayDetail;
    onClone(pathwayId: string): void;
    onView(pathwayId: string): void;
    pathwayIndex?: number;
    showExploreButton?: boolean;
    onClickExploreButton?: (pathway: ItemMetadata) => void;
}

const ITEMS_TO_RENDER = 3;

export class PathwayCard extends React.PureComponent<PathwayCardProps> {
    private renderItemTypeInfo(item: ChildItem) {
        if (true) {
            return (
                <div className='media-info'>
                    <ItemIcon itemType={item.item.metadata.type} zoom='14'/>
                    <span>
                        <WrappedMessage
                            message={getItemTypeMeta(item.item.metadata.type).name}
                            values={{count: item.item.metadata.itemCount || 0}}
                        />
                    </span>
                </div>
            );
        }
    }

    private renderItem(item: ChildItem, index: number) {
        const pathwayId = this.props.pathway.metadata.id;
        const itemId = item.item.metadata.id;
        const relId = item.item.metadata.relId;
        return (
            <Link
                to={ROUTES.Library.PATHWAY_ITEM_SLUG(pathwayId, itemId, relId)}
                key={index}
                className='pathway-content-row'
            >
                <span className='item-title'>
                    {item.item.metadata.title}
                </span>
                <div className='media-info-wrapper'>
                    {this.renderItemTypeInfo(item)}
                </div>
            </Link>
        );
    }

    private renderEmptyItem(index: number) {
        return (
            <div key={index} className='pathway-content-row hidden'>
                <span className='item-title'>
                    Filler text
                </span>
                <div className='media-info-wrapper'>
                    <div className='media-info'>
                        <ItemIcon itemType={ItemMetadataTypeEnum.Simulation} zoom='14'/>
                        <span>
                            <WrappedMessage
                                message={getItemTypeMeta(ItemMetadataTypeEnum.Simulation).name}
                            />
                        </span>
                </div>
                </div>
            </div>
        );
    }

    private renderItemsCards(items: ChildItem[]) {
        const itemsToRender = items.slice(0, ITEMS_TO_RENDER);
        const emptyItemsCount = ITEMS_TO_RENDER - itemsToRender.length;
        const finalItems = [...itemsToRender, ...Array(emptyItemsCount).fill(null)];

        return finalItems.map((item, index) => {
            if (item) {
                return this.renderItem(item, index);
            } else {
                return this.renderEmptyItem(index);
            }
        });
    }

    private getMoreCount(totalItems: number): number {
        const itemsNotRendered = totalItems - ITEMS_TO_RENDER;
        return itemsNotRendered > 0 ? itemsNotRendered : 0;
    }

    public render() {
        const pathway = this.props.pathway.metadata;
        let sourceOrg = null;
        let orgIsPartner = false;
        let orgUrl = '#';
        const moreCount = this.props.pathwayDetails ?
            this.getMoreCount(this.props.pathwayDetails.items.length) : 0;

        if (pathway.source && pathway.source.sourceOrganizations) {
            sourceOrg = pathway.source.sourceOrganizations[0].organization;
            if (
                sourceOrg.organizationType ===
                  OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
                sourceOrg.organizationType ===
                  OrganizationSummaryOrganizationTypeEnum.PARTNER
            ) {
                orgUrl = ROUTES.Organizations.PROFILE_SLUG(sourceOrg.slug);
                orgIsPartner = true;
            } else if (sourceOrg.url) {
                orgUrl = sourceOrg.url;
                orgIsPartner = false;
            }
        }

        const providerLink = (content: any) => {
            if (orgIsPartner) {
                return (
                    <Link to={orgUrl} className='item-source-provider-url' data-testid='partner-link'>
                        {content}
                    </Link>
                );
            } else {
                return (
                    <a
                        href={orgUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='item-source-provider-url'
                        data-testid='external-link'
                    >
                        {content}
                    </a>
                );
            }
        };

        return (
            <div className={classNames('pathway-card', { 'positive-margin':this.props.pathwayIndex === 0 })}>
                <Link
                    to={ROUTES.Library.PATHWAY_SLUG(pathway.id)}
                    className='left-section'
                    style={{ backgroundImage: `url('${pathway.imageUrl}')` }}
                >
                    <div className='left-section-content'>
                        <div className='pathway-title'>
                            <h3>{pathway.title}</h3>
                        </div>
                        {/* Disabling linter for next line because the div is used to stop event propagation */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div className='provider-info' onClick={(e) => e.stopPropagation()}>
                            {sourceOrg ?
                                providerLink(
                                    <>
                                        <img
                                            className='pathway-note-avatar rounded-circle'
                                            src={sourceOrg.logoUrl}
                                            style={{backgroundColor: sourceOrg.brandColor}}
                                            alt='Provider logo'
                                        />
                                        <h4>{sourceOrg.name}</h4>
                                    </>
                                )
                                : pathway.authors.length > 0 && pathway.authors[0].username ?
                                    <div className='item-source-provider-url'>
                                        <UserAvatar
                                            username={pathway.authors[0].username}
                                            diameter='36px'
                                        />
                                        <h4>{pathway.authors[0].fullName}</h4>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </Link>
                {this.props.pathwayDetails ?
                    <div className='right-section'>
                        <div className='right-section-content'>
                            <h4><WrappedMessage message={messages.pathwayCardsCovers}/></h4>
                            {this.renderItemsCards(this.props.pathwayDetails.items)}
                            <div className={moreCount > 0 ? 'more-count' : 'more-count transparent'}>
                                <span>+{moreCount} more</span>
                            </div>
                            <div className='action-buttons'>
                                {this.props.showExploreButton ?
                                    <Button
                                        className='explore-button'
                                        btnStyle='primary'
                                        label={messages.pathwayCardExploreButton}
                                        onClick={() => {
                                            if (this.props.onClickExploreButton) {
                                                this.props.onClickExploreButton(pathway);
                                            }
                                        }}
                                    />
                                    :
                                    <>
                                        <Button
                                            className='clone-button'
                                            btnStyle='outline'
                                            label={messages.pathwayEducatorCloneEditButton}
                                            onClick={() => this.props.onClone(pathway.id)}
                                        />
                                        <Button
                                            className='view-button'
                                            btnStyle='primary'
                                            label={messages.pathwayEducatorViewButton}
                                            onClick={() => this.props.onView(pathway.id)}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        );
    }
}
