import React from 'react';

import { Module, ModuleSlab } from 'labxchange-client';

import { ModuleAssetCard } from '../ModuleAssetCard';

interface GenericSlabSlabProps {
  slab: ModuleSlab;
  curriculumSlug: string;
  module: Module;
  showFeedbackButton?: boolean;
  subHeading?: string;
  selectedFilters?: string[];
}

export const GenericSlab = ({
  slab, showFeedbackButton, subHeading, curriculumSlug, module, selectedFilters
}: GenericSlabSlabProps) => {
  return (
    <div className='generic-slab'>
      <div className='title-subheading'>
        <h2 className='title font-s-lt'>{slab.title}</h2>
        {subHeading && <span className='subheading'>{subHeading}</span>}
      </div>
      <div className='slab-content'>
        {slab.items.map((asset) => (
          <ModuleAssetCard
            key={asset.metadata.id}
            asset={asset}
            curriculumSlug={curriculumSlug}
            module={module}
            showFeedbackButton={showFeedbackButton}
            selectedFilters={selectedFilters}
          />
        ))}
      </div>
    </div>
  );
};
