import * as React from 'react';
import './styles.scss'

interface InputTextProps {
  name: string;
  className: string;
  ariaLabel: string;
  onChange: (value: string) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  inputGroupAppend: React.ReactNode;
  inline: boolean,
  id?: string,
  label?: string | Element,
  readonly?: boolean
  placeholder?: string;

}

export function InputText(
  {
    name,
    className,
    ariaLabel,
    onChange,
    onKeyPress,
    value,
    inputGroupAppend,
    inline,
    id,
    label = '',
    readonly = false,
    placeholder = '',
  }: InputTextProps) {

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }
  return <div className='input-group'>
    <label htmlFor={name}>{label}</label>
    <input className={`${className} input-text ${!inline && 'rounded-borders'}`} id={id}
      type='text'
      name={name}
      placeholder={placeholder}
      aria-label={ariaLabel}
      onChange={onTextChange}
      onKeyPress={onKeyPress}
      value={value}
      readOnly={readonly}
    />
    {inputGroupAppend}
  </div>
}
