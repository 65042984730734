import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import messages from '../displayMessages';
import { intl } from '../../i18n';

import ViewToggle, { ViewType } from './ToggleHomeView';
import CategoryHome from './CategoryHome/CategoryHome';
import { CareerHome } from './';
import { CareerStandardLayout } from './CareerStandardLayout';
import { CareerSearchBar } from './CareerSearchBar';
import { ROUTES } from '../../global/constants';
import {CareerHeader} from './CareerHeader';

const HomePage: React.FC = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('query');
    const viewQuery = queryParams.get('viewType') as ViewType;
    const internalUser = queryParams.get('isInternal');
    const history = useHistory();

    const [view, setView] = useState<ViewType>(viewQuery || 'category');

    const handleViewChange = (newView: ViewType) => {
        setView(newView);
    };

    const onSearch = (searchQuery: string, viewType: ViewType) => {
        if (searchQuery) {
            let searchUrl = `${ROUTES.CareerExplorer.HOME}?query=${searchQuery}&viewType=${viewType}`;
            if (internalUser) {
                searchUrl = `${searchUrl}&isInternal=${internalUser}`;
            }
            history.push(searchUrl);
        }
    };

    const heroSection = (
        <div className='home-hero-section'>
            {query
                ?
                <>
                    <span>{intl.formatMessage(messages.careerShowingResultsLabel)}</span>
                    <h4>{query}</h4>
                </>
                :
                <>
                    <h4>{intl.formatMessage(messages.careerNavHeaderTitle)}</h4>
                    <span>
                        {view === 'category' ? intl.formatMessage(messages.categoryNavHeaderSubtitle) : intl.formatMessage(messages.careerNavHeaderSubtitle)}
                    </span>
                    <CareerSearchBar
                        onSubmit={onSearch}
                        viewType={view}
                    />
                </>
            }
        </div>
    );

    return (
        <CareerStandardLayout
            mainClassName='career-home-page'
            pageTitle={messages.careerDefaultPageTitle}
            headerComponent={
                <CareerHeader
                    heroSection={heroSection}
                    hideSearchBar={query ? false : true}
                />
            }
        >
            <div className='home-page'>
                <ViewToggle view={view} onViewChange={handleViewChange}/>
                {view === 'category' &&
                  <CategoryHome/>
                }
                {view === 'career' && <CareerHome/>}
            </div>
        </CareerStandardLayout>
    );
};

export default HomePage;
