import {bind} from 'bind-decorator';
import * as React from 'react';
import {
    Icon,
    IconSymbol,
} from 'ui/components';

import { Collapsible } from '@labxchange/ui-components';

interface CollapsibleWithChevronProps extends React.PropsWithChildren {
    title?: string | React.ReactElement;
    defaultOpen: boolean;
    headerIcon?: IconSymbol;
    headerIconZoom?: string;
    headerActions?: React.ReactElement;
    chevronIconZoom?: string;
    onExpand?: () => void;
    onToggleCallback?: (isOpen: boolean) => void;
}

interface CollapsibleWithChevronState {
    isOpen: boolean;
}

export class CollapsibleWithChevron extends React.Component<CollapsibleWithChevronProps, CollapsibleWithChevronState> {

    public constructor(props: CollapsibleWithChevronProps) {
        super(props);
        this.state = {
            isOpen: this.props.defaultOpen,
        };
    }

    public render() {
        const title = (
            <div className='collapsible-with-chevron-title'>
                <span className='collapsible-with-chevron-title-title'>
                    {this.props.headerIcon ?
                        <Icon
                            name={this.props.headerIcon}
                            zoom={this.props.headerIconZoom}
                        />
                    : null}
                    {this.props.title}
                </span>
                <div>
                    {this.props.headerActions}
                    <Icon
                        className='collapsible-with-chevron-title-chevron'
                        name={this.state.isOpen ? 'chevron-up' : 'chevron-down'}
                        zoom={this.props.chevronIconZoom || '1.4em'}
                    />
                </div>
            </div>
        );

        return (
            <div className='collapsible-with-chevron' data-testid='collapsible-with-chevron'>
                <Collapsible
                    title={title}
                    defaultOpen={this.props.defaultOpen}
                    isOpen={this.state.isOpen}
                    onToggle={this.onToggle}
                    isCollapsible={true}
                >
                    {this.props.children}
                </Collapsible>
            </div>
        );
    }

    @bind private onToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        }, () => {
            if (this.state.isOpen && this.props.onExpand) {
                this.props.onExpand();
            }
        });
        if (this.props.onToggleCallback) {
            this.props.onToggleCallback(!this.state.isOpen);
        }
    }
}
