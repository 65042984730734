import React, { useState, useEffect, useCallback } from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';
import { CareerCategory } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { Spinner } from 'ui/components';
import messages from '../../displayMessages';
import { isKeyboardEnterEvent } from '../../../global/utils';
import {colorCalculator, ColorScheme, useColor} from '../../ThemeProvider';

export const CategoryHome: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get('query') || '';
  const internalUser = queryParams.get('isInternal');
  const history = useHistory();

  const { setSelectedColor } = useColor();
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<CareerCategory[]>([]);

  useEffect(() => {
    if (!internalUser) {
      history.push(ROUTES.Explore.HOME);
    }
  }, [internalUser, history]);

  const fetchCategories = useCallback(async (value?: string) => {
    try {
      setLoading(true);
      const response = await CareersApi.categoriesList({ title: value });
      setCategories(response);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error fetching categories', error);
      setCategories([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories(query);
  }, [query, fetchCategories]);

  const handleClick = useCallback((selectedCategory: CareerCategory, color: ColorScheme) => {
    setSelectedColor(color);
    onClickCard(selectedCategory);
  }, [setSelectedColor]);

  const onClickCard = (selectedCategory: CareerCategory) => {
    history.push({
      pathname: ROUTES.CareerExplorer.CAREER_SUBCATEGORY(Number(selectedCategory.id)),
      state: { categoryData: selectedCategory }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
      <div className='career-home'>
        {!categories.length && query && !loading ? (
            <div className='empty-state'>
              <img
                  className='empty-state-image'
                  alt='No results found'
                  src='/assets/images/category-explorer/category-empty-state.svg'
              />
              <h5>{intl.formatMessage(messages.careerNoResultsHeading)}</h5>
              <span>{intl.formatMessage(messages.careerNoResultsSubheading)}</span>
            </div>
        ) : (
            <div className='category-list'>
              {categories.map((category, index) => {
                const colorInfo = colorCalculator(index);
                return (
                    <div
                        tabIndex={0}
                        role='button'
                        className={`category-list__card ${colorInfo.class}`}
                        onClick={() => handleClick(category, colorInfo)}
                        onKeyDown={(e) => isKeyboardEnterEvent(e) && handleClick(category, colorInfo)}
                    >
                      <div className='category-list__card__header'>
                        {category.categoryName}
                      </div>
                      <div className='category-list__card__content'>
                        <div className='category-list__card__content__background' style={{ background: colorInfo.iconColorFill }}></div>
                        <img
                            className='category-list__card__content__icon'
                            alt='Career Icon'
                            src='/assets/images/career-explorer/career-card-placeholder.svg'
                        />
                      </div>
                    </div>
                );
              })}
            </div>
        )}
      </div>
  );
};

export default CategoryHome;
