// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerAppliedFilter,
    CareerAppliedFilterFromJSON,
    CareerAppliedFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerSearchRequest
 */
export interface CareerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof CareerSearchRequest
     */
    keywords?: string;
    /**
     * 
     * @type {Array<CareerAppliedFilter>}
     * @memberof CareerSearchRequest
     */
    filters?: Array<CareerAppliedFilter>;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchRequest
     */
    ordering?: CareerSearchRequestOrderingEnum;
    /**
     * 
     * @type {number}
     * @memberof CareerSearchRequest
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerSearchRequest
     */
    paginationSize?: number;
}

export function CareerSearchRequestFromJSON(json: any): CareerSearchRequest {
    return {
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'filters': !exists(json, 'filters') ? undefined : (json['filters'] as Array<any>).map(CareerAppliedFilterFromJSON),
        'ordering': !exists(json, 'ordering') ? undefined : json['ordering'],
        'currentPage': !exists(json, 'current_page') ? undefined : json['current_page'],
        'paginationSize': !exists(json, 'pagination_size') ? undefined : json['pagination_size'],
    };
}

export function CareerSearchRequestToJSON(value?: CareerSearchRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'keywords': value.keywords,
        'filters': value.filters === undefined ? undefined : (value.filters as Array<any>).map(CareerAppliedFilterToJSON),
        'ordering': value.ordering,
        'current_page': value.currentPage,
        'pagination_size': value.paginationSize,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerSearchRequestOrderingEnum {
    Relevance = 'relevance',
    Alphabetical = 'alphabetical'
}


