// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CurriculumSubject,
    CurriculumSubjectFromJSON,
    CurriculumSubjectToJSON,
    ModuleHierarchy,
    ModuleHierarchyFromJSON,
    ModuleHierarchyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumModules
 */
export interface CurriculumModules {
    /**
     * Get modules associated with a curriculum.
     * @type {Array<ModuleHierarchy>}
     * @memberof CurriculumModules
     */
    readonly modules?: Array<ModuleHierarchy>;
    /**
     * Get all public subjects in curriculum.
     * @type {Array<CurriculumSubject>}
     * @memberof CurriculumModules
     */
    readonly subjects?: Array<CurriculumSubject>;
    /**
     * 
     * @type {string}
     * @memberof CurriculumModules
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumModules
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumModules
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurriculumModules
     */
    isPublic?: boolean;
    /**
     * Please provide learning objective title as singular i.e. `Core concept` instead of `Core Concepts`The pluralization will be handled on the frontend based on the number of learning objectivesthat needs to be shown under the heading
     * @type {string}
     * @memberof CurriculumModules
     */
    learningObjectivesHeading?: string;
}

export function CurriculumModulesFromJSON(json: any): CurriculumModules {
    return {
        'modules': !exists(json, 'modules') ? undefined : (json['modules'] as Array<any>).map(ModuleHierarchyFromJSON),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] as Array<any>).map(CurriculumSubjectFromJSON),
        'country': json['country'],
        'slug': json['slug'],
        'name': json['name'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'learningObjectivesHeading': !exists(json, 'learning_objectives_heading') ? undefined : json['learning_objectives_heading'],
    };
}

export function CurriculumModulesToJSON(value?: CurriculumModules): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'country': value.country,
        'slug': value.slug,
        'name': value.name,
        'is_public': value.isPublic,
        'learning_objectives_heading': value.learningObjectivesHeading,
    };
}


