import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import './styles.scss';

export interface CollapsibleProps {
  title: React.ReactNode,
  children: React.ReactNode,
  onToggle?: () => void,
  onOpen?: () => void,
  onClose?: () => void,
  className?: string,
  showMarkers?: boolean,
  isCollapsible?: boolean,
  isOpen?: boolean,
  closeMarker?: React.JSX.Element,
  openMarker?: React.JSX.Element,
}

const openIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='8' height='10' viewBox='0 0 8 10' fill='none'>
    <path d='M8 5L0.500004 9.33012L0.500004 0.669873L8 5Z' fill='#333333' />
  </svg>
);

const closeIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'>
    <path d='M5 8L0.669877 0.500003L9.33013 0.500004L5 8Z' fill='#333333' />
  </svg>
);

export const Collapsible = ({
  title,
  children,
  onToggle,
  onOpen,
  onClose,
  className = '',
  showMarkers = false,
  isCollapsible = true, 
  isOpen = false,
  closeMarker = closeIcon,
  openMarker = openIcon,
}: CollapsibleProps) => {
  const [isExpanded, setisExpanded] = useState(isOpen);

  const toggleOpen = () => {
    onToggle?.();
    isExpanded ? onClose?.() : onOpen?.();
    setisExpanded(!isExpanded);
  };

  return (
    <div className={classNames('collapsible', { 'is-open': isExpanded })} >
      <div
        role="button"
        onClick={toggleOpen}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            toggleOpen();
          }
        }}
        tabIndex={0}
        className={`${className} collapsible-trigger`}
      >
        <div className={classNames({ 'with-markers': showMarkers })}>
          {showMarkers ? (
            isExpanded ? closeMarker : openMarker
          ) : null}
          <span className="collapsible-title">{title}</span>
        </div>
      </div>
      {(!isCollapsible || isExpanded) && (
        <div className="collapsible-body">
          {children}
        </div>
      )}
    </div>
  );
}

