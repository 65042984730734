import { UILanguages } from 'i18n';
type SortOptionLocale  = {
    [k in keyof typeof UILanguages]: string;
};


// TODO: convert these to standard i18n message descriptors,
// so we can translate these in transifex instead.
export const SORT_OPTIONS_RELEVANCE: SortOptionLocale = {
    'en': 'Relevance',
    'nl': 'Relevantie',
    'de': 'Relevanz',
    'it': 'Rilevanza',
    'ja': '関連性',
    'fr': 'Pertinence',
    'ko': '관련성',
    'tr-tr': 'Alaka düzeyi',
    'ar-ae': 'ملاءمة',
    'es-419': 'Relevancia',
    'pt-br': 'Relevância',
    'zh-hans': '关联',
    'pl': 'Trafność',
    'zu': 'Ukufaneleka',
    'uk': 'Релевантність',
    'vi': 'sự liên quan',
    'az': 'Uyğunluq',
    'ro': 'Relevanţă',
    'ka': 'შესაბამისობა',
    'ru': 'Актуальность',
    'af': 'Relevansie',
    'bn-bd': 'প্রাসঙ্গিকতা',
    'sw-ke': 'Umuhimu',
    'id': 'Relevansi',
    'ur-pk': 'مطابقت',
    'ms-my': 'Perkaitan',
    'th': 'ความเกี่ยวข้อง',
    'tl': 'kaugnayan',
    'hi': 'प्रासंगिकता',
    'mr': 'प्रासंगिकता',
    'ta-in': 'சம்பந்தம்',
    'ne': 'सान्दर्भिकता',
};

export const SORT_OPTIONS_LAST_ADDED: SortOptionLocale = {
    'en': 'Last Added',
    'nl': 'Laatst toegevoegd',
    'de': 'Zuletzt hinzugefügt',
    'it': 'Ultimo aggiunto',
    'ja': '最終追加',
    'fr': 'Dernier ajouté',
    'ko': '마지막 추가',
    'tr-tr': 'En son eklenmiş',
    'ar-ae': 'آخر يضاف',
    'es-419': 'Última incorporacion',
    'pt-br': 'Último adicionado',
    'zh-hans': '最后添加',
    'pl': 'Ostatnio dodane',
    'zu': 'Igcine ukufakwa',
    'uk': 'Останній доданий',
    'vi': 'thêm lần cuối',
    'az': 'Ən Son Əlavə Edilən',
    'ro': 'Ultima adăugată',
    'ka': 'ბოლოს დამატებულია',
    'ru': 'Последнее добавление',
    'af': 'Laas bygevoeg',
    'bn-bd': 'সর্বশেষ যোগ করা হয়েছে',
    'sw-ke': 'Iliyoongezwa Mwisho',
    'id': 'Terakhir Ditambahkan',
    'ur-pk': 'آخری بار شامل کیا گیا',
    'ms-my': 'Terakhir Ditambah',
    'th': 'เพิ่มครั้งล่าสุด',
    'tl': 'Huling Nadagdag',
    'hi': 'अंतिम बार जोड़ा गया',
    'mr': 'शेवटचे जोडले',
    'ta-in': 'கடைசியாக சேர்க்கப்பட்டது',
    'ne': 'पछिल्लो पटक थपियो',
};

export const SORT_OPTIONS_MOST_VIEWED: SortOptionLocale = {
    'en': 'Most Viewed',
    'nl': 'Meest bekeken',
    'de': 'Am häufigsten gesehen',
    'it': 'I più visti',
    'ja': '最も見られました',
    'fr': 'Le plus regardé',
    'ko': '많이 본',
    'tr-tr': 'En çok görüntülenen',
    'ar-ae': 'الأكثر مشاهدة',
    'es-419': 'Mas visto',
    'pt-br': 'Mais visto',
    'zh-hans': '最受关注',
    'pl': 'Najczęściej oglądane',
    'zu': 'Ebonwe Kakhulu',
    'uk': 'З найбільшою кількістю переглядів',
    'vi': 'Xem nhiều nhất',
    'az': 'Ən Çox Baxılan',
    'ro': 'Cele mai văzute',
    'ka': 'ყველაზე ნახული',
    'ru': 'Наиболее просматриваемые',
    'af': 'Mees gekyk',
    'bn-bd': 'সর্বাধিক দেখা',
    'sw-ke': 'Iliyotazamwa Zaidi',
    'id': 'Paling Banyak Dilihat',
    'ur-pk': 'سب سے زیادہ دیکھے گئے',
    'ms-my': 'Paling Banyak Dilihat',
    'th': 'มีผู้ชมมากที่สุด',
    'tl': 'Pinaka Nanood',
    'hi': 'सबसे ज्यादा देखा गया',
    'mr': 'सर्वाधिक पाहिलेले',
    'ta-in': 'அதிகம் பார்க்கப்பட்டது',
    'ne': 'सबैभन्दा धेरै हेरिएको',
};

export const SORT_OPTIONS_MOST_FAVORITED: SortOptionLocale = {
    'en': 'Most Favorited',
    'nl': 'Meest favoriet',
    'de': 'Am beliebtesten',
    'it': 'I più preferiti',
    'ja': '最もお気に入り',
    'fr': 'Les plus favoris',
    'ko': '가장 좋아하는',
    'tr-tr': 'En Çok Beğenilen',
    'ar-ae': 'الأكثر تفضيلاً',
    'es-419': 'Más favorecido',
    'pt-br': 'Mais favorito',
    'zh-hans': '最喜欢的',
    'pl': 'Najbardziej ulubione',
    'zu': 'Athandwe Kakhulu',
    'uk': 'З найбільшою кількістю додавань у обране',
    'vi': 'yêu thích nhất',
    'az': 'Ən Çox Bəyənilən',
    'ro': 'Cele mai preferate',
    'ka': 'ყველაზე რჩეული',
    'ru': 'Самые любимые',
    'af': 'Mees Gunsteling',
    'bn-bd': 'সবচেয়ে প্রিয়',
    'sw-ke': 'Inayopendwa Zaidi',
    'id': 'Paling Favorit',
    'ur-pk': 'سب سے زیادہ پسندیدہ',
    'ms-my': 'Paling Digemari',
    'th': 'ที่ชื่นชอบมากที่สุด',
    'tl': 'Pinaka paborito',
    'hi': 'सर्वाधिक पसंदीदा',
    'mr': 'सर्वाधिक आवडते',
    'ta-in': 'மிகவும் பிடித்தது',
    'ne': 'सबैभन्दा मनपर्ने',
};

export const SORT_OPTIONS_MOST_REMIXED: SortOptionLocale = {
    'en': 'Most Remixed',
    'nl': 'Meest geremixed',
    'de': 'Am meisten remixt',
    'it': 'La maggior parte dei remix',
    'ja': 'ほとんどのリミックス',
    'fr': 'Le plus remixé',
    'ko': '대부분의 리믹스',
    'tr-tr': 'En Çok Yeniden Düzenlenen',
    'ar-ae': 'الأكثر ريمكسًا',
    'es-419': 'Más remezclado',
    'pt-br': 'Mais Remixado',
    'zh-hans': '最多混音',
    'pl': 'Najczęściej remiksowane',
    'zu': 'Exutshwe Kaningi',
    'uk': 'З найбільшою кількістю реміксів',
    'vi': 'Được phối lại nhiều nhất',
    'az': 'Ən Çox Remiks Edilən',
    'ro': 'Cele mai remixate',
    'ka': 'ყველაზე რემიქსი',
    'ru': 'Самый ремикс',
    'af': 'Mees Remixed',
    'bn-bd': 'সর্বাধিক রিমিক্সড',
    'sw-ke': 'Iliyochanganywa Zaidi',
    'id': 'Kebanyakan Remix',
    'ur-pk': 'سب سے زیادہ ریمکس شدہ',
    'ms-my': 'Paling banyak di-remix',
    'th': 'รีมิกซ์มากที่สุด',
    'tl': 'Pinaka-Remix',
    'hi': 'सबसे ज्यादा रीमिक्स किया गया',
    'mr': 'सर्वाधिक रीमिक्स केलेले',
    'ta-in': 'மிகவும் ரீமிக்ஸ்',
    'ne': 'सबैभन्दा धेरै रिमिक्स गरिएको',
};

export const SORT_OPTIONS_MOST_RECENT: SortOptionLocale = {
    'en': 'Most Recent',
    'nl': 'Meest recente',
    'de': 'Neueste',
    'it': 'Piu recente',
    'ja': '最も最近の',
    'fr': 'Le plus récent',
    'ko': '가장 최근',
    'tr-tr': 'En Yeniler',
    'ar-ae': 'الأحدث',
    'es-419': 'Más reciente',
    'pt-br': 'Mais recente',
    'zh-hans': '最近的',
    'pl': 'Najnowszy',
    'zu': 'Eyakamuva',
    'uk': 'Найновіші',
    'vi': 'Gần đây nhất',
    'az': 'Ən Sonuncu',
    'ro': 'Cel mai recent',
    'ka': 'Უახლესი',
    'ru': 'Самые последние',
    'af': 'Mees onlangse',
    'bn-bd': 'অতি সাম্প্রতিক',
    'sw-ke': 'Hivi Karibuni',
    'id': 'Terbaru',
    'ur-pk': 'تازہ ترین',
    'ms-my': 'Terkini',
    'th': 'ล่าสุด',
    'tl': 'Pinakabago',
    'hi': 'सबसे हाल का',
    'mr': 'सर्वात अलीकडील',
    'ta-in': 'மிக சமீபத்தியது',
    'ne': 'सबैभन्दा हालको',
};

export const SORT_OPTIONS_MOST_LIKED: SortOptionLocale = {
    'en': 'Most Liked',
    'nl': 'Meest leuk gevonden',
    'de': 'Am meisten gemocht',
    'it': 'I più apprezzati',
    'ja': '最も好きな',
    'fr': 'Le plus aimé',
    'ko': '가장 좋아하는',
    'tr-tr': 'En çok sevilen',
    'ar-ae': 'الأكثر إعجابا',
    'es-419': 'El mas gustado',
    'pt-br': 'O mais adorado',
    'zh-hans': '最喜欢',
    'pl': 'Najbardziej lubiane',
    'zu': 'Ethandwe Kakhulu',
    'uk': 'З найбільшою кількістю вподобань',
    'vi': 'Thích nhất',
    'az': 'Ən Çox Bəyənilən',
    'ro': 'Cele mai apreciate',
    'ka': 'ყველაზე მოწონებული',
    'ru': 'Самые популярные',
    'af': 'Die meeste gelaaik',
    'bn-bd': 'সবচেয়ে পছন্দ হয়েছে',
    'sw-ke': 'Zilizopendwa Zaidi',
    'id': 'Paling Disukai',
    'ur-pk': 'سب سے زیادہ پسند کیا گیا',
    'ms-my': 'Paling Disukai',
    'th': 'ชอบมากที่สุด',
    'tl': 'Pinaka Nagustuhan',
    'hi': 'सर्वाधिक पसंद किया गया',
    'mr': 'सर्वाधिक आवडले',
    'ta-in': 'மிகவும் விரும்பப்பட்டது',
    'ne': 'सबैभन्दा धेरै मन पर्यो',
};

export const SORT_OPTIONS_RECENTLY_JOINED: SortOptionLocale = {
    'en': 'Recently Joined',
    'nl': 'Onlangs toegetreden',
    'de': 'Vor kurzem beigetreten',
    'it': 'Iscritto di recente',
    'ja': '最近参加した',
    'fr': 'A récemment rejoint',
    'ko': '최근 참여한',
    'tr-tr': 'Yakın zamanda katıldı',
    'ar-ae': 'انضم مؤخرا',
    'es-419': 'Recientemente Unidos',
    'pt-br': 'Ingressou recentemente',
    'zh-hans': '近期加入',
    'pl': 'Niedawno dołączył',
    'zu': 'Usanda Kujoyina',
    'uk': 'Нещодавно приєдналися',
    'vi': 'Đã tham gia gần đây',
    'az': 'Ən Son Qoşulan',
    'ro': 'S-a alăturat recent',
    'ka': 'ახლახან შეუერთდა',
    'ru': 'Недавно присоединился',
    'af': 'Onlangs aangesluit',
    'bn-bd': 'সম্প্রতি যোগদান করেছেন',
    'sw-ke': 'Aliyejiunga Hivi Karibuni',
    'id': 'Baru-baru ini Bergabung',
    'ur-pk': 'حال ہی میں شامل ہوئے',
    'ms-my': 'Baru-baru Menyertai',
    'th': 'เพิ่งเข้าร่วม',
    'tl': 'Kamakailang Sumali',
    'hi': 'हाल ही में शामिल हुए',
    'mr': 'नुकतेच सामील झाले',
    'ta-in': 'சமீபத்தில் சேர்ந்தார்',
    'ne': 'भर्खरै सामेल हुनुभयो',
};
