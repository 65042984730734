import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { intl } from 'i18n';
import Select, { ValueType } from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';

import { sanitizeConfigOptions } from '@labxchange/util-sanitization';
import { isKeyboardEnterEvent } from 'global/utils';
import {
  Icon, Spinner, StandardPageLayout, showErrorMessage,
  PillButtonFilters, ALL_FILTER
} from 'ui/components';
import { RichText, CollapsibleWithChevron } from 'elements';
import {
  Module,
  ModuleSlab,
  ModuleSlabDisplayTypeEnum,
  ModuleHierarchy,
  CurriculumModules,
  ItemMetadata,
} from 'labxchange-client';
import { CurriculumsApi, ModulesApi } from 'global/api';
import PathwaysSlab from 'subjects/components/page/PathwaysSlab';
import { getCurriculumGroup } from 'auth/selectors';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { ROUTES } from 'global/constants';
import { detailUrlForEntity } from 'library/utils';

import { createSubjectGradeOptions, renderModules, searchModules, expandActiveModulePath } from '../../utils';
import { SelectDropdownOption } from '../../types';
import { ModuleSearchBar } from '../ModuleSearchBar';
import { GenericSlab, CloselyRelatedSlab } from './ModuleSlabs';
import messages from '../../displayMessages';

const SIDEBAR_BREAKPOINT = 1310;

const SLAB_TITLE_SUBHEADING_MAP = {
  'Simulations & interactive animations': messages.interactivesSlabSubheading,
  'Videos & annotated videos': messages.videosSlabSubheading,
  'Case studies': messages.caseStudiesSlabSubheading,
  'Readings': messages.readingsSlabSubheading,
  'Complete lessons': messages.completeLessonsSlabSubheading,
  'Learning pathways': messages.pathwaysSlabSubheading,
  'Questions': messages.questionsSlabSubheading,
  'Images': messages.imagesSlabSubheading,
};

export const CurriculumModulePage = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const moduleSlug = pathSegments[pathSegments.length - 1];
  const version = new URLSearchParams(window.location.search).get('version') || undefined;
  const lastScrollTopRef = useRef(0);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= SIDEBAR_BREAKPOINT);

  const maxAssetsPerSlab = Number(new URLSearchParams(window.location.search).get('max_assets_per_slab')) || undefined;
  const [selectedSubject, setSelectedSubject] = React.useState<string>(subject || '');
  const [selectedGrade, setSelectedGrade] = React.useState<string>(grade || '');
  const [moduleData, setModuleData] = useState<Module | null>(null);
  const [curriculumData, setCurriculumData] = useState<CurriculumModules | null>(null);
  const [loadingModuleData, setLoadingModuleData] = useState<boolean>(false);
  const [loadingCurriculumData, setLoadingCurriculumData] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [filteredModules, setFilteredModules] = useState<ModuleHierarchy[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([ALL_FILTER]);
  const [showFixedSidebar, setShowFixedSidebar] = useState(false);
  const [isSidebarAtTop, setIsSidebarAtTop] = useState(false);
  const userCurriculumGroup = useSelector(getCurriculumGroup);
  const canUserGiveFeedback = userCurriculumGroup === curriculum;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > lastScrollTopRef.current) {
        setIsSidebarAtTop(true);
      } else {
        setIsSidebarAtTop(false);
      }
      lastScrollTopRef.current = scrollTop;
    };

    const throttledScroll = () => requestAnimationFrame(handleScroll);

    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= SIDEBAR_BREAKPOINT);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchCurriculumData = async () => {
      setLoadingCurriculumData(true);
      try {
        const curriculumResponse: CurriculumModules = await CurriculumsApi.read(
          {
            id: curriculum,
            subject: selectedSubject,
            grade: selectedGrade,
          }
        );
        setCurriculumData(curriculumResponse);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.curriculumModulePageCurriculumError));
      }
      setLoadingCurriculumData(false);
    };

    fetchCurriculumData();
  }, [selectedSubject, selectedGrade, curriculum]);

  useEffect(() => {
    const fetchModuleData = async () => {
      setLoadingModuleData(true);
      try {
        const moduleResponse: Module = await ModulesApi.read({
          id: moduleSlug,
          curriculum,
          subject,
          grade,
          version,
          maxAssetsPerSlab,
        });
        setModuleData(moduleResponse);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.curriculumModulePageModuleError));
      }
      setLoadingModuleData(false);
    };

    fetchModuleData();
  } , [curriculum, grade, maxAssetsPerSlab, moduleSlug, subject, version]);

  useEffect(() => {
    if (!curriculumData?.modules) return;

    if (!searchKeyword) {
      const expandedModules = expandActiveModulePath(curriculumData.modules, moduleSlug);
      setFilteredModules([...expandedModules]);
    } else {
      const filteredResults: any = searchModules(curriculumData.modules, searchKeyword);
      setFilteredModules([...filteredResults]);
    }
  }, [searchKeyword, curriculumData, moduleSlug]);

  const renderSpinner = () => (
    <div
      data-testid='spinner-wrapper'
      className='spinner-wrapper'
    >
        <Spinner />
    </div>
  );

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);

      if (curriculumData && curriculumData.subjects) {
        const availableGradeValue = curriculumData.subjects.find((s) => s.slug === selectedSubjectValue)?.grades?.[0].slug;
        setSelectedGrade(availableGradeValue || '');
        setSearchKeyword('');
      }
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
      setSearchKeyword('');
    }
  };

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions
  } = createSubjectGradeOptions(curriculumData?.subjects || [], selectedSubject);
  const subjectDefaultValue = subjectOptions.find(option => option.value === selectedSubject);
  const gradeDefaultValue = gradeOptions.find(option => option.value === selectedGrade);

  const openSidebar = () => {
    document.body.style.overflow = 'hidden';
    setShowFixedSidebar(true);
  };

  const closeSidebar = () => {
    document.body.style.overflow = 'auto';
    setShowFixedSidebar(false);
  };

  const onClickSidebarModule = (module: Module) => {
    analyticsInstance.track(EVENT_NAMES.CurriculumModulePageSideBarSubModuleClicked, {
      module_id: module.id,
      module_slug: module.slug,
      module_name: module.name,
      module_url: ROUTES.Curriculum.MODULE_SLUG(curriculum, selectedSubject, selectedGrade, module.slug),
      search_keyword: searchKeyword,
      selected_subject: selectedSubject,
      selected_grade: selectedGrade,
      curriculum_slug: curriculum,
      url: window.location.toString()
    });
    closeSidebar();
  };

  const renderSidebar = () => {
    if (!curriculumData) return;
    return (
      <div
        className={classNames('curriculum-modules-sidebar',
          {
            'tablet-fixed': showFixedSidebar,
          }
        )}
        style={{ top: isSidebarAtTop ? '0' : '64px', marginTop: isSidebarAtTop ? '0px' : '4px' }}
      >
        <div className='sidebar-content'>
          <div className='curriculum-info'>
            <div className='curriculum-country-flag'>
              <ReactCountryFlag
                countryCode={(curriculumData.country as any).code}
                svg
                style={{
                  width: '34px',
                  height: '24px'
                }}
              />
            </div>
            <div className='curriculum-title-subheading'>
              <span className='curriculum-title'>{curriculumData.slug.toUpperCase()}</span>
              <span className='curriculum-subheading'>{(curriculumData.country as any).name}</span>
            </div>
            {showFixedSidebar &&
              <div className='close-sidebar-btn'>
                <button
                  tabIndex={0}
                  className='close-button'
                  aria-label='Close sidebar'
                  onClick={closeSidebar}
                  data-testid='close-sidebar-btn'
                >
                  <Icon name='x' zoom='20' />
                </button>
              </div>
            }
          </div>
          <div className='curriculum-dropdowns-search'>
            <div className='module-search-bar-wrapper'>
              <ModuleSearchBar
                value={searchKeyword}
                onChange={(value: string) => setSearchKeyword(value)}
                placeholder={messages.curriculumDetailPageSearchPlaceholder}
              />
            </div>
            <div className='curriculum-dropdowns'>
              <Select
                tabIndex='0'
                className='select-dropdown'
                classNamePrefix='select'
                options={subjectOptions}
                onChange={onSubjectChange}
                value={subjectDefaultValue}
                isSearchable={false}
              />
              <Select
                tabIndex='0'
                className='select-dropdown'
                classNamePrefix='select'
                options={gradeOptions}
                onChange={onGradeChange}
                value={gradeDefaultValue}
                isSearchable={false}
              />
            </div>
          </div>
          <div className='modules-list'>
           {loadingCurriculumData ? renderSpinner() :
              renderModules(
                filteredModules, curriculum, selectedSubject,
                selectedGrade, 1, moduleSlug, onClickSidebarModule
              )
            }
          </div>
        </div>
        {showFixedSidebar &&
          <div
            role='button'
            aria-label='Close sidebar'
            tabIndex={0}
            className='sidebar-backdrop'
            onClick={closeSidebar}
            onKeyDown={(e) => isKeyboardEnterEvent(e) && closeSidebar()}
            data-testid='close-sidebar-backdrop'
          />
        }
      </div>
    );
  };

  const renderModuleSlabs = (slabs: ModuleSlab[]) => {
    if (!moduleData || !curriculumData) return null;

    // Remove slabs having no items
    let filteredSlabs = slabs.filter(slab => slab.items.length > 0);
    // Filter slabs on basis of selected filters
    filteredSlabs = selectedFilters.includes(ALL_FILTER)
      ? slabs
      : slabs.filter((slab) => selectedFilters.includes(slab.title));

    const slabsContent = filteredSlabs.map((slab) => {
      if (slab.items.length === 0) return null;

      if (slab.displayType === ModuleSlabDisplayTypeEnum.Interactives || slab.displayType === ModuleSlabDisplayTypeEnum.SimpleCards) {
        return (
          <React.Fragment key={slab.title}>
            <GenericSlab
              slab={slab}
              curriculumSlug={curriculumData.slug}
              module={moduleData}
              subHeading={intl.formatMessage((SLAB_TITLE_SUBHEADING_MAP as any)[slab.title])}
              showFeedbackButton={canUserGiveFeedback}
              selectedFilters={selectedFilters}
            />
            <div className='slab-horizontal-divider' />
          </React.Fragment>
        );
      } else if (slab.displayType === ModuleSlabDisplayTypeEnum.Pathways) {
        return (
          <React.Fragment key={slab.title}>
            <PathwaysSlab
              heading={slab.title}
              slab={slab}
              showExploreButton={true}
              subHeading={intl.formatMessage((SLAB_TITLE_SUBHEADING_MAP as any)[slab.title])}
              onClickExploreButton={(pathway: ItemMetadata) => {
                analyticsInstance.track(EVENT_NAMES.CurriculumModulePageAssetCardClicked, {
                  asset_id: pathway.id,
                  asset_title: pathway.title,
                  asset_type: pathway.type,
                  module_id: moduleData.id,
                  module_slug: moduleData.slug,
                  active_version: moduleData.activeVersion,
                  curriculum_slug: curriculumData.slug,
                  selected_filters: selectedFilters,
                  url: window.location.toString(),
                });
                window.open(detailUrlForEntity(pathway), '_blank');
              }}
            />
            <div className='slab-horizontal-divider' />
          </React.Fragment>
        );
      }

      return null;
    });

    return (
      <div className='module-slabs'>
        {slabsContent}
        {moduleData.siblings.length > 0 && <CloselyRelatedSlab modules={moduleData.siblings} />}
      </div>
    );
  };

  const renderModuleContent = () => {
    if (!moduleData) return;
    return (
      <div className='module-content'>
        <div className='title-core-concept'>
          {isSmallScreen && (
            <button
              tabIndex={0}
              className='curriculum-sidebar-button'
              aria-label='Open sidebar'
              onClick={openSidebar}
              onKeyDown={(e) => isKeyboardEnterEvent(e) && openSidebar()}
            >
              <span>{intl.formatMessage(messages.curriculumSidebarToggle)}</span>
              <Icon name='double-arrow-right' zoom='20' />
            </button>
          )}
          <h1 className='font-m-lt'>{moduleData.name}</h1>
          <CollapsibleWithChevron
            key={moduleData.name}
            title={moduleData.learningObjectivesHeading}
            defaultOpen={false}
            onToggleCallback={(expanded: boolean) => analyticsInstance.track(EVENT_NAMES.CurriculumModulePageCollapsibleClicked, {
              total_learning_objectives: moduleData.learningObjectives.length,
              url: window.location.toString(),
              expanded,
            })}
          >
            {moduleData.learningObjectives.length > 1 ?
              <ol>{moduleData.learningObjectives.map((lo, index) =>
                <li key={index}>
                  <RichText
                      innerHtml={lo}
                    sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                  />
                </li>
              )}
              </ol>
              :
              <RichText
                  innerHtml={moduleData.learningObjectives[0]}
                sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
              />
            }
          </CollapsibleWithChevron>
        </div>
        <PillButtonFilters
          filters={moduleData.moduleSlabs.filter(slab => slab.items.length > 0).map((slab) => slab.title)}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onFilterClick={(filter, updatedFilters) => analyticsInstance.track(EVENT_NAMES.CurriculumModulePageContentTypeFilterClicked, {
            filter_text: filter,
            selected_filters: updatedFilters,
            url: window.location.toString(),
          })}
        />
        {renderModuleSlabs(moduleData.moduleSlabs)}
      </div>
    );
  };

  return (
    <StandardPageLayout
      backgroundStyle='light-header'
      mainClassName='curriculum-module-page'
      pageTitle={messages.curriculumPageTitle}
      pageTitleValues={{ name: moduleData?.name || '' }}
      footerClassName={!loadingCurriculumData ? 'curriculum-module-page-footer' : ''}
    >
      {renderSidebar()}
      {loadingModuleData ? renderSpinner() : renderModuleContent()}
    </StandardPageLayout>
  );
};
