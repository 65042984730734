import * as React from 'react';
import { intl } from 'i18n';
import { MessageDescriptor } from 'react-intl';

import { Icon } from 'ui/components';

import messages from '../../displayMessages';

interface ModuleSearchBarProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: MessageDescriptor;
}

export const ModuleSearchBar = ({value, onChange, placeholder}: ModuleSearchBarProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <div className='module-search-bar'>
            <div className='icon-wrapper' data-testid='search-icon'>
                <Icon name='search' />
            </div>
            <input
                type='text'
                className='search-input'
                placeholder={intl.formatMessage(placeholder || messages.searchBarPlainDefaultPlaceholder)}
                aria-label={intl.formatMessage(placeholder || messages.searchBarPlainDefaultPlaceholder)}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};
