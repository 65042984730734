import React, { memo } from 'react';

import { ModuleSlab, SectionSlab, ItemMetadata } from 'labxchange-client';
import { PathwaysCarousel } from 'home/components/TeachPage';

import { convertSlabToFeaturedItems } from './utils';

interface PathwaysSlabProps {
  heading: string;
  slab: SectionSlab | ModuleSlab;
  showExploreButton?: boolean;
  subHeading?: string;
  onClickExploreButton?: (pathway: ItemMetadata) => void;
}

export const PathwaysSlab: React.FC<PathwaysSlabProps> = (props) => {
  return (
    <div className='pathways-container section pathway-section scroll-section'>
      <h1 className='slab-title'>{props.heading}</h1>
      {props.subHeading && <span className='slab-subheading'>{props.subHeading}</span>}
      <PathwaysCarousel
        pathways={convertSlabToFeaturedItems(props.slab)}
        showExploreButton={props.showExploreButton}
        onClickExploreButton={props.onClickExploreButton}
      />
    </div>
  );
};

export default memo(PathwaysSlab);
