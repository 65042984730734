import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { intl } from 'i18n';
import Select, { ValueType } from 'react-select';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { StandardPageLayout, showErrorMessage, Spinner } from 'ui/components';
import { WrappedMessage } from 'utils';
import { CurriculumsApi } from 'global/api';
import { CurriculumDetail, CurriculumModules, ModuleHierarchy } from 'labxchange-client';
import { ROUTES } from 'global/constants';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

import messages from '../../displayMessages';
import { createSubjectGradeOptions, searchModules } from '../../utils';
import { SelectDropdownOption } from '../../types';
import { ModuleSearchBar } from '../ModuleSearchBar';

interface ExtendedModuleHierarchy extends ModuleHierarchy {
  index: number;
  sub_modules?: ModuleHierarchy[];
}

export const CurriculumDetailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pathSegments = location.pathname.split('/');
  const curriculumSlug = pathSegments[pathSegments.length - 1];

  const [loading, setLoading] = useState<boolean>(false);
  const [modulesLoading, setModulesLoading] = useState<boolean>(false);
  const [curriculum, setCurriculum] = useState<CurriculumDetail>();
  const [selectedSubject, setSelectedSubject] = useState<string>(queryParams.get('subject') || '');
  const [selectedGrade, setSelectedGrade] = useState<string>(queryParams.get('grade') || '');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [curriculumModules, setCurriculumModules] = useState<ExtendedModuleHierarchy[]>([]);
  const [filteredModules, setFilteredModules] = useState<ExtendedModuleHierarchy[]>([]);

  useEffect(() => {
    const filteredResults: any = searchModules(curriculumModules, searchKeyword);
    setFilteredModules(filteredResults);
  }, [searchKeyword, curriculumModules]);

  useEffect(() => {
    const fetchCurriculumData = async () => {
      setLoading(true);
      try {
        const response: CurriculumDetail = await CurriculumsApi.getDetail({ id: curriculumSlug });
        const firstSubject = response.subjects?.[0];
        setCurriculum(response);

        // Set initial values only if not provided via query parameters
        if (!queryParams.get('subject')) {
          setSelectedSubject(firstSubject.slug || '');
        }
        if (!queryParams.get('grade')) {
          setSelectedGrade(firstSubject.grades?.[0].slug || '');
        }
      } catch (error) {
        setLoading(false);
        setCurriculum(undefined);
        showErrorMessage(
          <WrappedMessage message={messages.curriculumDetailPageErrorCurriculum} />
        );
      }
      setLoading(false);
    };

    fetchCurriculumData();
  }, [curriculumSlug]);

  useEffect(() => {
    const fetchDataWithModules = async () => {
      if (!curriculum || !selectedSubject || !selectedGrade) return;
      setModulesLoading(true);
      try {
        const response: CurriculumModules = await CurriculumsApi.read({
          id: curriculum.slug,
          subject: selectedSubject,
          grade: selectedGrade,
        });

        if (response?.modules) {
          const modules: ExtendedModuleHierarchy[] = response.modules.map((curriculumModule, index) => ({
            ...curriculumModule,
            index: index + 1,
          }));
          setCurriculumModules(modules);
        } else {
          setCurriculumModules([]);
        }

      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.curriculumDetailPageErrorModules));
      }
      setModulesLoading(false);
    };

    fetchDataWithModules();
  }, [curriculum, selectedGrade, selectedSubject]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedSubject) params.set('subject', selectedSubject);
    if (selectedGrade) params.set('grade', selectedGrade);

    history.replace({ search: params.toString() });
  }, [selectedSubject, selectedGrade, history]);

  const renderSpinner = () => {
    return (
      <div data-testid='spinner-wrapper' className='spinner-wrapper'>
        <Spinner />
      </div>
    );
  };

  const renderStandardPageLayout = (children: React.ReactNode) => {
    return (
      <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='curriculum-detail-page'
        pageTitle={messages.curriculumPageTitle}
        pageTitleValues={{ name: curriculum?.name || '' }}
      >
        {children}
      </StandardPageLayout>
    );
  };

  if (loading || !curriculum) {
    return renderStandardPageLayout(renderSpinner());
  }

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);

      const availableGradeValue = curriculum.subjects.find((s) => s.slug === selectedSubjectValue)?.grades?.[0].slug;
      setSelectedGrade(availableGradeValue as string);
      setSearchKeyword('');
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
      setSearchKeyword('');
    }
  };

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions
  } = createSubjectGradeOptions(curriculum.subjects, selectedSubject);
  const subjectDefaultValue = subjectOptions.find(option => option.value === selectedSubject);
  const gradeDefaultValue = gradeOptions.find(option => option.value === selectedGrade);

  const renderHeroSection = () => {
    return (
      <div className='curriculum-hero-section'>
        <div className='curriculum-info'>
          <div className='curriculum-country-flag'>
            <ReactCountryFlag
              countryCode={(curriculum.country as any).code}
              svg
              style={{
                width: '39px',
                height: '27px'
              }}
            />
          </div>
          <div className='curriculum-title-headline'>
            <h1 className='curriculum-title font-l-lt'>{`${curriculum.slug.toUpperCase()} (${(curriculum.country as any).name})`}</h1>
            <span className='curriculum-headline'>{intl.formatMessage(messages.curriculumDetailPageHeadline)}</span>
          </div>
        </div>
        <div className='curriculum-dropdowns-search'>
          <div className='curriculum-dropdowns'>
            <Select
              placeholder={intl.formatMessage(messages.curriculumDetailSubjectDropdownPlaceholder)}
              aria-label={intl.formatMessage(messages.curriculumDetailSubjectDropdownAria)}
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              options={subjectOptions}
              onChange={onSubjectChange}
              value={subjectDefaultValue}
              isSearchable={false}
            />
            <Select
              placeholder={intl.formatMessage(messages.curriculumDetailGradeDropdownPlaceholder)}
              aria-label={intl.formatMessage(messages.curriculumDetailGradeDropdownAria)}
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              options={gradeOptions}
              onChange={onGradeChange}
              value={gradeDefaultValue}
              isSearchable={false}
            />
          </div>
          <div className='topic-search-bar'>
            <ModuleSearchBar
              value={searchKeyword}
              onChange={(value: string) => setSearchKeyword(value)}
              placeholder={messages.curriculumDetailPageSearchPlaceholder}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSubModulesList = (subModules: any, level = 1) => {
    if (!subModules || !subModules.length) return;
    return (
      <div className={classNames({'sub-modules-list': level === 1, 'sub-sub-modules-list': level > 1})}>
        {subModules.map((subModule: any) => {
          const subSubModules = subModule.subModules || subModule.sub_modules;
          return (
            <React.Fragment key={subModule.id}>
              <span className={classNames({
                  'font-xs-lt': level === 1,
                  'sub-module-name': level === 1,
                  'sub-sub-module-name': level > 1
                })}
              >
                {level === 1  ? subModule.name :
                  <Link
                    to={ROUTES.Curriculum.MODULE_SLUG(curriculum.slug, selectedSubject, selectedGrade, subModule.slug)}
                    onClick={() => analyticsInstance.track(EVENT_NAMES.CurriculumDetailPageSubModuleClicked, {
                      module_id: subModule.id,
                      module_slug: subModule.slug,
                      module_name: subModule.name,
                      module_url: ROUTES.Curriculum.MODULE_SLUG(curriculum.slug, selectedSubject, selectedGrade, subModule.slug),
                      search_keyword: searchKeyword,
                      selected_subject: selectedSubject,
                      selected_grade: selectedGrade,
                      curriculum_slug: curriculum.slug,
                      url: window.location.toString()
                    })}
                  >
                    {subModule.name}
                  </Link>
                }
              </span>
              {subSubModules.length > 0 && renderSubModulesList(subModule.subModules || subModule.sub_modules, level + 1)}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderModuleCard = (curriculumModule: ExtendedModuleHierarchy) => {
    return (
      <div key={curriculumModule.id} className='curriculum-module-card'>
        <div className='left-section'>
          <span className='module-number'>{`Module ${curriculumModule.index}`}</span>
          <span className='module-name font-s-lt'>{curriculumModule.name}</span>
        </div>
        <div className='right-section'>
          {renderSubModulesList(curriculumModule.subModules || curriculumModule.sub_modules)}
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    const contentSection = (children: React.ReactNode) => {
      return (
        <div className='curriculum-content-section'>
          {children}
        </div>
      );
    };

    if (modulesLoading) {
      return contentSection(renderSpinner());
    }

    if (!selectedSubject || !selectedGrade) {
      return contentSection(
        <span>{intl.formatMessage(messages.curriculumDetailPageSelectSubjectGrade)}</span>
      );
    }

    if (!filteredModules.length) {
      return contentSection(
        <span className='no-modules-message'>
          {intl.formatMessage(
            searchKeyword ? messages.curriculumDetailNoSearchResults :
            messages.curriculumDetailPageNoModules
          )}
        </span>
      );
    }

    return contentSection(
      filteredModules.map((curriculumModule) => renderModuleCard(curriculumModule))
    );
  };

  const renderCurriculumDetails = () => {
    if (!curriculum) return;
    return (
      <div>
        {renderHeroSection()}
        {renderContentSection()}
      </div>
    );
  };

  return renderStandardPageLayout(renderCurriculumDetails());
};
