import React, { ReactNode } from 'react';
import './styles.scss';

interface InputSelectProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  label?: ReactNode | string,
  className?: string,
}

export function InputSelect({ name, value, label, className, onChange, options }: InputSelectProps) {
  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  }
  return <>
    <label htmlFor={name}>{label}</label>
    <select name={name} value={value} onChange={onSelect} className={`${className} input-select`} style={{ appearance: 'none' }}>
      {options.map((option) => (
        <option key={option.value} value={option.value} className='test'>
          {option.label}
        </option>
      ))}
    </select>
  </>
}
