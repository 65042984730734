import { defineMessages } from 'react-intl';

const messages = defineMessages({
    interactiveCardsHeading: {
        id:'interactiveCardsHeading',
        defaultMessage: 'Engage students with interactivity!',
        description: 'Heading for interactive cards.',
    },
    subjectFilterInteractiveHeading: {
        id:'subjectFilterInteractiveHeading',
        defaultMessage: 'Recommended',
        description: 'Heading for interactive cards for subject tabs.',
    },
    moreTopicsText: {
        id:'moreTopicsText',
        defaultMessage: 'More {subject} topics',
        description: 'More topics button text.',
    },
    seeMoreButton: {
        id:'seeMoreButton',
        defaultMessage: 'See More Resources',
        description: 'More topics button text for race and science section.',
    },
    raceAndScienceSectionHeading: {
        id:'raceAndScienceSectionHeading',
        defaultMessage: 'Race & Science',
        description: 'Heading text for race and science section',
    },
    pathwayCardsHeading: {
        id:'pathwayCardsHeading',
        defaultMessage: 'Customize existing lessons',
        description: 'Heading for pathway cards.',
    },
    pathwayCardsDescription: {
        id:'pathwayCardsDescription',
        defaultMessage: 'Remix and customize pre-made learning pathways to meet your students\' needs. Or create your own lessons with a ',
        description: 'Description for pathway cards.',
    },
    pathwayCardsCovers: {
        id:'pathwayCardsCovers',
        defaultMessage: 'This pathway covers',
        description: 'Description for pathway cards.',
    },
    createAccountText: {
        id:'createAccountText',
        defaultMessage: 'free educator account.',
        description: 'text for a link to create free educator account.',
    },
    morePathwaysText: {
        id:'morePathwaysText',
        defaultMessage: 'More pathways',
        description: 'Heading for pathway cards.',
    },
    quickLinksHeading: {
        id:'quickLinksHeading',
        defaultMessage: 'Quick links',
        description: 'Heading for quick links.',
    },
    stemSourcesHeading: {
        id:'stemSourcesHeading',
        defaultMessage: 'The world\'s best STEM sources, all in one place',
        description: 'Heading for stem sources.',
    },
    stemSourcesDescription: {
        id:'stemSourcesDescription',
        defaultMessage: 'LabXchange convenes a community that believes in the transformative power of education. We offer more than 28,500 free educational resources from over 150 leading organizations.',
        description: 'Description for stem sources.',
    },
    learnerStoriesHeading: {
        id:'learnerStoriesHeading',
        defaultMessage: 'LabXchange works for learners',
        description: 'Heading for learner success stories.',
    },
    searchContainerHeading: {
        id:'searchContainerHeading',
        defaultMessage: 'Find something specific',
        description: 'Heading for search container.',
    },
    popularQuriesTryText: {
        id:'popularQuriesTryText',
        defaultMessage: 'Try',
        description: 'Text for try popular queries.',
    },
    allEducatorResources: {
        id:'allEducatorResources',
        defaultMessage: 'View all educator resources',
        description: 'Text for all educator rescources button.',
    },
    textViewAllSources: {
        id:'textViewAllSources',
        defaultMessage: 'View all sources',
        description: 'Text for view all sources button.',
    },
    sourceImageAltText: {
        id:'sourceImageAltText',
        defaultMessage: 'Image contains logs of sources',
        description: 'Alt text for sources image.',
    },
    launchButtonText: {
        id:'launchButtonText',
        defaultMessage: 'Launch',
        description: 'Alt text for sources image.',
    },
    overviewButtonText: {
        id:'overviewButtonText',
        defaultMessage: 'Overview',
        description: 'Overview button text.',
    },
    pathwayEducatorCloneEditButton: {
        id:'pathwayEducatorCloneEditButton',
        defaultMessage: 'Clone & edit',
        description: 'Clone and start editing a pathway',
    },
    pathwayEducatorViewButton: {
        id:'pathwayEducatorViewButton',
        defaultMessage: 'View pathway',
        description: 'Proceed to view the pathway page',
    },
    pathwayCardExploreButton: {
        id: 'pathwayCardExploreButton',
        defaultMessage: 'Explore pathway',
        description: 'Explore button for pathway card',
    },
    pathwayEducatorCloneFailed: {
        id:'pathwayEducatorCloneFailed',
        defaultMessage: 'Failed to clone pathway. You may not have valid permissions, try again later.',
        description: 'An error message when backend failed to clone a pathway',
    },
    pathwayEducatorCloneSucceeded: {
        id:'pathwayEducatorCloneSucceeded',
        defaultMessage: 'This pathway has been cloned. You may edit the new cloned pathway now.',
        description: 'Shown when the user clones a pathway.',
    },
    designYourOwnLessonText: {
        id:'designYourOwnLessonText',
        defaultMessage: 'Design your own lesson',
        description: 'Text for Design you own lesson button',
    },
    designYourOwnLessonAltText: {
        id:'designYourOwnLessonAltText',
        defaultMessage: 'Icon for design your own lesson link',
        description: 'Alt text for design you own lesson button',
    },
    viewSubjectsAndTopicsText: {
        id:'viewSubjectsAndTopicsText',
        defaultMessage: 'View subjects & topics',
        description: 'Text for View subjects & topics button',
    },
    viewSubjectsAndTopicsAltText: {
        id:'viewSubjectsAndTopicsAltText',
        defaultMessage: 'Icon for subjects & topics link',
        description: 'Alt text for view subjects & topics button',
    },
    createQuizzText: {
        id:'createQuizzText',
        defaultMessage: 'Create a quiz',
        description: 'Text for Create a quiz button',
    },
    createQuizzAltText: {
        id:'createQuizzAltText',
        defaultMessage: 'Icon for create a quiz',
        description: 'Alt text for create a quiz button',
    },
    browsePremadePathwaysText: {
        id:'browsePremadePathwaysText',
        defaultMessage: 'Browse pre-made pathways ',
        description: 'Text for Browse pre-made pathways button',
    },
    createClassText: {
        id:'createClassText',
        defaultMessage: 'Create a class',
        description: 'Text for Create a class button',
    },
    createClassAltText: {
        id:'createClassAltText',
        defaultMessage: 'Icon for create a class link',
        description: 'Alt text for create a class button',
    },
    exploreAllContentTypesText: {
        id:'exploreAllContentTypesText',
        defaultMessage: 'Explore all content types',
        description: 'Text for Explore all content types button',
    },
    requiresFreeAccountText: {
        id:'requiresFreeAccountText',
        defaultMessage: 'requires a free account',
        description: 'requires a free account text',
    },
    teachTextForHeader: {
        id:'teachTextForHeader',
        defaultMessage: 'Teach',
        description: 'Teach text for header',
    },
    gradesTextForEducatorsPage: {
        id:'gradesTextForEducatorsPage',
        defaultMessage: 'Grades 9 - 12',
        description: 'Grades text for techers page',
    },
    teachPageHeroImageText: {
        id:'teachPageHeroImageText',
        defaultMessage: 'What are you {teaching} next?',
        description: 'Header text for hero variation on teach page',
    },
    teachText: {
        id:'teachText',
        defaultMessage: 'teaching',
        description: 'Header text for hero variation on teach page',
    },
    pageTitle :{
        id:'pageTitle',
        defaultMessage: 'Teach',
        description: 'Teach page title.',
    },
    loginError: {
        id:'apploginError',
        defaultMessage: '{loginError}',
        description: 'Error when the user is not able to login',
    },
    fetchExploreContentError :{
        id:'fetchExploreContentError',
        defaultMessage: 'Unable to fetch explore content.',
        description: 'Error when explore API fails.',
    },
});

export default messages;
