// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerDuty,
    CareerDutyFromJSON,
    CareerDutyToJSON,
    CareerOutlook,
    CareerOutlookFromJSON,
    CareerOutlookToJSON,
    CareerOverviewCard,
    CareerOverviewCardFromJSON,
    CareerOverviewCardToJSON,
    CareerQuote,
    CareerQuoteFromJSON,
    CareerQuoteToJSON,
    CareerSubcategory,
    CareerSubcategoryFromJSON,
    CareerSubcategoryToJSON,
    CareerSuggestedAssets,
    CareerSuggestedAssetsFromJSON,
    CareerSuggestedAssetsToJSON,
    ProjectHighlight,
    ProjectHighlightFromJSON,
    ProjectHighlightToJSON,
    RelatedCareerSection,
    RelatedCareerSectionFromJSON,
    RelatedCareerSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Career
 */
export interface Career {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof Career
     */
    slug: string;
    /**
     * Get related career sections for a career.
     * @type {Array<RelatedCareerSection>}
     * @memberof Career
     */
    readonly relatedCareerSections?: Array<RelatedCareerSection>;
    /**
     * Get subcategories associated with a career.
     * @type {Array<CareerSubcategory>}
     * @memberof Career
     */
    readonly subcategories?: Array<CareerSubcategory>;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    title: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof Career
     */
    readonly icon?: string;
    /**
     * introduction video url for the career.
     * @type {string}
     * @memberof Career
     */
    introductionVideoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    subheading: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    minimumQualification: string;
    /**
     * Get sorted career suggested assets for a career.
     * @type {Array<CareerSuggestedAssets>}
     * @memberof Career
     */
    readonly careerSuggestedAssets?: Array<CareerSuggestedAssets>;
    /**
     * Whether the career is published or not.
     * @type {boolean}
     * @memberof Career
     */
    isPublished?: boolean;
    /**
     * A list of alternative names by which this career is known.
     * @type {Array<string>}
     * @memberof Career
     */
    alternativeNames: Array<string>;
    /**
     * A breakdown of the pronunciation of the career title.
     * @type {string}
     * @memberof Career
     */
    pronunciation: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    readonly pronunciationAudio?: string;
    /**
     * 
     * @type {CareerOutlook}
     * @memberof Career
     */
    outlook?: CareerOutlook;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    entryLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    midLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    seniorLevelSalary?: number;
    /**
     * 
     * @type {ProjectHighlight}
     * @memberof Career
     */
    projectHighlight?: ProjectHighlight;
    /**
     * 
     * @type {CareerQuote}
     * @memberof Career
     */
    quote?: CareerQuote;
    /**
     * 
     * @type {CareerDuty}
     * @memberof Career
     */
    duty?: CareerDuty;
    /**
     * Get overview cards for a career.
     * @type {Array<CareerOverviewCard>}
     * @memberof Career
     */
    readonly overviewCards?: Array<CareerOverviewCard>;
    /**
     * Editors Note: Please ensure that given HTML content follows WCAG 2.1 guidelines.Following HTML tags can be used:<br>&lt;i&gt; and &lt;em&gt;  for italics<br>&lt;b&gt; and &lt;strong&gt; for bold<br>&lt;sup&gt; for superscript<br>&lt;sub&gt; for subscript<br>&lt;a&gt; for link<br>&lt;h&gt;, &lt;p&gt;, &lt;ul&gt;, &lt;ol&gt; and &lt;li&gt; for text headings, paragraphs and lists.
     * @type {string}
     * @memberof Career
     */
    references?: string;
    /**
     * Education pathway config to add React Flow Map data config.
     * @type {any}
     * @memberof Career
     */
    pathwayConfig?: any;
    /**
     * 
     * @type {Date}
     * @memberof Career
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Career
     */
    readonly updatedAt?: Date;
}

export function CareerFromJSON(json: any): Career {
    return {
        'slug': json['slug'],
        'relatedCareerSections': !exists(json, 'related_career_sections') ? undefined : (json['related_career_sections'] as Array<any>).map(RelatedCareerSectionFromJSON),
        'subcategories': !exists(json, 'subcategories') ? undefined : (json['subcategories'] as Array<any>).map(CareerSubcategoryFromJSON),
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'introductionVideoUrl': !exists(json, 'introduction_video_url') ? undefined : json['introduction_video_url'],
        'subheading': json['subheading'],
        'description': json['description'],
        'minimumQualification': json['minimum_qualification'],
        'careerSuggestedAssets': !exists(json, 'career_suggested_assets') ? undefined : (json['career_suggested_assets'] as Array<any>).map(CareerSuggestedAssetsFromJSON),
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'alternativeNames': json['alternative_names'],
        'pronunciation': json['pronunciation'],
        'pronunciationAudio': !exists(json, 'pronunciation_audio') ? undefined : json['pronunciation_audio'],
        'outlook': !exists(json, 'outlook') ? undefined : CareerOutlookFromJSON(json['outlook']),
        'entryLevelSalary': !exists(json, 'entry_level_salary') ? undefined : json['entry_level_salary'],
        'midLevelSalary': !exists(json, 'mid_level_salary') ? undefined : json['mid_level_salary'],
        'seniorLevelSalary': !exists(json, 'senior_level_salary') ? undefined : json['senior_level_salary'],
        'projectHighlight': !exists(json, 'project_highlight') ? undefined : ProjectHighlightFromJSON(json['project_highlight']),
        'quote': !exists(json, 'quote') ? undefined : CareerQuoteFromJSON(json['quote']),
        'duty': !exists(json, 'duty') ? undefined : CareerDutyFromJSON(json['duty']),
        'overviewCards': !exists(json, 'overview_cards') ? undefined : (json['overview_cards'] as Array<any>).map(CareerOverviewCardFromJSON),
        'references': !exists(json, 'references') ? undefined : json['references'],
        'pathwayConfig': !exists(json, 'pathway_config') ? undefined : (json['pathway_config']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function CareerToJSON(value?: Career): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'introduction_video_url': value.introductionVideoUrl,
        'subheading': value.subheading,
        'description': value.description,
        'minimum_qualification': value.minimumQualification,
        'is_published': value.isPublished,
        'alternative_names': value.alternativeNames,
        'pronunciation': value.pronunciation,
        'outlook': CareerOutlookToJSON(value.outlook),
        'entry_level_salary': value.entryLevelSalary,
        'mid_level_salary': value.midLevelSalary,
        'senior_level_salary': value.seniorLevelSalary,
        'project_highlight': ProjectHighlightToJSON(value.projectHighlight),
        'quote': CareerQuoteToJSON(value.quote),
        'duty': CareerDutyToJSON(value.duty),
        'references': value.references,
        'pathway_config': (value.pathwayConfig),
    };
}


