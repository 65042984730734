/* istanbul ignore file */

/**
 * Main entry point for the app.
 * Nothing should be exported from this file, because it runs too much
 * code when it is imported. This should only be run once, when the
 * main app loads for a user visiting the site.
 */

/* tslint:disable:ordered-imports */
import 'assets/scss/base.scss';
import 'assets/scss/app.scss';
/* tslint:enable:ordered-imports */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { focusElement } from 'oaf-side-effects';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { checkLoginStatus } from 'auth/actions';
import { fetchBannersNow } from 'banners/actions';
import { fetchTotalPublicAssetCountNow } from 'library/actions';
import { ROUTES } from 'global/constants';
import {
    DEFAULT_UI_LANGUAGE,
    locale,
    localeMessages,
    selectFontStackForLanguage,
} from './i18n';
import { fetchNotificationsPoll } from 'notifications/polls';
import { MainRoutes } from 'routes/main';
import { GlobalMessageReporter } from 'ui/components/GlobalMessageReporter/GlobalMessageReporter';
import { history } from './global/history';
import { createRootReducer, RootState } from './global/reducers';
import { setStore } from './global/store';
import { handleTranslationError, intl } from 'i18n';
import { MathJaxContext } from 'better-react-mathjax';


///////////////////////////////////////////////
// Favicons
import Favicon192 from 'assets/images/favicon-192x192.png';  // eslint-disable-line
import Favicon32 from 'assets/images/favicon-32x32.png';  // eslint-disable-line

// Pollyfills for IE11 etc. fetch() API
import 'whatwg-fetch';  // eslint-disable-line
import { AnalyticsProvider } from 'use-analytics';
import { AnalyticsGateway, analyticsInstance } from 'tracking';
import { LoginModal } from 'auth/components/LxLoginView/LoginModal';
import { Usersnap } from 'usersnap/components/Usersnap';
import { HelmetComponent, srNavigationAnnouncementsDiv } from 'ui/components/Metadata/HelmetComponent';

///////////////////////////////////////////////
// Helper methods

/** Are two Sets equal? */
function areSetsEqual<T>(a: ReadonlySet<T>, b: ReadonlySet<T>) {
    return a.size === b.size && [...a].every((value) => b.has(value));
}

// MathJax Configuration
const mathJaxOptions = {
    loader: { load: ['[tex]/html', '[tex]/enclose', 'input/mml', 'output/chtml'] },
    tex: {
        packages: { '[+]': ['html', 'enclose'] },
        inlineMath: [
            ['\\(', '\\)'],
            ['[mathjaxinline]', '[/mathjaxinline]'],
        ],
        displayMath: [
            ['\\[', '\\]'],
            ['[mathjax]', '[/mathjax]'],
        ]
    },
    startup: {
        typeset: false
    },
    mml: {},
};

///////////////////////////////////////////////
// Redux store
function configureStore(useHistory: History, initialState?: RootState): Store<RootState> {
    let middleware = applyMiddleware(routerMiddleware(useHistory), thunk);

    if (process.env.NODE_ENV !== 'production') {
        middleware = composeWithDevTools(middleware);
    }

    const newStore = createStore(
        createRootReducer(history) as any, initialState as any, middleware,
    ) as Store<RootState>;

    if (import.meta.hot) {
        import.meta.hot.accept('global/reducers', () => {
            import('global/reducers').then((module) => {
            const nextCreateRootReducer = module.createRootReducer; // Access named export directly
            store.replaceReducer(nextCreateRootReducer(history));
    });
    });
}

    return newStore;
}
const store = configureStore(history);
setStore(store);

///////////////////////////////////////////////
// Component that scrolls the window to the top when changing routes.
class ScrollToTop extends React.Component<RouteComponentProps<{}>> {
    public componentDidUpdate(prevProps: RouteComponentProps<{}>) {
        if (this.props.location !== prevProps.location) {
            const path = this.props.location.pathname.replace(/\/$/, ''); // Path without trailing slash
            const prevPath = prevProps.location.pathname.replace(/\/$/, ''); // Path without trailing slash
            if (path === prevPath && [ROUTES.Library.HOME, ROUTES.People.HOME].includes(path)) {
                // We are on a search page. If the only change is to the filters, we don't want to scroll back to top.
                // Otherwise there can be an annoying scroll while the user is changing the filters.
                // But we do want to scroll back to top when the search keyword or page has changed.
                const params = new URLSearchParams(this.props.location.search);
                const oldParams = new URLSearchParams(prevProps.location.search);
                if (!areSetsEqual(new Set(params.getAll('t')), new Set(oldParams.getAll('t')))) {
                    return; // Filters have changed. Don't scroll.
                }
            }
            // Set focus to the document.
            focusElement(document.documentElement);
            // Scroll back to the top of the page, or the top of the .lx-scroll-top-on-navigate element
            const el = document.querySelector('.lx-scroll-top-on-navigate') as HTMLElement | null;
            window.scrollTo(0, el ? el.offsetTop : 0);
        }
    }

    public render() {
        return null;
    }
}

// Check if the user is logged in
checkLoginStatus(store.dispatch);

// Fetch banners
fetchBannersNow();

// Start polls
fetchNotificationsPoll();

// Set current language class on document element.
selectFontStackForLanguage();

// fetch library count
fetchTotalPublicAssetCountNow();

// Determine and set document class for career explorer
if (window.location.pathname.startsWith('/careerxplorer')) {
    document.documentElement.className = 'career-explorer';
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <IntlProvider locale={locale} defaultLocale={DEFAULT_UI_LANGUAGE.key} textComponent={React.Fragment} messages={localeMessages} onError={handleTranslationError}>
        <Provider store={store}>
            <MathJaxContext
                src={'/assets/mathjax/es5/tex-chtml.js'}
                version={3}
                config={mathJaxOptions}
            >
                <AnalyticsProvider instance={analyticsInstance}>
                    <ConnectedRouter history={history}>
                        <>
                            <AnalyticsGateway />
                            <HelmetComponent />
                            <Route component={ScrollToTop} />
                            <MainRoutes />
                            <GlobalMessageReporter />
                            <LoginModal />
                            {srNavigationAnnouncementsDiv}
                            <Usersnap />
                        </>
                    </ConnectedRouter>
                </AnalyticsProvider>
            </MathJaxContext>
        </Provider>
    </IntlProvider>
);
