// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerSearchResult,
    CareerSearchResultFromJSON,
    CareerSearchResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerSearchResults
 */
export interface CareerSearchResults {
    /**
     * 
     * @type {number}
     * @memberof CareerSearchResults
     */
    count: number;
    /**
     * 
     * @type {Array<CareerSearchResult>}
     * @memberof CareerSearchResults
     */
    results: Array<CareerSearchResult>;
    /**
     * 
     * @type {any}
     * @memberof CareerSearchResults
     */
    aggregations: any;
}

export function CareerSearchResultsFromJSON(json: any): CareerSearchResults {
    return {
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(CareerSearchResultFromJSON),
        'aggregations': (json['aggregations']),
    };
}

export function CareerSearchResultsToJSON(value?: CareerSearchResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(CareerSearchResultToJSON),
        'aggregations': (value.aggregations),
    };
}


