import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import classNames from 'classnames';

import { CareerAppliedFilter, CareerSearchRequest, CareerSearchRequestOrderingEnum, CareerSearchResult, CareerSearchResults } from 'labxchange-client';
import { SearchApi } from 'global/api';
import { Spinner } from 'ui/components';

import CareerCard from '../Careers/CareerCard';
import messages from '../../displayMessages';

export const CareerHome: React.FC  = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get('query');

  const [loading, setLoading] = useState<boolean>(true);
  const [careers, setCareers] = useState<CareerSearchResult[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const fetchCareers = async (value?: string, page: number = 1) => {
    try {
      setLoading(true);
      const filters = [] as CareerAppliedFilter[];
      const searchRequest: CareerSearchRequest = {
        filters,
        keywords: value,
        currentPage: 1,
        paginationSize: 16,
        ordering: CareerSearchRequestOrderingEnum.Relevance,
    };
      const response: CareerSearchResults = await SearchApi.careerSearch({data: searchRequest});
      setCareers(response.results);
      // setHasNextPage(!!response.next);
      setCurrentPage(page);
      setCount(response.count);
    } catch (error) {
      setCareers([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching careers', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareers(query || '');
  }, [query]);

  const onClickLoadMore = async () => {
    await fetchCareers(query || '', currentPage + 1);
  };

  return (
      <div className='career-home'>
        <>
          {!careers.length && query && !loading ? (
              <div className='empty-state'>
                <img
                    className='empty-state-image'
                    alt='No results found'
                    src='/assets/images/career-explorer/career-empty-state.svg'
                />
                <h5>{intl.formatMessage(messages.careerNoResultsHeading)}</h5>
                <span>{intl.formatMessage(messages.careerNoResultsSubheading)}</span>
              </div>
          ) : (
              <div className='main-careers-list'>
                {careers.map((career) =>
                    <CareerCard
                        key={career.slug}
                        career={career}
                        showImage
                    />)
                }
              </div>
          )
          }
          <span className='results-count'>
            {intl.formatMessage(messages.careerResultsText, {
              count, plural: count === 1 ? '' : 's'
            })}
          </span>
        </>
        {loading ? <Spinner /> :
            <button
                type='button'
                className={classNames('load-more-button', {'hide-button': !hasNextPage})}
                onClick={onClickLoadMore}
            >
                    <span className='ce-label'>
                      {intl.formatMessage(messages.careerLoadMoreButton)}
                    </span>
            </button>
        }
      </div>
  );
};

export default CareerHome;
