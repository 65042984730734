import { InputSelect } from '@labxchange/ui-components';
import { getUserPermissions } from 'auth/selectors';
import { bind } from 'bind-decorator';
import { RootState } from 'global/state';
import {
    APIPermissions,
    ContentSource,
    ItemMetadataBackgroundKnowledgeEnum,
    ItemMetadataLicenseEnum,
} from 'labxchange-client';
import { getBackgroundKnowledgeLabel, getContentLicenseLabel } from 'library/displayMessages';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { connect } from 'react-redux';
import {
    Button,
    CheckBox,
    ImageField,
    ItemSection,
    LanguageButton,
    LanguageSelector,
    Modal,
    ToggleSwitch
} from 'ui/components';
import { CopyrightAcknowledgement } from 'ui/components/CopyrightAcknowledgement/CopyrightAcknowledgement';
import { getLanguageNativeName } from 'i18n';
import { WrappedMessage } from 'utils';
import { SubjectTagsSelect } from '../SubjectTagsSelect/SubjectTagsSelect';
import messages from './displayMessages';
import languageMessages from 'ui/components/LanguageSelect/displayMessages';
import { useState } from 'react';
import { intl } from 'i18n';

interface AgreementsProps {
    onUpdate: (checked: boolean) => void;
    showErrors: boolean;
    isChecked: boolean;
    enablePIICheck?: boolean;
    isPIIChecked?: boolean;
    onPIIUpdate?: (checked: boolean) => void;
    enableNotificationCheck?: boolean;
    isNotificationChecked?: boolean;
    onNotificationCheckUpdate?: (checked: boolean) => void;
}

export class AgreementsField extends React.PureComponent<AgreementsProps, {}> {

    public static defaultProps = {
        showErrors: false,
    };

    public render() {
        return (
            <div className='unit-agreements'>
                {this.getPIICheck()}
                <div className='form-check'>
                    <CopyrightAcknowledgement
                        showErrors={this.props.showErrors}
                        isChecked={this.props.isChecked}
                        onClick={this.props.onUpdate}
                    />
                </div>
                {this.getNotificationCheck()}
            </div>
        );
    }

    private getPIICheck() {
        if (
            this.props.enablePIICheck &&
            this.props.onPIIUpdate &&
            (this.props.isPIIChecked !== undefined)
        ) {
            return <div className='pii-metadata-field'>
            <CheckBox
                onClick={this.props.onPIIUpdate}
                checked={this.props.isPIIChecked}
            >
                <WrappedMessage message={messages.sendPIIMetadata} />
            </CheckBox>
        </div>;
        }
        return null;
    }

    private getNotificationCheck() {
        if (
            this.props.enableNotificationCheck &&
            this.props.onNotificationCheckUpdate &&
            this.props.isNotificationChecked !== undefined
        ) {
            return <div className='notification-check-field'>
                <CheckBox
                    onClick={this.props.onNotificationCheckUpdate}
                    checked={this.props.isNotificationChecked}
                >
                    <WrappedMessage message={messages.itemNotificationCheck} />
                </CheckBox>
            </div>;
        }
        return null;
    }
}

interface LicenseReduxStateProps {
    userPermissions?: APIPermissions;
}

interface LicenceFieldProps {
    value: string;
    onChange?: (license: ItemMetadataLicenseEnum) => void;
    contentSource?: ContentSource|null;
}

class LicenseFieldInternal extends React.PureComponent<LicenceFieldProps & LicenseReduxStateProps> {

    public render() {
        return (
            <div className='unit-license'>
                <InputSelect
                    name='item_metadata_license'
                    className='lx-select'
                    label={<WrappedMessage message={messages.licenseSelectLabel} />}
                    value={this.props.value}
                    onChange={this.props.onChange ? this.props.onChange : () => { return; }}
                    options={this.options()}
                />
            </div>
        );
    }

    private options() {
        return Object.values(ItemMetadataLicenseEnum).filter(this.showOption, this).map((value) => ({
            label: intl.formatMessage(getContentLicenseLabel(value)),
            value,
        }));
    }

    private showOption(value: string) {
        if (value === this.props.value || value === ItemMetadataLicenseEnum.LX1) {
            return true;
        }
        if (this.props.contentSource &&
            this.props.contentSource.sourceOrganizations &&
            this.props.contentSource.sourceOrganizations[0].organization.enableAdditionalLicenseOptions
        ) {
            const additionalLicenseOptions: string[] = [
                ItemMetadataLicenseEnum.LX1,
                ItemMetadataLicenseEnum.PD,
                ItemMetadataLicenseEnum.CCBY40,
                ItemMetadataLicenseEnum.CCBYNC40,
                ItemMetadataLicenseEnum.CCBY30,
                ItemMetadataLicenseEnum.CCBYNC30,
                ItemMetadataLicenseEnum.CCBY25,
                ItemMetadataLicenseEnum.CCBYNC25,
                ItemMetadataLicenseEnum.CCBY20,
                ItemMetadataLicenseEnum.CCBYNC20,
                ItemMetadataLicenseEnum.CCBY10,
                ItemMetadataLicenseEnum.CCBYNC10,
            ];
            return additionalLicenseOptions.includes(value);
        }

        if (this.props.userPermissions) {
            const libraryPermissions = this.props.userPermissions.library;

            const standardLicenseOptions: string[] = [
                ItemMetadataLicenseEnum.CCBY40,
                ItemMetadataLicenseEnum.CCBYNC40,
            ];

            if (libraryPermissions.canMakeItemPublic && standardLicenseOptions.includes(value)) {
                return true;
            }
        }
        return false;
    }
}

export const LicenseField = connect<LicenseReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(LicenseFieldInternal);

interface BackgroundKnowledgeProps {
    value: string;
    onChange?: (license: ItemMetadataBackgroundKnowledgeEnum) => void;
    required: boolean;
    showErrors: boolean;
}

export class BackgroundKnowledgeField extends React.PureComponent<
    BackgroundKnowledgeProps> {

    public render() {
        // Build the options in the right order
        const knowledgeOptions = Object.values(ItemMetadataBackgroundKnowledgeEnum).map((value) =>
            ({
                label: intl.formatMessage(getBackgroundKnowledgeLabel(value)),
                value,
            }),
        );
        knowledgeOptions.unshift(knowledgeOptions.pop()!);

        const label = <>
            <WrappedMessage message={messages.backgroundKnowledgeSelectLabel} />{this.props.required ? '*' : ''}
        </>;
        const isError = this.props.required && !this.props.value && this.props.showErrors;

        return (
            <div className='unit-background-knowledge'>
                <InputSelect
                    name='item_metadata_background_knowledge'
                    className={`lx-select ${isError ? 'lx-select-error' : ''}`}
                    label={label}
                    value={this.props.value}
                    onChange={this.props.onChange ? this.props.onChange : () => { return; }}
                    options={knowledgeOptions}
                />
            </div>
        );
    }
}

interface SubjectAreaProps {
    selectedSubjectTags: string[];
    onUpdate?: (selectedTagIdSet: ReadonlySet<string>) => void;
    required: boolean;
    showErrors: boolean;
}

export class SubjectAreaField extends React.PureComponent<SubjectAreaProps> {

    public static defaultProps = {
        required: false,
        showErrors: false,
    };

    public render() {
        const isError = this.props.required && this.props.selectedSubjectTags.length === 0 && this.props.showErrors;
        return (
            /* eslint-disable jsx-a11y/label-has-associated-control */
            <div className={`unit-subject ${isError ? 'unit-subject-error' : ''}`}>
                <label className='unit-subject-select-label'>
                    <WrappedMessage message={messages.subjectSelectLabel} />{this.props.required ? '*' : ''}
                    <SubjectTagsSelect
                        onSelectedTagsChanged={this.props.onUpdate ? this.props.onUpdate : () => { return; }}
                        selectedSubjectTags={new Set(this.props.selectedSubjectTags)}
                    />
                </label>
            </div>
            /* eslint-enable */
        );
    }
}

interface LanguageProps {
    value: string;
    onUpdate: (language: string) => void;
}

export const LanguageField = (props: LanguageProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(props.value);
    return (
        <div className='unit-language'>
            <div className='form-group'>
                <label htmlFor='language-select'>
                    <WrappedMessage message={messages.languageSelectLabel} />
                </label>
                <div>
                    {getLanguageNativeName(props.value)}{' '}
                    <LanguageButton onClick={() => setShowOptions(true)} renderAs='text' backgroundStyle='light' />
                </div>
                <Modal
                    className='modal-close-header-action'
                    isOpen={showOptions}
                    onRequestClose={() => setShowOptions(false)}
                    closeButtonText={languageMessages.languageSelectCancel}
                    size='normal'
                    title={<WrappedMessage message={languageMessages.languageSelectTitle} />}
                    showTopBar={true}
                    content={
                        <div className='translation-selector-modal'>
                            <LanguageSelector
                                currentLanguage={selectedLanguage}
                                onSetLanguage={setSelectedLanguage}
                            />
                            <Button
                                btnStyle='primary'
                                fullWidth={true}
                                label={languageMessages.languageSelectConfirm}
                                onClick={() => {
                                    props.onUpdate(selectedLanguage);
                                    setShowOptions(false);
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

interface PublicToggleProps {
    isPublic: boolean;
    onUpdate: (isPublic: boolean) => void;
    titleMessage?: MessageDescriptor;
    ariaTitleMessage?: MessageDescriptor;
    descriptionMessage?: MessageDescriptor;
    noteMessage?: MessageDescriptor;
    showNote?: boolean;
    disabled?: boolean;
}

export class PublicToggleField extends React.PureComponent<PublicToggleProps> {
    public render() {
        const titleMessage = this.props.titleMessage || messages.itemPublicContentSectionTitle;
        const ariaTitleMessage = this.props.ariaTitleMessage || messages.ariaItemPublicContentToggleLabel;
        const descriptionMessage = this.props.descriptionMessage || messages.itemPublicContentInformation;
        const noteMessage = this.props.noteMessage || messages.itemPublicContentNote;

        return (
            <div className='unit-public-content'>
                <ItemSection
                    title={<WrappedMessage message={titleMessage} />}
                    sectionName='item-public-content'
                    toggleSwitch={
                        <ToggleSwitch
                            isChecked={this.props.isPublic}
                            hideLabel={true}
                            displayText={intl.formatMessage(ariaTitleMessage)}
                            onToggleSwitchSelect={this.props.onUpdate}
                            disabled={this.props.disabled}
                        />
                    }>
                    <div className='unit-public-content'>
                        <p className='unit-public-content-information'>
                            <WrappedMessage
                                message={descriptionMessage}
                            />
                        </p>
                        {this.props.showNote
                        ?
                            <p className='unit-public-content-note'>
                                <WrappedMessage
                                    message={noteMessage}
                                />
                            </p>
                        : null}
                    </div>
                </ItemSection>
            </div>
        );
    }
}

interface FeaturedImageFieldProps {
    defaultValue?: string;
    onUpdate: (newFile: File|undefined, newUrl: string) => void;
    titleMessage?: MessageDescriptor;
    required: boolean;
    showErrors: boolean;
    selectedFile?: File;
}

interface FeaturedImageFieldState {
    currentFile?: File;
    currentUrl: string;
}

export class FeaturedImageField extends React.PureComponent<
    FeaturedImageFieldProps, FeaturedImageFieldState> {

    public static defaultProps = {
        showErrors: false,
    };

    constructor(props: FeaturedImageFieldProps) {
        super(props);
        this.state = {
            currentUrl: this.props.defaultValue || '',
        };
    }

    public render() {
        const titleMessage = this.props.titleMessage || messages.itemFeaturedImageSectionTitle;

        const title = <>
            <WrappedMessage message={titleMessage} />{this.props.required ? '*' : ''}
        </>;

        return (
            <ItemSection
                extraClasses='item-featured-image'
                title={title}
                sectionName='item-featured-image-section'
            >
                <div className='item-featured-image-content'>
                    <ImageField
                        label={undefined}
                        currentUrl={this.state.currentUrl}
                        currentFile={this.state.currentFile || this.props.selectedFile}
                        onChange={this.onUpdate}
                        required={this.props.required}
                        showErrors={this.props.showErrors}
                    />
                </div>
            </ItemSection>
        );
    }

    @bind private onUpdate(newFile: File|undefined, newUrl: string) {
        this.setState({currentFile: newFile, currentUrl: newUrl});
        this.props.onUpdate(newFile, newUrl);
    }
}

interface ContentSourceFieldProps {
    // Does this user have permission to change the content source?
    allowChangingSource: boolean;
    // What content source is currently selected
    selectedSourceId: number;
    onUpdateContentSource: (source: ContentSource) => void;
    contentSources: ContentSource[]|null;
}


export const ContentSourceField: React.FunctionComponent<ContentSourceFieldProps> = ({
    selectedSourceId,
    allowChangingSource,
    onUpdateContentSource,
    contentSources,
}) => {
    const onChange = React.useCallback((event: React.FocusEvent<HTMLSelectElement>) => {
        if (allowChangingSource && contentSources) {
            const source = contentSources.find(e => e.id === event.target.value);
            onUpdateContentSource(source!);
        }
    }, [allowChangingSource, onUpdateContentSource]);
    return <div className='unit-content-source'>
        <div className='form-group'>
            <label htmlFor='content-source-select'>
                <WrappedMessage message={messages.sourceSelectLabel} />
            </label>
            <select
                id='content-source-select'
                name='item_metadata_content_source'
                className='lx-select form-control'
                defaultValue={selectedSourceId}
                disabled={!allowChangingSource}
                onBlur={onChange}
            >
                {contentSources && contentSources.map((cs) => (
                    <option
                      key={cs.id}
                      value={cs.id}
                    >
                        {cs.title || (cs.sourceOrganizations && cs.sourceOrganizations[0].organization.name)}
                    </option>
                ))}
            </select>
        </div>
    </div>;
};
