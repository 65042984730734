import React from 'react';
import {WrappedMessage} from '../../../../utils';
import messages from '../../../displayMessages';
import CollapsibleComponent from 'career-explorer/components/CareerCollapsible/CollapsibleComponent';
import { intl } from 'i18n';
import { sanitizeConfigOptions, sanitizeUnsafeHTML } from '@labxchange/util-sanitization';
import moment from 'moment-shortformat';

interface EditHistoryProps {
    createdAt: Date | undefined;
    updatedAt: Date| undefined;
}

const EditHistory: React.FC<EditHistoryProps> = ({createdAt, updatedAt}) => {
    const dateFormat = 'MMMM D, YYYY';

    return (
        <div className='edit-history'>
            <div className='frame'>
                <div className='version-chip dark'></div>
                <div className='line'></div>
                <div className='version-chip light'></div>
            </div>
            <div className='history-details'>
                <div className='last-updated'>
                    <WrappedMessage
                        message={messages.updateHistoryUpdatedOnLabelText}
                        values={{date: moment(updatedAt).format(dateFormat)}}
                    />
                </div>
                <div className='created-date'>
                    <WrappedMessage
                        message={messages.updateHistoryUploadedOnLabelText}
                        values={{date: moment(createdAt).format(dateFormat)}}
                    />
                </div>
            </div>
        </div>
    );
};

interface SourcesSectionProps {
    references: string | undefined;
    createdAt: Date | undefined;
    updatedAt: Date| undefined;
}

const SourcesSection: React.FC<SourcesSectionProps> = ({references, createdAt, updatedAt}) => {

    return (
        <div className='sources-section'>
            <h2><WrappedMessage message={messages.sourcesSectionHeadingText} /></h2>
            <div className='sources-content'>
                <p className='sources-description'>
                    <WrappedMessage message={messages.sourcesSectionDescriptionText} />
                </p>
                {references &&
                    <CollapsibleComponent title={intl.formatMessage(messages.referencesLabelText)}>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: sanitizeUnsafeHTML(references, sanitizeConfigOptions.UnsafeHTMLAllowedStyles)}} />
                            <span className='mission-statement-label'>
                                <WrappedMessage message={messages.missionStatementLabelText} />
                            </span>
                            <p>
                                <WrappedMessage message={messages.missionStatementDescriptionText} />
                            </p>
                        </div>
                    </CollapsibleComponent>
                }
                <CollapsibleComponent title={intl.formatMessage(messages.updateHistoryLabelText)}>
                    <EditHistory createdAt={createdAt} updatedAt={updatedAt} />
                </CollapsibleComponent>
            </div>
        </div>
    );
};

export default SourcesSection;
