import React from 'react';
import classNames from 'classnames';

interface PillButtonProps {
  label: string;
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  className?: string;
  ariaLabel?: string;
}

export const PillButton: React.FC<PillButtonProps> = ({
  label,
  onClick,
  isSelected = false,
  isDisabled = false,
  className = '',
  ariaLabel = '',
}) => {
  return (
    <button
      className={classNames(
        'pill-button',
        { 'pill-button-selected': isSelected, 'pill-button-disabled': isDisabled },
        className
      )}
      onClick={onClick}
      disabled={isDisabled}
      aria-label={ariaLabel}
    >
      {label}
    </button>
  );
};
