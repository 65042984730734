// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CareerSearchRequest,
    CareerSearchRequestFromJSON,
    CareerSearchRequestToJSON,
    CareerSearchResults,
    CareerSearchResultsFromJSON,
    CareerSearchResultsToJSON,
    DiscussionsSearchRequestData,
    DiscussionsSearchRequestDataFromJSON,
    DiscussionsSearchRequestDataToJSON,
    DiscussionsSearchResults,
    DiscussionsSearchResultsFromJSON,
    DiscussionsSearchResultsToJSON,
    OrgSearchRequest,
    OrgSearchRequestFromJSON,
    OrgSearchRequestToJSON,
    OrgSearchResults,
    OrgSearchResultsFromJSON,
    OrgSearchResultsToJSON,
    PeopleSearchRequestData,
    PeopleSearchRequestDataFromJSON,
    PeopleSearchRequestDataToJSON,
    PeopleSearchResults,
    PeopleSearchResultsFromJSON,
    PeopleSearchResultsToJSON,
    SearchRequest,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SearchResults,
    SearchResultsFromJSON,
    SearchResultsToJSON,
    SearchSuggestionResponse,
    SearchSuggestionResponseFromJSON,
    SearchSuggestionResponseToJSON,
    SearchTitleResponse,
    SearchTitleResponseFromJSON,
    SearchTitleResponseToJSON,
} from '../models';

export interface CareerSearchRequest {
    data: CareerSearchRequest;
}

export interface DiscussionsSearchRequest {
    data: DiscussionsSearchRequestData;
}

export interface LibrarySearchRequest {
    data: SearchRequest;
}

export interface LibraryTitlesSearchRequest {
    search?: string;
    language?: string;
}

export interface OrganizationSearchRequest {
    data: OrgSearchRequest;
}

export interface PeopleSearchRequest {
    data: PeopleSearchRequestData;
}

export interface PopularQueriesRequest {
    search?: string;
}

/**
 * no description
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for careers
     */
    async careerSearchRaw(requestParameters: CareerSearchRequest): Promise<runtime.ApiResponse<CareerSearchResults>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling careerSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/careers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CareerSearchRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CareerSearchResultsFromJSON(jsonValue));
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for careers
     */
    async careerSearch(requestParameters: CareerSearchRequest): Promise<CareerSearchResults> {
        const response = await this.careerSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search for threads.
     */
    async discussionsSearchRaw(requestParameters: DiscussionsSearchRequest): Promise<runtime.ApiResponse<DiscussionsSearchResults>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling discussionsSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/discussions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscussionsSearchRequestDataToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscussionsSearchResultsFromJSON(jsonValue));
    }

    /**
     * Search for threads.
     */
    async discussionsSearch(requestParameters: DiscussionsSearchRequest): Promise<DiscussionsSearchResults> {
        const response = await this.discussionsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for items in the library.
     */
    async librarySearchRaw(requestParameters: LibrarySearchRequest): Promise<runtime.ApiResponse<SearchResults>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling librarySearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/library`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultsFromJSON(jsonValue));
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for items in the library.
     */
    async librarySearch(requestParameters: LibrarySearchRequest): Promise<SearchResults> {
        const response = await this.librarySearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search for items title in the library.
     */
    async libraryTitlesSearchRaw(requestParameters: LibraryTitlesSearchRequest): Promise<runtime.ApiResponse<SearchTitleResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/library_titles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchTitleResponseFromJSON(jsonValue));
    }

    /**
     * Search for items title in the library.
     */
    async libraryTitlesSearch(requestParameters: LibraryTitlesSearchRequest): Promise<SearchTitleResponse> {
        const response = await this.libraryTitlesSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for organizations
     */
    async organizationSearchRaw(requestParameters: OrganizationSearchRequest): Promise<runtime.ApiResponse<OrgSearchResults>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling organizationSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgSearchRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgSearchResultsFromJSON(jsonValue));
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for organizations
     */
    async organizationSearch(requestParameters: OrganizationSearchRequest): Promise<OrgSearchResults> {
        const response = await this.organizationSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for user profiles
     */
    async peopleSearchRaw(requestParameters: PeopleSearchRequest): Promise<runtime.ApiResponse<PeopleSearchResults>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling peopleSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeopleSearchRequestDataToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeopleSearchResultsFromJSON(jsonValue));
    }

    /**
     * This should really be a GET method since it does not mutate anything, but we want to accept body parameters so it's a POST method.
     * Search for user profiles
     */
    async peopleSearch(requestParameters: PeopleSearchRequest): Promise<PeopleSearchResults> {
        const response = await this.peopleSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns search suggestions from popular search queries.
     */
    async popularQueriesRaw(requestParameters: PopularQueriesRequest): Promise<runtime.ApiResponse<SearchSuggestionResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/library_popular_queries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSuggestionResponseFromJSON(jsonValue));
    }

    /**
     * Returns search suggestions from popular search queries.
     */
    async popularQueries(requestParameters: PopularQueriesRequest): Promise<SearchSuggestionResponse> {
        const response = await this.popularQueriesRaw(requestParameters);
        return await response.value();
    }

}
