import { bind } from 'bind-decorator';
import { Icon } from 'elements';
import { AnnotatedVideoAnnotation, AnnotatedVideoQuestion } from 'labxchange-client';
import * as React from 'react';
import * as UI from 'ui/components';
import { isURL, WrappedMessage } from 'utils';

import messages from './displayMessages';
import { ProblemEditor } from './ProblemEditor';
import { intl } from 'i18n';
import { InputSelect } from '@labxchange/ui-components';
import { MAX_IMAGE_ASSET_SIZE } from 'global/constants';
import { showWarningMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import libraryMessages from 'library/displayMessages';

interface Props {
    startPosition?: number;
    annotation?: AnnotatedVideoAnnotation;
    withQuestion?: boolean;
    onSave(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined | '',
        withQuestion: boolean): void;
    onDelete(
        annotation?: AnnotatedVideoAnnotation,
        withQuestion?: boolean): void;
    /// Function to send the TimerInput references to keep the paddles in sync with the Inputs
    onStartForm?(startTimerRef: React.RefObject<UI.TimerInput>, endTimer: React.RefObject<UI.TimerInput>): void;
    onStartQuestionEdition?(startTimerRef: React.RefObject<UI.TimerInput>): void;
    /// Function to send the change in a TimerInput
    onChangeAnnotationTimes?(isLeft: boolean, newTime: number): void;
    onChangeQuestionTime?(newTime: number): void;
    questionStartOrderMap: {[start: string]: number};
}

interface State {
    startValue: number;
    endValue: number;
    titleValue: string;
    descriptionValue: string;
    linkValue: string;
    tagsValue: string[];
    imageValue?: string;
    imageFileValue?: File;
    imageAltValue: string;
    questionValue?: AnnotatedVideoQuestion;
    questionFieldInvalid: boolean;
    questionAnswersFieldInvalid: boolean;
    startFieldInvalid: boolean;
    endFieldInvalid: boolean;
    titleFieldInvalid: boolean;
    linkFieldInvalid: boolean;
    imageAltFieldInvalid: boolean;
    questionSetOrder: string;
    totalQuestionsAtTime: number;
}

export class AnnotationEditor extends React.PureComponent<Props, State> {
    private startTimer: React.RefObject<UI.TimerInput>;
    private endTimer: React.RefObject<UI.TimerInput>;

    constructor(props: Props) {
        super(props);
        this.state = {
            startValue: this.props.annotation?.start || this.props.startPosition || 0,
            endValue: this.props.annotation?.end || 0,
            titleValue: this.props.annotation?.title || '',
            descriptionValue: this.props.annotation?.description || '',
            linkValue: this.props.annotation?.link || '',
            tagsValue: this.props.annotation?.tags || [],
            imageValue: this.props.annotation?.imageUrl,
            imageAltValue: this.props.annotation?.imageAlt || '',
            questionValue: this.props.annotation?.question,
            questionFieldInvalid: false,
            questionAnswersFieldInvalid: false,
            startFieldInvalid: false,
            endFieldInvalid: false,
            titleFieldInvalid: false,
            linkFieldInvalid: false,
            imageAltFieldInvalid: false,
            questionSetOrder: (this.props.annotation && this.props.annotation.questionSetOrder) || '1',
            totalQuestionsAtTime: this.props.questionStartOrderMap[this.props.annotation?.start || 0] || 1,
        };
        this.startTimer = React.createRef();
        this.endTimer = React.createRef();
    }

    public componentDidMount() {
        if (this.props.withQuestion) {
            if (this.props.onStartQuestionEdition) {
                this.props.onStartQuestionEdition(this.startTimer);
            }
        } else {
            if (this.props.onStartForm){ this.props.onStartForm(this.startTimer, this.endTimer); }
        }
    }

    public render() {
        const annotation = this.props.annotation;
        const withQuestion = this.props.withQuestion || (this.state.questionValue
                && Object.keys(this.state.questionValue).length > 0);
        return <li className={
            `annotated-video-annotation-editor
            ${!annotation ? ' annotated-video-annotation-editor-new' : ''}`}>
            <div className='annotated-video-annotation-editor-header'>
                <Icon name='text'/>
                {!withQuestion
                ? <WrappedMessage message={messages.annotationEditorTitle}/>
                : <WrappedMessage message={messages.annotationEditorQuestionTitle}/>}
            </div>
            <div className='annotated-video-annotation-editor-body'>
                <div className='form-group' data-testid='start-input'>
                    <label htmlFor='annotation-video-annotation-editor-start-label'>
                        <WrappedMessage message={messages.annotationEditorStartInputLabel}/>
                    </label>
                    <UI.TimerInput
                        ref={this.startTimer}
                        aria-labelledby='annotation-video-annotation-editor-start-label'
                        value={this.state.startValue}
                        style={this.state.startFieldInvalid ? 'invalid': 'default'}
                        onChange={this.onChangeStartValue}
                    />
                </div>
                {!withQuestion &&
                    <div className='form-group' data-testid='end-input'>
                        <label htmlFor='annotation-video-annotation-editor-end-label'>
                            <WrappedMessage message={messages.annotationEditorEndInputLabel}/>
                        </label>
                        <UI.TimerInput
                            ref={this.endTimer}
                            aria-labelledby='annotation-video-annotation-editor-end-label'
                            value={annotation && annotation.end}
                            style={this.state.endFieldInvalid ? 'invalid': 'default'}
                            onChange={this.onChangeEndValue}
                        />
                    </div>
                }
                {withQuestion &&
                    <>
                        <div className='form-group question-set-order' data-testid='end-input'>
                            <InputSelect
                                name='question_set_order'
                                className='lx-select'
                                options={this.prepareOptions()}
                                value={ this.state.questionSetOrder || this.state.totalQuestionsAtTime.toString()}
                                label={<WrappedMessage message={messages.annotationQuestionSetOrderLabel} />}
                                onChange={this.onChangeQuestionSetOrder}
                            />
                        </div>
                    </>
                }
                <div className='form-group'>
                    <div className='form-group' data-testid='title-input'>
                        <UI.HtmlTextBox
                            showErrors={this.state.titleFieldInvalid}
                            label={intl.formatMessage(!withQuestion
                                ? messages.annotationEditorTitleInputLabel
                                : messages.annotationEditorQuestionTitleInputLabel)}
                            placeholder={intl.formatMessage(messages.annotationEditorTitleInputPlaceholder)}
                            defaultValue={annotation && annotation.title}
                            editorStyle={UI.HtmlTextBox.EditorStyle.OnlyScripts}
                            onChange={(value) => this.setState({titleValue: value})}
                            height={45}
                            required={true}
                        />
                    </div>
                </div>
                {!withQuestion &&
                    <div className='form-group' data-testid='description-input'>
                        <UI.HtmlTextBox
                            label={intl.formatMessage(messages.annotationEditorDescriptionInputLabel)}
                            placeholder={intl.formatMessage(messages.annotationEditorDescriptionInputPlaceholder)}
                            defaultValue={annotation && annotation.description}
                            editorStyle={UI.HtmlTextBox.EditorStyle.Simple}
                            onChange={(value) => this.setState({descriptionValue: value})}
                        />
                    </div>
                }
                {
                    <>
                        <UI.ImageField
                            required={false}
                            showErrors={false}
                            currentUrl={this.state.imageValue || ''}
                            currentFile={this.state.imageFileValue}
                            keepGeneratedUrl={true}
                            onChange={this.onChangeImage}
                            maxImageSize={MAX_IMAGE_ASSET_SIZE}
                            onMaxImageSize={this.onMaxImageSize}
                        />
                        <br/>
                        <div className='form-group'>
                            <label>
                                <WrappedMessage message={messages.annotationEditorImageAltInputLabel}/>
                                <input
                                    type='text'
                                    placeholder={intl.formatMessage(messages.annotationEditorImageAltInputPlaceholder)}
                                    onFocus={() => this.setState({imageAltFieldInvalid: false})}
                                    className={`form-control ${this.state.imageAltFieldInvalid && 'lx-input-error'}`}
                                    defaultValue={annotation && annotation.imageAlt}
                                    onBlur={(e) => this.setState({imageAltValue: e.currentTarget.value})}
                                />
                            </label>
                        </div>
                    </>
                }
                {!withQuestion &&
                    <div className='form-group'>
                        <label>
                            <WrappedMessage message={messages.annotationEditorLinkInputLabel}/>
                            <input
                                type='link'
                                placeholder={intl.formatMessage(messages.annotationEditorLinkInputPlaceholder)}
                                onFocus={() => this.setState({linkFieldInvalid: false})}
                                className={`form-control ${this.state.linkFieldInvalid && 'lx-input-error'}`}
                                defaultValue={annotation && annotation.link}
                                onBlur={(e) => this.setState({linkValue: e.currentTarget.value})}
                            />
                        </label>
                    </div>
                }
                {withQuestion && <ProblemEditor
                    question={this.state.questionValue}
                    showQuestionError={this.state.questionFieldInvalid}
                    showAnswersError={this.state.questionAnswersFieldInvalid}
                    onChange={(questionValue: AnnotatedVideoQuestion) => this.setState({questionValue})}/>
                }
                <UI.ItemSection
                    isMandatory={false}
                    title={intl.formatMessage(messages.annotationEditorTagsSectionName)}
                    sectionName={intl.formatMessage(messages.annotationEditorTagsSectionName)}>
                    <UI.TagsEditor
                        tags={this.state.tagsValue}
                        label={messages.annotationEditorTagsHelpText}
                        showErrors={false}
                        placeholder={messages.annotationEditorTagsInputPlaceholder}
                        onChange={this.onChangeTags}
                        required={false}/>
                </UI.ItemSection>
            </div>
            <div className='annotated-video-annotation-editor-footer'>
                <UI.Button
                    data-testid='delete'
                    btnStyle='link'
                    onClick={() => this.props.onDelete(this.props.annotation, this.props.withQuestion || false)}
                    icon='trashcan'
                    label={messages.annotationEditorDeleteButton}/>
                <UI.Button
                    data-testid='submit'
                    btnStyle='primary'
                    onClick={this.onSave}
                    label={
                        !withQuestion
                        ? messages.annotationEditorSaveButton
                        : messages.annotationEditorSaveQuestionButton}/>
            </div>
        </li>;
    }

    @bind onChangeImage(newFile: File | undefined, newUrl: string, generatedUrl?: string) {
        this.setState({
            imageValue: newFile ? generatedUrl : newUrl,
            imageFileValue: newFile,
        });
    }

    @bind onChangeTags(tags: string[]) {
        this.setState({tagsValue: tags});
    }

    @bind onChangeStartValue(hours: number, minutes: number, seconds: number, total: number) {
        this.setState({startFieldInvalid: false, startValue: total});
        if (this.props.withQuestion) {
            if(this.props.onChangeQuestionTime) {
                this.props.onChangeQuestionTime(total);
            }
            if (this.props.questionStartOrderMap[total.toString()]) {
                this.setState({totalQuestionsAtTime: this.props.questionStartOrderMap[total.toString()] + 1});
            } else {
                this.setState({totalQuestionsAtTime: 1});
            }
        } else {
            if (this.props.onChangeAnnotationTimes) {
                this.props.onChangeAnnotationTimes(true, total);
            }
        }
    }

    @bind onChangeEndValue(hours: number, minutes: number, seconds: number, total: number) {
        this.setState({endFieldInvalid: false, endValue: total});
        if (this.props.onChangeAnnotationTimes) {
            this.props.onChangeAnnotationTimes(false, total);
        }
    }

    @bind onChangeQuestionSetOrder(value: string) {
        this.setState({questionSetOrder: value});
    }

    @bind private isValid() {
        let isValid = true;
        const newState = {
            titleValue: this.state.titleValue.trim(),
            descriptionValue: this.state.descriptionValue.trim(),
            linkValue: this.state.linkValue.trim(),
            imageAltValue: this.state.imageAltValue.trim(),
            tagsValue: this.state.tagsValue,
            questionValue: this.state.questionValue,
            questionFieldInvalid: false,
            questionAnswersFieldInvalid: false,
            startFieldInvalid: false,
            endFieldInvalid: false,
            titleFieldInvalid: false,
            linkFieldInvalid: false,
            imageAltFieldInvalid: false,
        };

        if (newState.questionValue && newState.questionValue.question) {
            newState.questionValue.question = newState.questionValue.question.trim();
        }

        if (this.state.endValue && this.state.startValue >= this.state.endValue) {
            newState.startFieldInvalid = true;
            newState.endFieldInvalid = true;
            isValid = false;
        }

        if (newState.titleValue === '') {
            newState.titleFieldInvalid = true;
            isValid = false;
        }

        if (newState.linkValue) {
            isValid = isURL(newState.linkValue);
            if (!isValid) {
                newState.linkFieldInvalid = true;
            }
        }

        if (this.state.imageValue || this.state.imageFileValue) {
            if (!newState.imageAltValue) {
                newState.imageAltFieldInvalid = true;
                isValid = false;
            }
        }

        const shouldHaveQuestion = this.props.withQuestion;

        if (shouldHaveQuestion) {
            if (!newState.questionValue || Object.keys(newState.questionValue).length === 0
                || newState.questionValue.question === '') {
                newState.questionFieldInvalid = true;
                isValid = false;
            }

            const answersValue = newState.questionValue && (newState.questionValue.answers || []);

            if (!answersValue || answersValue.length === 0) {
                newState.questionAnswersFieldInvalid = true;
                isValid = false;
            }

            const hasOneValidAnswer = !answersValue ||
                (answersValue.some((value) => value.correct === true));
            if (!hasOneValidAnswer) {
                newState.questionAnswersFieldInvalid = true;
                isValid = false;
            }

            const hasEmptyAnswer = !answersValue || answersValue.some((value) => value.text === '');
            if (hasEmptyAnswer) {
                newState.questionAnswersFieldInvalid = true;
                isValid = false;
        }
        }

        this.setState(newState);
        return isValid;
    }

    @bind private onSave() {
        if (!this.isValid()) { return; }
        this.props.onSave({
            id: this.props.annotation ? this.props.annotation.id : '',
            start: this.state.startValue,
            end: this.state.endValue,
            title: this.state.titleValue,
            description: this.state.descriptionValue,
            link: this.state.linkValue,
            tags: this.state.tagsValue,
            imageUrl: this.state.imageValue,
            imageAlt: this.state.imageAltValue,
            question: this.state.questionValue,
            questionSetOrder: this.state.questionSetOrder,
        } as AnnotatedVideoAnnotation,
        this.state.imageFileValue,
        this.props.withQuestion || false);
    }

    private prepareOptions() {
        const options = [];
        for (let i = 1; i <= this.state.totalQuestionsAtTime; i += 1) {
            const temp = {
                label: i.toString(),
                value: i.toString(),
            };
            options.push(temp);
        }
        return options;
    }

    private onMaxImageSize() {
        showWarningMessage(intl.formatMessage(
            libraryMessages.maxImageSizeAlertMessage,
            {maxSizeNumber: MAX_IMAGE_ASSET_SIZE}
        ));
    }
}
