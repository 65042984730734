import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Career, CareerAppliedFilter, CareerList, CareerListPaginated, CareerSearchRequest, CareerSearchResults, CareerSubcategory } from 'labxchange-client';
import { SearchApi } from 'global/api';
import Banner from '../Banner/Banner';
import Tabs from '../Tabs';
import CareerCard from './CareerCard';
import { Spinner } from 'ui/components';

export const CareersPage: React.FC = () => {
  const { field: subcategoryId } = useParams<{ field: string }>();
  const location = useLocation<{ subcategoryData: CareerSubcategory }>();
  const subcategoryData = location.state?.subcategoryData;

  const [loading, setLoading] = useState<boolean>(true);
  const [careers, setCareers] = useState<Career[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(0);
  const fetchCareers = async (value?: string, page: number = 1) => {
    try {
      setLoading(true);
      const searchRequest: CareerSearchRequest = {
        keywords: value,
    };
      const response: CareerSearchResults = await SearchApi.careerSearch(searchRequest);
      setCareers(response.results);

      // setHasNextPage(!!response.next);
      setCurrentPage(page);
    } catch (error) {
      setCareers([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching careers', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareers();
  }, [subcategoryId]);


  return (
      <div className='career-home'>
        {!loading ?
          <>
            <Banner
                title={subcategoryData?.subcategoryName}
                description={subcategoryData?.definition}
            />
            <Tabs tabs={[{ label: 'Careers' }, { label: 'Skills' }]} onTabChange={setActiveTab} />

            {activeTab === 0 &&
                <div className='careers-list'>
                  {careers.length ?
                      <>
                        {careers.map((career:Career, index:number) => (
                            <CareerCard
                                key={index}
                                career={career}
                            />
                        ))}
                      </>
                       :
                      <div>
                          No Career listed under {subcategoryData?.subcategoryName}
                      </div>
                  }
                </div>
            }
          </>
            :
            <Spinner />
        }

      </div>
  );
};

export default CareersPage;
