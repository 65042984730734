import React from 'react';
import { intl } from 'i18n';
import { Link } from 'react-router-dom';

import { ModuleSibling } from 'labxchange-client';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

import messages from '../../../../displayMessages';

interface CloselyRelatedSlabProps {
  modules: ModuleSibling[];
}

export const CloselyRelatedSlab = ({ modules }: CloselyRelatedSlabProps) => {
    return (
        <div className='closely-related-slab'>
            <div className='title-subheading'>
                <h2 className='title font-s-lt'>{intl.formatMessage(messages.closelyRelatedSlabHeading)}</h2>
                <span className='subheading'>{intl.formatMessage(messages.closelyRelatedSlabSubheading)}</span>
            </div>
            <div className='slab-content'>
                {modules.map((relatedModule) => (
                    <Link
                        key={relatedModule.name}
                        to={relatedModule.url}
                        className='related-module-card'
                        onClick={() => analyticsInstance.track(EVENT_NAMES.CurriculumModulePageCloselyRelatedCardClicked, {
                            module_id: relatedModule.id,
                            module_slug: relatedModule.slug,
                            module_name: relatedModule.name,
                            module_url: relatedModule.url,
                            url: window.location.toString(),
                        })}
                    >
                        <span>{relatedModule.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};
