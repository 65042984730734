import bind from 'bind-decorator';
import * as React from 'react';

import { Icon } from 'elements';
import { CheckBox } from 'ui/components/CheckBox';
import { Collapsible } from '@labxchange/ui-components';
import { WrappedMessage, showFilterTabsUi } from 'utils';
import messages from '../../displayMessages';
import { TaxonomyCategory } from 'search/components/Taxonomy/types';
import { TagData } from './types';
import { intl } from 'i18n';

/**
 * Properties shared by <Tag> and <TagList> components.
 */
export interface TagUiProps {
    displayCount?: boolean;
    /** Set of all tag IDs in the current tag hierarchy that the user has selected. */
    selectedTagIds: ReadonlySet<string>;
    onTagSelect?: (tagId: string, tagSelected: boolean, selectedTagIdSet: ReadonlySet<string>) => void;
}

export interface TagProps extends TagData, TagUiProps {
    category?: TaxonomyCategory;
}

interface TagState {
    /**
     * If this tag/choice has child tags/choices, this indicates whether they
     * are visible (default, true) or if the user has collapsed them out of
     * view.
     */
    isOpen: boolean;
}

type messageKey = keyof typeof messages;

/**
 * A toggleable entry in the search filter list.
 * Note that it's not always a tag in the technical sense; this
 * may represent another search filter option like "duration < 10min"
 */
export class Tag extends React.PureComponent<TagProps, TagState> {

    constructor(props: TagProps) {
        super(props);
        this.state = {
            isOpen: showFilterTabsUi() ? this.numberOfChildTagsSelected() > 0 : false,
        };
    }

    public isTagSelected(tagId: string = this.props.id) {
        return this.props.selectedTagIds.has(tagId);
    }

    public numberOfChildTagsSelected(): number {
        return this.props.childTags ? this.props.childTags.reduce(
            (count: number, tag: TagData) => count + (this.isTagSelected(tag.id) ? 1 : 0), 0,
        ) : 0;
    }

    /**
     * Is any parent tag selected?
     * This relies on the assumption that child tag IDs
     * are build from strings that begin with the parent tag ID
     * followed by a colon (:).
     */
    public get isParentTagSelected(): boolean {
        const parentTagId = this.props.id.split(':').slice(0, -1).join(':');
        if (parentTagId) {
            return this.isTagSelected(parentTagId);
        }
        return false;
    }

    public render() {
        const message = nameForTag(this.props, this.props.category);

        let text: React.ReactNode;
        // if there is a count to be added, format the message with the counter.
        if (this.props.displayCount && this.props.numEntities !== undefined) {
            text = <>
                <WrappedMessage
                    message={messages.tagItemWithCount}
                    values={{ name: message, count: this.props.numEntities }}
                />
            </>;
        } else {
            text = message;
        }

        // If there are child tags, clicking on the text should collapse/expand
        // the display of child tags. Otherwise, clicking on the text should
        // toggle the checkmark.
        if (this.props.childTags && this.props.childTags.length > 0) {
            const someSubTagsSelected = this.numberOfChildTagsSelected() > 0;
            return <li className='tag' data-tag-id={this.props.id}>
                <CheckBox
                    checked={
                        this.isTagSelected() ? true :
                        someSubTagsSelected ? null :
                        false
                    }
                    onClick={this.onCheckboxClick}
                    altLabel={this.props.name}
                />
                <Collapsible
                    title={<>
                        <span className='tag-color'>
                            {text}
                        </span>
                        <Icon className='toggle-icon'
                            name={this.state.isOpen ? 'chevron-up' : 'chevron-down'}
                        />
                    </>}
                    isOpen={this.state.isOpen}
                    onToggle={this.onToggle}
                    isCollapsible={true}
                >
                    <TagList
                        tags={this.props.childTags}
                        category={this.props.category}
                        displayCount={this.props.displayCount || false}
                        selectedTagIds={this.props.selectedTagIds}
                        onTagSelect={this.onTagSelect}
                    />
                </Collapsible>
            </li>;
        } else {
            // If a parent tag is selected, this tag shoudl appear as disabled and selected
            const showAsIncludedByParentTag = this.isParentTagSelected && !this.isTagSelected();
            return <li className='tag' data-tag-id={this.props.id}>
                <CheckBox
                    checked={showAsIncludedByParentTag || !!this.isTagSelected()}
                    disabled={showAsIncludedByParentTag}
                    onClick={this.onCheckboxClick}
                >
                    {text}
                </CheckBox>
            </li>;
        }
    }

    @bind private onCheckboxClick(nowSelected: boolean): void {
        // Toggle this tag's selected state.
        const newSelectedTagIdSet = new Set(this.props.selectedTagIds);
        if (this.props.childTags !== undefined && this.props.childTags.length > 0) {
            // Clear all child tags. Regardless of whether the user has just
            // selected or un-selected this parent tag, we un-select all child tags.
            // The UI will display them as either [disabled + selected] or [unselected]
            for (const tag of this.props.childTags) {
                newSelectedTagIdSet.delete(tag.id);
            }
        }
        if (nowSelected) {
            newSelectedTagIdSet.add(this.props.id);
        } else {
            newSelectedTagIdSet.delete(this.props.id);
        }
        this.onTagSelect(this.props.id, nowSelected, newSelectedTagIdSet);
    }

    @bind private onTagSelect(tagId: string, tagSelected: boolean, selectedTagIdSet: ReadonlySet<string>): void {
        // Select the current tag, and any subtags.
        if (this.props.onTagSelect) {
            this.props.onTagSelect(tagId, tagSelected, selectedTagIdSet);
        }
    }

    /** Toggle the display of child tags */
    @bind private onToggle(): void {
        this.setState({isOpen: !this.state.isOpen});
    }
}

export interface TagListProps extends TagUiProps {
    tags: ReadonlyArray<TagData>;
    category?: TaxonomyCategory;
}

export class TagList extends React.PureComponent<TagListProps> {

    public render() {
        const tags = this.props.tags.map((tagProps) =>
            <Tag
                key={tagProps.name}
                {...tagProps}
                category={this.props.category}
                displayCount={this.props.displayCount || false}
                selectedTagIds={this.props.selectedTagIds}
                onTagSelect={this.props.onTagSelect}
            />
        );
        return <ul>{tags}</ul>;
    }
}

export function nameForTag(tag: TagData, category?: TaxonomyCategory, inPlural=false): string {
    const translateCategories = [
        TaxonomyCategory.BackgroundKnowledge,
        TaxonomyCategory.ContentType,
        // TODO: Remove this line - once all translation would be migrated into database
        TaxonomyCategory.SubjectArea,
    ];
    let message: string;
    let msgKey: messageKey = tag.name as messageKey;
    if (inPlural) {
        msgKey = msgKey + ' (Plural)' as messageKey;
    }
    if (tag.translatedName) {
        message = tag.translatedName;
    } else if (
        category !== undefined
        && translateCategories.includes(category)
        && messages[msgKey] !== undefined
    ) {
        message = intl.formatMessage(messages[msgKey]);
    } else {
        message = tag.name;
    }
    return message;
}
