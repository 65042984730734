import bind from 'bind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { intl } from 'i18n';

import { Icon } from 'elements';
import { Collapsible } from '@labxchange/ui-components';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { nameForTag, TagData, TagList } from '../Tag';
import { TaxonomyCategory, TaxonomyData } from './types';

export function messageForTaxonomyCategory(cat: TaxonomyCategory): MessageDescriptor  {
    switch (cat) {
        case TaxonomyCategory.SubjectArea: return messages.taxonomySubjectArea;
        case TaxonomyCategory.ContentType: return messages.taxonomyContentType;
        case TaxonomyCategory.Country: return messages.taxonomyCountry;
        case TaxonomyCategory.BackgroundKnowledge: return messages.taxonomyBackgroundKnowledge;
        case TaxonomyCategory.VideoDuration: return messages.taxonomyVideoDuration;
        case TaxonomyCategory.ContentSource: return messages.taxonomySource;
        case TaxonomyCategory.PopularTags: return messages.taxonomyPopularTags;
        case TaxonomyCategory.Role: return messages.taxonomyRole;
        case TaxonomyCategory.Institution: return messages.taxonomyInstitution;
        case TaxonomyCategory.Interests: return messages.taxonomyInterests;
        case TaxonomyCategory.AreaOfStudy: return messages.taxonomyAreaOfStudy;
        case TaxonomyCategory.Location: return messages.taxonomyLocation;
        case TaxonomyCategory.Forum: return messages.taxonomyForum;
        case TaxonomyCategory.Language: return messages.taxonomyLanguage;
        case TaxonomyCategory.OrganizationType: return messages.taxonomyOrganizationType;
        case TaxonomyCategory.Empty: return {id: 'empty'};
    }
}

export function searchMessageForTaxonomyCategory(cat: TaxonomyCategory): MessageDescriptor  {
    switch (cat) {
        case TaxonomyCategory.SubjectArea: return messages.taxonomySubjectAreaSearch;
        case TaxonomyCategory.ContentType: return messages.taxonomyContentTypeSearch;
        case TaxonomyCategory.Country: return messages.taxonomyCountrySearch;
        case TaxonomyCategory.BackgroundKnowledge: return messages.taxonomyBackgroundKnowledgeSearch;
        case TaxonomyCategory.VideoDuration: return messages.taxonomyVideoDurationSearch;
        case TaxonomyCategory.ContentSource: return messages.taxonomySourceSearch;
        case TaxonomyCategory.PopularTags: return messages.taxonomyPopularTagsSearch;
        case TaxonomyCategory.Role: return messages.taxonomyRoleSearch;
        case TaxonomyCategory.Institution: return messages.taxonomyInstitutionSearch;
        case TaxonomyCategory.Interests: return messages.taxonomyInterestsSearch;
        case TaxonomyCategory.AreaOfStudy: return messages.taxonomyAreaOfStudySearch;
        case TaxonomyCategory.Location: return messages.taxonomyLocationSearch;
        case TaxonomyCategory.Forum: return messages.taxonomyForumSearch;
        case TaxonomyCategory.Language: return messages.taxonomyLanguageSearch;
        case TaxonomyCategory.OrganizationType: return messages.taxonomyOrganizationTypeSearch;
        case TaxonomyCategory.Empty: return {id: 'empty'};
    }
}

export interface TaxonomyProps extends TaxonomyData {
    className?: string;
    /** Next to each tag choice, display the number of entities already tagged with that tag. */
    displayCount: boolean;
    isOpen: boolean;
    isSelected: boolean;
    selectedTagIds: ReadonlySet<string>;
    /** Optional override of the displayed heading, which comes from 'name' */
    title?: string;

    onToggleShow?: (id: string, isOpen: boolean) => void;
    onToggleTagSelect?: (selectedTagIdSet: ReadonlySet<string>) => void;

    /** For <FilterTaxonomy> to inject its search widget */
    extraWidget?: React.ReactNode;

    nodeRef?: React.RefObject<HTMLDivElement>;

    showSkeleton?: boolean;
}

interface TaxonomyState {
    /**
     * This is different from isOpen, as isOpen can only expand one filter at a time,
     * whereas this can expand multiple filters based on selection. Once closed,
     * this behavior won't occur again, and collapse/expansion will be controlled using isOpen.
     */
    isSelected: boolean;
}

/**
 * A collapsible UI widget for displaying a selectable list of tags or
 * other filter choices that can be used to filter a search.
 */
export class Taxonomy extends React.PureComponent<TaxonomyProps, TaxonomyState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isSelected : this.props.isSelected
        };
    }

    public static defaultProps = {
        displayCount: true,
        showSkeleton: false,
    };

    public render() {
        return (
            <div className={classNames('taxonomy', this.props.className)} ref={this.props.nodeRef}>
                {this.props.showSkeleton ?
                    <Skeleton /> :
                    <Collapsible
                        title={this.props.title || this.renderHeading()}
                        isOpen={this.props.isOpen || this.state.isSelected}
                        onToggle={this.onToggle}
                        isCollapsible={true}
                    >
                        {this.props.extraWidget /* For use by <FilterTaxonomy> */}
                        <TagList
                          tags={this.props.tags}
                          category={this.props.category}
                          displayCount={this.props.displayCount}
                          selectedTagIds={this.props.selectedTagIds}
                          onTagSelect={this.onTagSelect}
                        />
                    </Collapsible>
                }
            </div>
        );
    }

    @bind private renderHeading(): React.ReactNode {
        // Show the count of tags selected in the heading.
        // Note that selectedTagIds may contain IDs from other taxonomies, so we have to filter it.
        const allTagIds = this.getAllTagIds();
        const numTagsSelected = [...this.props.selectedTagIds].filter((t) =>  allTagIds.has(t)).length;
        const countMessage: React.ReactNode = numTagsSelected > 0 ? <WrappedMessage message={messages.taxonomyHeadingCount} values={{count: numTagsSelected}} /> : null;
        const icon = this.props.isOpen || this.state.isSelected ? 'chevron-up' : 'chevron-down';
        return (
            <h3>
                <WrappedMessage message={messageForTaxonomyCategory(this.props.category)} />
                {' '}{countMessage}
                <Icon className='toggle-icon' name={icon}/>
            </h3>
        );
    }

    @bind private onToggle(isOpen: boolean): void {
        this.setState({isSelected: false});
        if (this.props.onToggleShow) {
            this.props.onToggleShow!(this.props.id, isOpen);
        }
    }

    @bind private onTagSelect(tagId: string, tagSelected: boolean, selectedTagIdSet: ReadonlySet<string>): void {
        if (this.props.onToggleTagSelect) {
            this.props.onToggleTagSelect(selectedTagIdSet);
        }
    }

    /** Get the flattened set of all tag IDs used in this widget */
    private getAllTagIds(): ReadonlySet<string> {
        const allTagIds = new Set<string>();
        const findTagIds = (tags: ReadonlyArray<TagData>) => {
            for (const tag of tags) {
                allTagIds.add(tag.id);
                if (tag.childTags) {
                    findTagIds(tag.childTags);
                }
            }
        };
        findTagIds(this.props.tags);
        return allTagIds;
    }
}

export interface FilterTaxonomyProps extends TaxonomyProps {
    showSearch?: boolean;
    /** Invert this value to clear any active filter */
    clearFlag: boolean;
}

interface FilterTaxonomyState {
    /** The current string used to filter the tag list, if any. */
    filterString: string;
    previousClearFlag: boolean;
}

/**
 * Like <Taxonomy>, this is a collapsible UI widget for displaying a selectable
 * list of tags or other filter choices that can be used to filter a search.
 * The only difference is that this includes a search box that can be used to
 * filter the list of tags shown in this widget.
 */
export class FilterTaxonomy extends React.PureComponent<
    FilterTaxonomyProps,
    FilterTaxonomyState
> {

    public static getDerivedStateFromProps(props: FilterTaxonomyProps, state: FilterTaxonomyState) {
        if (props.clearFlag !== state.previousClearFlag) {
            return {
                filterString: '',
                previousClearFlag: props.clearFlag,
            };
        }
        return null;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            filterString: '',
            previousClearFlag: props.clearFlag,
        };
    }

    public render() {
        const props = {
            ...this.props,
            tags: this.getFilteredTags(),
        };
        return (
            <Taxonomy
                {...props}
                extraWidget={
                    this.props.showSearch
                    ?   <div className='search-facet'>
                            <input type='text' placeholder={this.searchLabel()} aria-label={this.searchLabel()}
                                onChange={this.onChange} value={this.state.filterString} />
                            <span><Icon name='search' fill='#66A1DB' zoom='1.3em'/></span>
                        </div>
                    : null
                }
            />
        );
    }

    private searchLabel() {
        return intl.formatMessage(
            searchMessageForTaxonomyCategory(this.props.category)
        );
    }

    @bind private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({filterString: event.target.value});
    }

    /** Get a subset of this.props.tags, filtered by the search string, if any */
    private getFilteredTags(): TagData[] {
        const filteredTags: TagData[] = [];
        let searchQuery: string = this.state.filterString.trim().toLocaleLowerCase();
        searchQuery = searchQuery.replace(/  +/g, ' ');
        this.props.tags.forEach((parentTag) => {
            let filteredParent: TagData = {id: '', name: '', numEntities: 0};
            const parentName = nameForTag(parentTag, this.props.category);
            const parentExtraText = parentTag.extraText || '';
            if (
                parentName.toLocaleLowerCase().indexOf(searchQuery) !== -1 ||
                parentExtraText.toLocaleLowerCase().indexOf(searchQuery) !== -1
            ) {
                filteredParent = {...parentTag};
            } else {
                if (parentTag.childTags && parentTag.childTags.length > 0) {
                    filteredParent = {...parentTag};
                    filteredParent.childTags = parentTag.childTags.filter(
                        (childTag) => {
                            const childName = nameForTag(childTag, this.props.category);
                            const childExtraText = childTag.extraText || '';
                            return (
                                childName.toLocaleLowerCase().indexOf(searchQuery) !== -1 ||
                                childExtraText.toLocaleLowerCase().indexOf(searchQuery) !== -1
                            );
                        }
                    );
                    if (filteredParent.childTags.length === 0) {
                        filteredParent = {id: '', name: '', numEntities: 0};
                    }
                }
            }
            if (filteredParent.id !== '') {
                filteredTags.push(filteredParent);
            }
        });
        return filteredTags;
    }
}
