import React from 'react';
import { ROUTES } from '../../../global/constants';
import { NavLink } from 'react-router-dom';

const Navbar: React.FC = () => {
    return (
        <nav className='navbar-career'>
            <div className='navbar-content'>
                <NavLink to={ROUTES.CareerExplorer.HOME}>
                    <div className='navbar__icon'>
                        <img
                            alt='CareerXplorer'
                            title='CareerXplorer'
                            src='/assets/images/career-explorer/career-xplorer-logo.svg'
                        />
                    </div>
                </NavLink>
            </div>
        </nav>
    );
};

export default Navbar;
