import React, { useEffect, useState, useRef } from 'react';
import { intl } from 'i18n';
import classNames from 'classnames';

import { PillButton, Icon } from 'ui/components';
import { useLayoutSize } from 'utils';

import messages from '../../../curriculum/displayMessages';

interface PillButtonFiltersProps {
  filters: string[];
  selectedFilters: string[];
  setSelectedFilters: (filters: string[]) => void;
  onFilterClick?: (filter: string, updatedFilters: string[]) => void;
}

export const ALL_FILTER = 'All';

export const PillButtonFilters = ({
  filters, selectedFilters, setSelectedFilters, onFilterClick
}: PillButtonFiltersProps) => {
  const [showAllFilters, setShowAllFilters] = useState<boolean>(false);
  const [visibleCount, setVisibleCount] = useState<number>(filters.length);
  const [hideToggleButton, setHideToggleButton] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const pillRefs = useRef<HTMLDivElement[]>([]);
  const isMobile = ['small', 'mobile'].includes(useLayoutSize());

  useEffect(() => {
    const calculateVisibleFilters = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const toggleButtonWidth = 36;
      let availableWidth = containerWidth - toggleButtonWidth - 140;
      let count = 0;

      for (const pill of pillRefs.current) {
        const pillWidth = pill?.offsetWidth || 190;
        if (availableWidth - pillWidth >= 0) {
          availableWidth -= pillWidth;
          count++;
        } else {
          break;
        }
      }

      setVisibleCount(count);
      setHideToggleButton(count >= filters.length);
    };

    calculateVisibleFilters();

    window.addEventListener('resize', calculateVisibleFilters);
    return () => window.removeEventListener('resize', calculateVisibleFilters);
  }, [filters, showAllFilters]);

  const visibleFilters = (showAllFilters || isMobile) ? filters : filters.slice(0, visibleCount);

  const handleFilterToggle = (filter: string) => {
    let updatedFilters = [];

    if (filter === ALL_FILTER) {
      updatedFilters = [ALL_FILTER];
      if (onFilterClick) onFilterClick(filter, updatedFilters);
      return setSelectedFilters(updatedFilters);
    }

    updatedFilters = [...selectedFilters];
    updatedFilters = updatedFilters.includes(filter)
      ? updatedFilters.filter((selected) => selected !== filter)
      : [...updatedFilters, filter];

    if (updatedFilters.includes(ALL_FILTER)) {
      updatedFilters = [filter];
    }

    setSelectedFilters(updatedFilters.length === 0 ? [ALL_FILTER] : updatedFilters);
    if (onFilterClick) onFilterClick(filter, updatedFilters);
  };

  if (filters.length === 0) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={classNames(
        'pill-button-filters',
        {
          'expanded': showAllFilters,
          'collapsed': !showAllFilters,
          'scrollable': isMobile,
        }
      )}
    >
      <PillButton
        label={intl.formatMessage(messages.allPillButtonFilter)}
        onClick={() => handleFilterToggle(ALL_FILTER)}
        isSelected={selectedFilters.includes(ALL_FILTER)}
        ariaLabel={intl.formatMessage(messages.allPillButtonFilterAria)}
      />
      <div className='vertical-divider'></div>
      {visibleFilters.map((filter, index) => (
        <div
          key={filter}
          ref={(el: HTMLDivElement) => (pillRefs.current[index] = el)}
        >
          <PillButton
            label={filter}
            onClick={() => handleFilterToggle(filter)}
            isSelected={selectedFilters.includes(filter)}
            ariaLabel={intl.formatMessage(messages.pillButtonFilterAria, { filter })}
          />
        </div>
      ))}
      {(!hideToggleButton && !isMobile) &&
        <button
          className={`btn-toggle ${showAllFilters ? 'is-open' : ''}`}
          aria-label={intl.formatMessage(messages.pillButtonFiltersToggleAria)}
          onClick={() => setShowAllFilters(!showAllFilters)}
        >
          <Icon
            name={showAllFilters ? 'chevron-up-tabs' : 'chevron-down-tabs'}
            className='icon-toggle'
          />
        </button>
      }
    </div>
  );
};
